import type { SelectOption, SelectOptionGroup } from 'contracts/Common';
import type { FindSchedulingDocks } from 'contracts/Scheduling';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { ListSchedulingDocksActions as MainActions } from 'store/ducks/scheduling';
import { Formatter } from 'utils';
import { FindDocksValidator } from 'validators/Scheduling';

export const useSchedulingDocks = () => {
  const dispatch: AppDispatch = useDispatch();
  const { data: schedulingDocks, loading: loadingSchedulingDocks } =
    useSelector((state: RootState) => state.listSchedulingDocks);

  const [options, setOptions] = useState<SelectOptionGroup<SelectOption>[]>([]);

  const fetchSchedulingDocks = useCallback(
    async (query: Partial<FindSchedulingDocks>): Promise<void> => {
      const { schema, logErrors } = new FindDocksValidator();
      try {
        const validQuery = await schema.validate(query, { abortEarly: false });
        dispatch(MainActions.request(validQuery));
      } catch (error) {
        dispatch(MainActions.reset());
        logErrors(error);
      }
    },
    [dispatch]
  );

  const onDocksLoad = useCallback((): void => {
    setOptions(
      schedulingDocks.map(({ dock, timeframes }) => ({
        label: dock.name,
        options: timeframes.map((timeframe) => ({
          label: `${dock.name} às ${Formatter.date(timeframe, {
            format: 'HH:mm',
          })}`,
          value: `${dock.id}|${timeframe}`,
        })),
      }))
    );
  }, [schedulingDocks]);

  useEffect(() => {
    onDocksLoad();
  }, [onDocksLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    schedulingDocksOptions: options,
    loadingSchedulingDocks,
    fetchSchedulingDocks,
  };
};

export type SchedulingDocksHook = ReturnType<typeof useSchedulingDocks>;
