import * as Yup from 'yup';

export class FindDocksValidator {
  public get schema() {
    return Yup.object().shape({
      warehouseId: Yup.number()
        .typeError('Selecione um armazém')
        .required('Selecione um armazém'),
      carrierId: Yup.number()
        .typeError('Selecione uma transportadora')
        .required('Selecione uma transportadora'),
      companyId: Yup.number()
        .typeError('Selecione um cliente')
        .required('Selecione um cliente'),
      orderTypeId: Yup.number()
        .typeError('Selecione um tipo de ordem')
        .required('Selecione um tipo de ordem'),
      operationTypeId: Yup.number()
        .typeError('Selecione um tipo de operação')
        .required('Selecione um tipo de operação'),
      date: Yup.string()
        .typeError('Informe uma data')
        .required('Informe uma data')
        .transform((value: string) => {
          return value ? new Date(value).toISOString().split('T')[0] : null;
        }),
      duration: Yup.number()
        .typeError('Informe a duração')
        .required('Informe a duração'),
    });
  }
  public logErrors(error: any) {
    if (error instanceof Yup.ValidationError) {
      const validationErrors: Record<string, any> = {};
      error.inner.forEach((er: any) => {
        validationErrors[er.path] = er.message;
      });
    }
  }
}
