import * as Yup from 'yup';
import { getWarehouseVehicleSetupShape } from 'validators/Common/baseWarehouseVehicleSetupValidator';

export class CreateWarehouseVehicleSetupValidator {
  public get schema() {
    return Yup.object().shape({
      ...getWarehouseVehicleSetupShape(),
    });
  }
}
