import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  CompactCheckbox,
  CompactInput,
  CompactSelect,
} from 'components/Shared';
import { CHECKBOX_OPTIONS } from 'constants/Checkboxes';
import { FindMany, SelectOption } from 'contracts/Common';
import { OrderStatus } from 'contracts/Orders';
import { useAuth, useWarehouses } from 'hooks';
import { throttle } from 'lodash';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as S from './styles';

export interface FindOrders extends FindMany {
  warehouseId?: unknown;
  date?: unknown;
  fromDate?: unknown;
  toDate?: unknown;
  status?: string[];
}

interface Props {
  currentFilter: FindOrders;
  delay?: number;
  onFilter?: (query: FindOrders) => void;
}

const Filters: React.FC<Props> = ({
  delay = 1000,
  currentFilter,
  onFilter,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { profile } = useAuth();
  const { warehouseOptions, loadingWarehouses, fetchWarehouses } =
    useWarehouses();

  const [filters, setFilters] = useState<FindOrders>({
    ...currentFilter,
    dirty: false,
  });

  const handleWarehouseChange = useCallback(
    (option: SelectOption | null): void => {
      setFilters((state) => ({
        ...state,
        dirty: true,
        warehouseId: option?.value,
      }));
    },
    []
  );

  const handleFromDateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setFilters((state) => ({
        ...state,
        dirty: true,
        fromDate: value,
      }));
    },
    []
  );

  const handleToDateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setFilters((state) => ({
        ...state,
        dirty: true,
        toDate: value,
      }));
    },
    []
  );

  const handleStatusChange = useCallback((options: string[]): void => {
    setFilters((state) => ({
      ...state,
      dirty: true,
      status: options as OrderStatus[],
    }));
  }, []);

  const setDefaultWarehouse = useCallback(() => {
    const warehouseId =
      profile?.warehouse?.id ??
      currentFilter?.warehouseId ??
      warehouseOptions[0]?.value ??
      null;

    if (!warehouseId) return;

    const warehouse = warehouseOptions.find(
      (option) => option.value === warehouseId
    );

    if (!warehouse) return;

    formRef.current?.setFieldValue('warehouseId', warehouse);
  }, [currentFilter, profile, warehouseOptions]);

  const setDefaultFromDate = useCallback(() => {
    let fromDate: unknown = null;

    fromDate = currentFilter?.fromDate;

    if (!fromDate) return;

    formRef.current?.setFieldValue('fromDate', fromDate);
    // for some reason, setting date is not triggering onChange
    setFilters((state) => ({
      ...state,
      dirty: true,
      fromDate: fromDate as string,
    }));
  }, [currentFilter]);

  const setDefaultToDate = useCallback(() => {
    let toDate: unknown = null;

    toDate = currentFilter?.toDate;

    if (!toDate) return;

    formRef.current?.setFieldValue('toDate', toDate);
    // for some reason, setting date is not triggering onChange
    setFilters((state) => ({
      ...state,
      dirty: true,
      toDate: toDate as string,
    }));
  }, [currentFilter]);

  const setDefaultStatus = useCallback(() => {
    formRef.current?.setFieldValue('status', currentFilter.status);
  }, [currentFilter.status]);

  // throttled methods

  const onWarehouseChange = useMemo(
    () => throttle(handleWarehouseChange, 0),
    [handleWarehouseChange]
  );

  const onStatusChange = useMemo(
    () => throttle(handleStatusChange, 0),
    [handleStatusChange]
  );

  const onFromDateChange = useMemo(
    () => throttle(handleFromDateChange, delay),
    [delay, handleFromDateChange]
  );

  const onToDateChange = useMemo(
    () => throttle(handleToDateChange, delay),
    [delay, handleToDateChange]
  );

  useEffect(() => {
    fetchWarehouses();
  }, [fetchWarehouses]);

  useEffect(() => {
    setDefaultWarehouse();
  }, [warehouseOptions]);

  useEffect(() => {
    setDefaultFromDate();
  }, [setDefaultFromDate]);

  useEffect(() => {
    setDefaultToDate();
  }, [setDefaultToDate]);

  useEffect(() => {
    setDefaultStatus();
  }, [setDefaultStatus]);

  const invokeFilter = useCallback((): void => {
    if (onFilter && filters.dirty) {
      onFilter(filters);
    }
  }, [filters, onFilter]);

  useEffect(() => {
    invokeFilter();
  }, [invokeFilter]);

  return (
    <S.Container>
      <Form ref={formRef} onSubmit={() => {}}>
        <CompactSelect
          name="warehouseId"
          placeholder="Armazém"
          options={warehouseOptions}
          onChange={onWarehouseChange}
          isLoading={loadingWarehouses}
        />
        <CompactInput
          name="fromDate"
          type="date"
          placeholder="Data inicial"
          label="De"
          direction="mixed"
          onChange={onFromDateChange}
        />
        <CompactInput
          name="toDate"
          type="date"
          placeholder="Data final"
          label="Até"
          direction="mixed"
          onChange={onToDateChange}
        />
        <CompactCheckbox
          name="status"
          options={CHECKBOX_OPTIONS.ORDER_STATUS}
          onChange={onStatusChange}
        />
      </Form>
    </S.Container>
  );
};

export default Filters;
