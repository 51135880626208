import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Select } from 'components/Shared';
import type { SelectOption } from 'contracts/Common';
import {
  CreateCompanyMemberAccessActions as CreateActions,
  DeleteCompanyMemberAccessActions as DeleteActions,
} from 'store/ducks/companyMemberAccesses';
import { useValidation } from 'hooks';
import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import * as Yup from 'yup';
import * as S from './styles';
import { Formatter } from 'utils';
import { PaginatedCompanyMemberAccesses } from 'contracts/CompanyMemberAccesses';

export interface Ref {
  resetForm: () => void;
}

interface Props {
  onUpdate?: () => void;
  companyMemberId: string;
}

interface ICompaniesManager
  extends ForwardRefExoticComponent<Props & RefAttributes<Ref>> {}

interface ItemProps extends PaginatedCompanyMemberAccesses {}

const CompaniesManager: ICompaniesManager = forwardRef<Ref, Props>(
  ({ companyMemberId, onUpdate }, ref) => {
    const { data: companyMember } = useSelector(
      (state: RootState) => state.fetchCompanyMember
    );
    const { data: companyMemberAccesses } = useSelector(
      (state: RootState) => state.paginateCompanyMemberAccesses
    );

    const dispatch: AppDispatch = useDispatch();
    const formRef = useRef<FormHandles>(null);

    const { handleFormErrors, handleApiErrors } = useValidation();

    /**
     * this component will not fetch companies here,
     * we lifted it to a upper level (RulesManager component)
     * to avoid dispatching multiple times
     * */
    const { loading: loadingCompanies, data: companies } = useSelector(
      (state: RootState) => state.listCompanies
    );

    const { loading: creating, validationErrors } = useSelector(
      (state: RootState) => state.createCompanyMemberAccess
    );
    

    const { id: deletingId } = useSelector(
      (state: RootState) => state.deleteCompanyMemberAccess
    );

    /**
     * we will also filter the list of companies to only show
     * the ones that are not already bound to the dock
     * */

    const companyOptions: SelectOption[] | undefined = useMemo(() => {
      if (!companies?.length) return undefined;

      return companies
        .filter(({ id }) => companyMember?.companyId !== id)
        .filter(
          ({ id }) =>
            !companyMemberAccesses
              .map(({ companyId }) => companyId)
              .includes(id)
        )
        .map((company) => ({
          value: company.id,
          label: `${company.tradeName} - ${Formatter.document(
            company.document,
            company.documentType
          )}`,
        }));
    }, [companies, companyMember]);

    const onDelete = useCallback(
      (id?: number) => dispatch(DeleteActions.request(id, onUpdate)),
      [dispatch, onUpdate]
    );

    const onSuccess = useCallback((): void => {
      onUpdate && onUpdate();
      formRef.current?.reset();
    }, [onUpdate]);

    const onSubmit = useCallback(
      async (data: any): Promise<void> => {
        try {
          formRef.current?.setErrors({});
          const schema = Yup.object().shape({
            companyId: Yup.number()
              .typeError('Selecione o cliente')
              .required('Selecione o cliente'),
          });
          const validData = await schema.validate(data, {
            abortEarly: false,
          });
          dispatch(
            CreateActions.request({ ...validData, companyMemberId }, onSuccess)
          );
        } catch (error) {
          handleFormErrors(error, formRef);
        }
      },
      [dispatch, handleFormErrors, onSuccess, companyMemberId]
    );

    const Item = useCallback(
      ({ company, id }: ItemProps): JSX.Element => {
        const {
          addressCity,
          addressState,
          document,
          id: companyId,
          tradeName,
        } = company;

        return (
          <S.ListItem>
            <S.Column>{companyId}</S.Column>
            <S.Column>{tradeName}</S.Column>
            <S.Column>{Formatter.cnpj(document)}</S.Column>
            <S.Column>{addressCity}</S.Column>
            <S.Column>{addressState}</S.Column>
            <S.ActionsColumn>
              <S.ActionButton
                disabled={deletingId === id}
                onClick={() => onDelete(id)}
                mood="danger"
              >
                {deletingId === id ? (
                  <S.ActivityIndicator />
                ) : (
                  <S.TrashIcon />
                )}
              </S.ActionButton>
            </S.ActionsColumn>
          </S.ListItem>
        );
      },
      [deletingId, onDelete]
    );

    useImperativeHandle(
      ref,
      () => ({
        resetForm: () => {
          formRef.current?.reset();
          formRef.current?.setErrors({});
        },
      }),
      []
    );

    useEffect(() => {
      handleApiErrors(validationErrors, formRef);
    }, [handleApiErrors, validationErrors]);

    return (
      <S.Container>
        <Form ref={formRef} onSubmit={onSubmit}>
          <S.FormRow>
            <Select
              name="companyId"
              label="Cliente"
              options={companyOptions}
              isLoading={loadingCompanies}
              menuPortalTarget={document.body}
              menuPlacement="top"
            />
            <S.Button type="submit">
              {creating ? <S.ActivityIndicator /> : 'Adicionar'}
            </S.Button>
          </S.FormRow>
        </Form>
        {!companyMemberAccesses?.length ? (
          <S.EmptyListPlaceholder>
            Esse usuário ainda não possui permissões para outros clientes
          </S.EmptyListPlaceholder>
        ) : (
          <>
            <S.ListHeader>
              <div>ID</div>
              <div>NOME</div>
              <div>CNPJ</div>
              <div>CIDADE</div>
              <div>ESTADO</div>
            </S.ListHeader>
            {companyMemberAccesses.map((props) => (
              <Item {...props} key={props.id} />
            ))}
          </>
        )}
      </S.Container>
    );
  }
);

export default CompaniesManager;
