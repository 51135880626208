import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Input, MaskedInput, Modal, ModalCloseButton } from 'components/Shared';
import { MODAL_DISMISS_ACTION } from 'constants/Common';
import { Order } from 'contracts/Orders';
import { useValidation } from 'hooks';
import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { UpdateOrderDriverAndVehicleActions as MainActions } from 'store/ducks/orders';
import { UpdateOrderDriverAndVehicleValidator as Validator } from 'validators/Orders';
import * as S from './styles';

export interface Ref {
  openModal: () => void;
}

interface Props {
  order: Order;
  onUpdate?: () => void;
}

interface IUpdateDriverAndVehicleModal
  extends ForwardRefExoticComponent<Props & RefAttributes<Ref>> {}

const UpdateDriverAndVehicleModal: IUpdateDriverAndVehicleModal = forwardRef(
  (props, ref) => {
    const { order, onUpdate } = props;
    const dispatch: AppDispatch = useDispatch();
    const formRef = useRef<FormHandles>(null);
    const { handleFormErrors, handleApiErrors } = useValidation();
    const [isOpen, setIsOpen] = useState(false);

    const { loading: updatingOrder, validationErrors } = useSelector(
      (state: RootState) => state.updateOrderDriverAndVehicle
    );

    const onModalClose = useCallback((): void => {
      setIsOpen(false);
      dispatch(MainActions.reset());
      formRef.current?.setErrors({});
      formRef.current?.reset();
    }, [dispatch]);

    const setFormValues = useCallback((): void => {
      const { driverName, driverDocument, vehiclePlate, cellPhone } = order;
      if (isOpen) {
        formRef.current?.setData({
          driverName,
          driverDocument,
          vehiclePlate,
          cellPhone,
        });
      }
    }, [isOpen, order]);

    const onSuccess = useCallback((): void => {
      formRef.current?.reset();
      onModalClose();
      onUpdate?.();
    }, [onModalClose, onUpdate]);

    const onSubmit = useCallback(
      async (data: any): Promise<void> => {
        try {
          formRef.current?.setErrors({});

          const { schema } = new Validator();

          const validData = await schema.validate(data, {
            abortEarly: false,
          });

          dispatch(MainActions.request(order.id, validData, onSuccess));
        } catch (error) {
          handleFormErrors(error, formRef);
        }
      },
      [dispatch, handleFormErrors, onSuccess, order.id]
    );

    useEffect(() => {
      setFormValues();
    }, [setFormValues]);

    useEffect(() => {
      handleApiErrors(validationErrors, formRef);
    }, [handleApiErrors, validationErrors]);

    useEffect(() => {
      return () => {
        dispatch(MainActions.reset());
      };
    }, [dispatch]);

    useImperativeHandle(
      ref,
      () => ({
        openModal: (): void => {
          setIsOpen(true);
        },
      }),
      []
    );

    return (
      <Modal isOpen={isOpen} onClickOutside={onModalClose}>
        <S.ModalContent style={{ maxWidth: '720px' }}>
          <S.ModalHeader>
            <h4>Editar motorista e placa</h4>
            <ModalCloseButton onClick={onModalClose} />
          </S.ModalHeader>
          <S.ModalBody>
            <Form ref={formRef} onSubmit={onSubmit}>
              <S.FormRow>
                <Input name="driverName" label="Nome do motorista" />
                <MaskedInput
                  name="cellPhone"
                  label="Celular"
                  mask="(99) 99999-9999"
                />
              </S.FormRow>
              <S.FormRow>
                <MaskedInput
                  name="driverDocument"
                  label="CPF do motorista"
                  mask="999.999.999-99"
                />
                <Input name="vehiclePlate" label="Placa do veículo" />
              </S.FormRow>
              <S.FormActions>
                <S.Button type="button" mood="light" onClick={onModalClose}>
                  {MODAL_DISMISS_ACTION}
                </S.Button>
                <S.Button type="submit">
                  {updatingOrder ? <S.ActivityIndicator /> : 'Salvar'}
                </S.Button>
              </S.FormActions>
            </Form>
          </S.ModalBody>
        </S.ModalContent>
      </Modal>
    );
  }
);

export default UpdateDriverAndVehicleModal;
