import React, { useCallback } from 'react';
import type { PaginatedDock } from 'contracts/Docks';
import * as S from './styles';

interface Props {
  docks: PaginatedDock[];
  onSelectDock?: (dockId: number) => void;
}

interface ItemProps {
  dock: PaginatedDock;
}

const DocksList: React.FC<Props> = ({ docks, onSelectDock }) => {
  const Item = useCallback(
    ({ dock }: ItemProps): JSX.Element => {
      const {
        id,
        name,
        startTime,
        endTime,
        loadEnabled,
        unloadEnabled,
        blockedAt,
      } = dock;

      return (
        <S.ListItem>
          <S.Column>{id}</S.Column>
          <S.Column>{name}</S.Column>
          <S.Column>{startTime.substring(0, 5)}</S.Column>
          <S.Column>{endTime.substring(0, 5)}</S.Column>
          <S.Column>{loadEnabled ? 'Sim' : 'Não'}</S.Column>
          <S.Column>{unloadEnabled ? 'Sim' : 'Não'}</S.Column>
          <S.Column>
            {blockedAt && <S.LockIcon title="Doca bloqueada" />}
          </S.Column>
          <S.ActionsColumn>
            <S.ActionButton onClick={() => onSelectDock?.(id)}>
              <S.EditIcon />
            </S.ActionButton>
          </S.ActionsColumn>
        </S.ListItem>
      );
    },
    [onSelectDock]
  );

  if (!docks.length) {
    return (
      <S.EmptyListPlaceholder>
        Este armazém ainda não possui docas
      </S.EmptyListPlaceholder>
    );
  }

  return (
    <S.List>
      <S.ListHeader>
        <div>ID</div>
        <div>NOME</div>
        <div>INÍCIO</div>
        <div>TÉRMINO</div>
        <div>CARREGAMENTO</div>
        <div>DESCARREGAMENTO</div>
        <div></div>
      </S.ListHeader>
      {docks.map((dock) => (
        <Item key={dock.id} dock={dock} />
      ))}
    </S.List>
  );
};

export default DocksList;
