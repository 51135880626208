import { Validator } from 'utils';
import * as Yup from 'yup';

export class UpdateOrderDriverAndVehicleValidator {
  public get schema() {
    return Yup.object().shape({
      driverName: Yup.string().required('Informe o nome do motorista'),
      driverDocument: Yup.mixed().test({
        name: 'testDocument',
        test: function (value: string) {
          try {
            Validator.validateDocument(value, 'cpf');
            return true;
          } catch (error: any) {
            return this.createError({
              message: error?.message,
            });
          }
        },
      }),
      vehiclePlate: Yup.string().required('Informe a placa do veículo'),
      cellPhone: Yup.string().min(11, 'Número inválido').optional()
    });
  }
}
