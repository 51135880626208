import type { RouterProps } from 'contracts/Router';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

// guest
import loginRoute from './guest/login';
import forgotMyPasswordRoute from './guest/forgotMyPassword';
import passwordResetRoute from './guest/passwordReset';

// authenticated
import adminsRoutes from './admins';
import cancelationReasonsRoutes from './cancelationReasons';
import cargoTypesRoutes from './cargoTypes';
import carrierMemberRoutes from './carrierMembers';
import carriersRoutes from './carriers';
import companiesRoutes from './companies';
import companyMemberRoutes from './companyMembers';
import dockReservedIntervalsRoutes from './dockReservedIntervals';
import operationTypesRoutes from './operationTypes';
import schedulingRoutes from './scheduling';
import vehicleSetupsRoutes from './vehicleSetups';
import vehicleTypesRoutes from './vehicleTypes';
import warehouseMemberRoutes from './warehouseMembers';
import warehousesRoutes from './warehouses';

import { MyAccountPage } from 'pages/Account';
import { ListOrdersPage } from 'pages/Scheduling';

const routes = (props: RouterProps): RouteObject[] => {
  const { isLoggedIn, location } = props;
  return [
    {
      path: '/',
      element: isLoggedIn ? (
        <Outlet />
      ) : (
        <Navigate to="/login" state={{ from: location }} />
      ),
      children: [
        // settings routes
        adminsRoutes(props),
        carrierMemberRoutes(props),
        carriersRoutes(props),
        companiesRoutes(props),
        companyMemberRoutes(props),
        warehousesRoutes(props),
        warehouseMemberRoutes(props),
        vehicleSetupsRoutes(props),
        vehicleTypesRoutes(props),
        cargoTypesRoutes(props),
        operationTypesRoutes(props),
        cancelationReasonsRoutes(props),
        dockReservedIntervalsRoutes(props),
        {
          path: 'configuracoes',
          element: <Navigate to="/configuracoes/clientes" />,
        },
        // end settings routes
        schedulingRoutes(props),
        {
          path: 'meus-dados',
          element: <MyAccountPage />,
        },
        {
          path: '',
          element: <ListOrdersPage />,
        },
      ],
    },
    loginRoute(props),
    forgotMyPasswordRoute(props),
    passwordResetRoute(props),
  ];
};

export default routes;
