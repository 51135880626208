import { ApiValidationError } from 'contracts/Common';
import type {
  FindSchedulingVehicleSetups,
  SchedulingVehicleSetup,
} from 'contracts/Scheduling';
import update from 'immutability-helper';
import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions(
  {
    request: ['query', 'onSuccess', 'onFailure'],
    success: ['data'],
    failure: ['errorMessage'],
    softReset: [],
    reset: [],
  },
  {
    prefix: 'LIST_SCHEDULING_VEHICLE_SETUPS_',
  }
);

export interface ListSchedulingVehicleSetupsState {
  data: SchedulingVehicleSetup[];
  loading: boolean;
  errorMessage: string | null;
  validationErrors: ApiValidationError[];
}

export interface ListSchedulingVehicleSetupsRequestAction {
  query: FindSchedulingVehicleSetups;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface SuccessAction {
  data: SchedulingVehicleSetup[];
}

interface FailureAction {
  errorMessage: string;
  validationErrors?: ApiValidationError[];
}

const INITIAL_STATE: ListSchedulingVehicleSetupsState = {
  data: [],
  loading: false,
  errorMessage: null,
  validationErrors: [],
};

const request = (state: ListSchedulingVehicleSetupsState) =>
  update(state, {
    loading: { $set: true },
    errorMessage: { $set: null },
    validationErrors: { $set: [] },
  });

const success = (
  state: ListSchedulingVehicleSetupsState,
  action: SuccessAction
) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

const failure = (
  state: ListSchedulingVehicleSetupsState,
  action: FailureAction
) =>
  update(state, {
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
    validationErrors: { $set: action.validationErrors || [] },
  });

const softReset = (state: ListSchedulingVehicleSetupsState) =>
  update(state, {
    loading: { $set: false },
  });

const reset = () => INITIAL_STATE;

export const listSchedulingVehicleSetups = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.SOFT_RESET]: softReset,
  [Types.RESET]: reset,
});

export const ListSchedulingVehicleSetupsTypes = Types;
export const ListSchedulingVehicleSetupsActions = Creators;
