import { SelectOption } from 'contracts/Common';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { ListVehicleTypesActions as MainActions } from 'store/ducks/vehicleTypes';

interface FindVehicleTypes {
  excludeBlocked?: boolean;
}

export const useVehicleTypes = () => {
  const dispatch: AppDispatch = useDispatch();

  const { data: vehicleTypes, loading: loadingVehicleTypes } = useSelector(
    (state: RootState) => state.listVehicleTypes
  );

  const [ignoredBlockedId, enableVehicleTypeIfBlocked] = useState<
    number | null
  >(null);

  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchVehicleTypes = useCallback(
    (query?: FindVehicleTypes): void => {
      dispatch(MainActions.request(query));
    },
    [dispatch]
  );

  const onVehicleTypesLoad = useCallback((): void => {
    setOptions(
      vehicleTypes.map(({ id, name, blockedAt }) => ({
        value: id,
        label: name,
        isDisabled: ignoredBlockedId !== id && blockedAt !== null,
      }))
    );
  }, [vehicleTypes, ignoredBlockedId]);

  useEffect(() => {
    onVehicleTypesLoad();
  }, [onVehicleTypesLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    vehicleTypes,
    vehicleTypeOptions: options,
    loadingVehicleTypes,
    fetchVehicleTypes,
    enableVehicleTypeIfBlocked,
  };
};

export type VehicleTypesHook = ReturnType<typeof useVehicleTypes>;
