import * as Yup from 'yup';
import {
  getCompanyLogoShape,
  getCompanyShape,
  Props,
} from 'validators/Common/baseCompanyValidator';

export class UpdateCompanyValidator {
  constructor(protected readonly props: Props) {}

  public get schema() {
    return Yup.object().shape({
      ...getCompanyShape(this.props),
      ...getCompanyLogoShape(),
    });
  }
}
