import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Scaffold } from 'layouts';
import { FormPageHeader } from 'components/Shared';
import { MemberCreationForm } from 'components/Pages/CarrierMembers';
import * as S from './styles';

export const CarrierMemberCreationPage: React.FC = () => {
  const navigate = useNavigate();

  const onCreate = useCallback((): void => {
    navigate('/configuracoes/transportadoras/usuarios');
  }, [navigate]);

  return (
    <Scaffold>
      <S.PageContainer>
        <S.MainPanel>
          <FormPageHeader
            title="Novo usuário de transportadora"
            icon={<S.UserRectangleIcon />}
            actions={
              <S.LinkButton
                size="small"
                to="/configuracoes/transportadoras/usuarios"
              >
                <S.ArrowLeftIcon /> Voltar
              </S.LinkButton>
            }
          />
          <MemberCreationForm onCreate={onCreate} />
        </S.MainPanel>
      </S.PageContainer>
    </Scaffold>
  );
};
