import * as Yup from 'yup';
import { getIntervalShape } from 'validators/Common/baseDockReservedIntervalValidator';

export class UpdateIntervalValidator {
  public get schema() {
    return Yup.object().shape({
      ...getIntervalShape(),
    });
  }
}
