import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler } from 'services';
import {
  UpdateOrderNoShowActions as Actions,
  UpdateOrderNoShowRequestAction as RequestAction,
} from 'store/ducks/orders';

export function* updateOrderNoShowRequest(action: any) {
  const { id, putData, onSuccess, onFailure }: RequestAction = action;
  try {
    yield call(api.put, `orders/${id}/no-show`, putData);
    onSuccess && onSuccess();
    notify('success', 'Ordem de coleta atualizado com sucesso');
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
