import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'store';
import { Scaffold } from 'layouts';
import { FormPageHeader } from 'components/Shared';
import { TypeUpdateForm } from 'components/Pages/VehicleTypes';
import * as S from './styles';

export const VehicleTypeUpdatePage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();

  const { loading } = useSelector((state: RootState) => state.fetchVehicleType);

  const onUpdate = useCallback((): void => {
    navigate('/configuracoes/tipos-veiculos');
  }, [navigate]);

  return (
    <Scaffold>
      <S.PageContainer>
        <S.MainPanel>
          <FormPageHeader
            title="Editar tipo de veículo"
            icon={<S.TruckIcon />}
            isLoading={loading}
            actions={
              <S.LinkButton size="small" to="/configuracoes/tipos-veiculos">
                <S.ArrowLeftIcon /> Voltar
              </S.LinkButton>
            }
          />
          <TypeUpdateForm typeId={id} onUpdate={onUpdate} />
        </S.MainPanel>
      </S.PageContainer>
    </Scaffold>
  );
};
