import styled from 'styled-components';
export {
  FormRow,
  Button,
  LinkButton,
  ActivityIndicator,
  FormActions,
} from 'styles/components';

export const Container = styled.div`
  width: 100%;
`;
