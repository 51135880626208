import { OrderFile } from 'contracts/OrderFiles';
import * as S from './styles';

interface Props {
  files: OrderFile[];
}

const OrderFiles = ({ files }: Props) => {
  return (
    <S.List>
      <S.ListHeader>
        <div>Documento</div>
        <div>Download</div>
      </S.ListHeader>
      {files.map((item) => (
        <S.ListItem key={item.id}>
          <S.Column>{item.fileName}</S.Column>
          <S.Column>
            <a href={item.filePath} target='_blank' rel="noreferrer" >
              <S.DownloadIcon />
            </a>
          </S.Column>
        </S.ListItem>
      ))}
    </S.List>
  );
};

export default OrderFiles;
