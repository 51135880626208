import { CompanyUpdateForm } from 'components/Pages/Companies';
import { RulesManager } from 'components/Pages/Companies/Rules';
import { Scaffold } from 'layouts';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FetchCompanyActions as FetchActions } from 'store/ducks/companies';

export const CompanyUpdatePage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onUpdate = useCallback((): void => {
    navigate('/configuracoes/clientes');
  }, [navigate]);

  const fetchCompany = useCallback((): void => {
    dispatch(FetchActions.request(id));
  }, [dispatch, id]);

  useEffect(() => {
    fetchCompany();
  }, [id]);

  return (
    <Scaffold>
      <CompanyUpdateForm companyId={id} onUpdate={onUpdate} />
      <RulesManager companyId={id} onUpdate={fetchCompany} />
    </Scaffold>
  );
};
