import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from 'contracts/Router';
import {
  OrderCreationPage,
  WarehouseSchedulePage,
  OrderPrintingPage,
} from 'pages/Scheduling';

const schedulingRoutes = ({
  isLoggedIn,
  location,
  userBelongsToAnyOf,
}: RouterProps): RouteObject => ({
  path: 'agendamento',
  element: isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: '',
      element: <WarehouseSchedulePage />,
    },
    {
      path: 'criar',
      element: userBelongsToAnyOf(
        'admin',
        'carrierMember',
        'warehouseMember',
        'companyMember'
      ) ? (
        <OrderCreationPage />
      ) : (
        <Navigate to="/agendamento" state={{ from: location }} />
      ),
    },
    {
      path: ':id/impressao',
      element: <OrderPrintingPage />,
    },
  ],
});

export default schedulingRoutes;
