import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler } from 'services';
import {
  CreateCancelationReasonActions as Actions,
  CreateCancelationReasonRequestAction as RequestAction,
} from 'store/ducks/cancelationReasons';

export function* createCancelationReasonRequest(action: any) {
  const { postData, onSuccess, onFailure }: RequestAction = action;
  try {
    yield call(api.post, `cancelation-reasons`, postData);
    onSuccess && onSuccess();
    notify('success', 'Justificativa criada com sucesso');
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
