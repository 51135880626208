import { OrderItem } from "contracts/OrderItems";

interface OrderItemsEntry
  extends Pick<OrderItem, 'document' | 'weight' | 'value'> {
  uuid: string;
}

export interface ValidData {
  scheduledAt: Date;
  weightCapacity: number;
  duration: number;
  driverDocument: string;
  cargoTypeId: number;
  carrierId: number;
  companyId: number;
  date?: Date;
  dockAndTimeframe: string;
  dockId: number;
  documents?: File[];
  material: string | null | undefined;
  driverName: string;
  observation: string | null | undefined;
  operationTypeId: number;
  orderTypeId: number;
  cellPhone?: string | null;
  vehiclePlate: string;
  vehicleTypeId: number;
  warehouseId: number;
  devolutionNumber?: string;
  exportationContainer?: string;
  exportationSecuritySeal?: string;
  importationContainer?: string;
  importationDeclaration?: string;
  importationOrder?: string;
  importationSequential?: string;
  items: OrderItemsEntry[]
}

export const convertDataToFormData = (data: ValidData) => {
  const formData = new FormData()

  formData.append('scheduledAt', data.scheduledAt.toISOString())
  formData.append('weightCapacity', data.weightCapacity.toString())
  formData.append('duration', data.duration.toString())
  formData.append('driverDocument', data.driverDocument)
  formData.append('cargoTypeId', data.cargoTypeId.toString())
  formData.append('carrierId', data.carrierId.toString())
  formData.append('companyId', data.companyId.toString())
  formData.append('dockAndTimeframe', data.dockAndTimeframe)
  formData.append('dockId', data.dockId.toString())
  formData.append('driverName', data.driverName)
  formData.append('operationTypeId', data.operationTypeId.toString())
  formData.append('orderTypeId', data.orderTypeId.toString())
  formData.append('vehiclePlate', data.vehiclePlate)
  formData.append('vehicleTypeId', data.vehicleTypeId.toString())
  formData.append('warehouseId', data.warehouseId.toString())

  if(data.cellPhone) {
    formData.append('cellPhone', data.cellPhone)
  }
  if(data.date) {
    formData.append('date', data.date.toISOString())
  }
  if(data.material) {
    formData.append('material', `${data.material}`)
  }
  if(data.observation) {
    formData.append('observation', data.observation)
  }
  if(data.devolutionNumber){
    formData.append('devolutionNumber', data.devolutionNumber)
  }
  if(data.exportationContainer){
    formData.append('exportationContainer', data.exportationContainer)
  }
  if(data.exportationSecuritySeal){
    formData.append('exportationSecuritySeal', data.exportationSecuritySeal)
  }
  if(data.importationContainer){
    formData.append('importationContainer', data.importationContainer)
  }
  if(data.importationDeclaration){
    formData.append('importationDeclaration', data.importationDeclaration)
  }
  if(data.importationOrder){
    formData.append('importationOrder', data.importationOrder)
  }
  if(data.importationSequential){
    formData.append('importationSequential', data.importationSequential)
  }
  if(data.documents) {
    data.documents.forEach((file, index) => {
      formData.append(`documents[${index}]`, file)
    })
  }
  if(data.items) {
    data.items.forEach((item, index) => {
      formData.append(`items[${index}].document`, item.document)
      formData.append(`items[${index}].value`, item.value.toString())
      formData.append(`items[${index}].weight`, item.weight.toString())
    })
  }

  return formData
}