import type { OrderStatus } from 'contracts/Orders';
import styled, { css } from 'styled-components';
import { Colors, Fonts, OrderStatusColor } from 'styles/constants';
export {
  BlockIcon,
  DockIcon,
  DownArrowSquareIcon,
  UpArrowSquareIcon,
} from 'styles/components';

// Carregamento -> seta pra cima
// Desgarregamento -> seta pra baixo

export const MainContainer = styled.div<{ recall?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  user-select: none;

  .scroll-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: max-content;
    position: relative;
    transition: all 0.7s;

    // deducing the left panel, and the page gaps
    width: calc(1920px - ${({ recall })=> recall ? '90px' : '240px'} - 48px * 3);
    @media only screen and (max-width: 1900px) {
      width: calc(100vw - ${({ recall })=> recall ? '90px' : '240px'} - 48px * 3);
    }
    // when below 1800px, the gap after the left panel goes from 48 to 24 pixels
    @media only screen and (max-width: 1800px) {
      width: calc(100vw + 24px - ${({ recall })=> recall ? '90px' : '240px'} - 48px * 3);
    }
  }
`;

export const Header = styled.div.attrs({ className: 'header' })`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 8px;
  font-family: ${Fonts.GilroySemiBold};
  font-size: 16px;
  margin-bottom: 22px;
`;

export const ScrollContainerWrapper = styled.div`
  background: linear-gradient(
    to top,
    ${Colors.White} 0px,
    ${Colors.White} 10px,
    ${Colors.Gray30} 10px,
    ${Colors.Gray30} 170px,
    ${Colors.White} 170px
  );
`;

// Time track

export const TimeTrack = styled.div.attrs({ className: 'time-track' })`
  display: flex;
  flex-direction: row;
`;

export const TimeSlot = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  height: 36px;
  border-bottom: 1px solid ${Colors.Gray40};

  &:first-child {
    > div {
      height: 0px;
    }
  }

  > div {
    border-left: 1px solid ${Colors.Gray40};
  }

  &.last-in-range {
    border-color: ${Colors.Gray30};
  }

  &.out-of-range {
    border-bottom: 1px solid ${Colors.Gray30};
    > div {
      border-color: ${Colors.Gray30};
    }
  }
`;

export const TimeLabel = styled.label`
  flex-direction: column;
  justify-content: top;
  align-items: center;
  text-align: center;
  font-size: 13px;
  font-size: ${Fonts.GilroySemiBold};
  color: ${Colors.Gray50};
  width: fit-content;
  transform: translateX(-50%);
`;

export const Marker = styled.div`
  display: flex;
  margin-top: auto;
  height: 0px;
`;

export const QuarterHourMarker = styled(Marker)`
  height: 8px;
`;

export const FullHourMarker = styled(Marker)`
  height: 16px;
`;

// Main track where we will display the orders

export const MainTrack = styled.div.attrs({ className: 'main-track' })`
  height: 180px;
  overflow: hidden;
  padding: 10px 0;
  background: linear-gradient(
    to bottom,
    ${Colors.White} 0px,
    ${Colors.White} 10px,
    ${Colors.Gray30} 10px,
    ${Colors.Gray30} 170px,
    ${Colors.White} 170px
  );
`;

// reserved interval
export const ReservedInterval = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 160px;
  position: absolute;
  background: #d1d1d1;
  z-index: 1;
  color: ${Colors.White};
`;

interface IOrder {
  negativeX?: number;
  status?: OrderStatus;
}

const applyNegativeXOnHover = (amount?: number) => {
  if (!amount) {
    return css``;
  }

  return css`
    transform: translateX(-${amount}px);
  `;
};

const applyStatusColor = (status?: OrderStatus) => {
  switch (status) {
    case 'aguardando':
      return css`
        background-color: ${OrderStatusColor.Aguardando};
      `;
    case 'chegada':
      return css`
        background-color: ${OrderStatusColor.Chegada};
      `;
    case 'iniciado':
      return css`
        background-color: ${OrderStatusColor.Iniciado};
      `;
    case 'finalizado':
      return css`
        background-color: ${OrderStatusColor.Finalizado};
      `;
    case 'liberado':
      return css`
        background-color: ${OrderStatusColor.Liberado};
      `;
    case 'noShow':
      return css`
        background-color: ${OrderStatusColor.NoShow};
      `;
    case 'cancelado':
      return css`
        background-color: ${OrderStatusColor.Cancelado};
      `;
    case 'todos':
      return css`
        background-color: ${OrderStatusColor.Todos};
      `;
    default:
      return css``;
  }
};

// order
export const Order = styled.div<IOrder>`
  display: flex;
  height: 160px;
  position: absolute;
  z-index: 1;
  padding: 8px;
  transition: transform 300ms ease-in-out;
  transform-origin: center;
  background: ${OrderStatusColor.Aguardando};
  ${({ status }) => applyStatusColor(status)}

  &.has-cut-content {
    .carrier,
    .company,
    .operation {
      opacity: 0;
      transform: translateX(200px);
    }

    &:hover {
      ${({ negativeX }) => applyNegativeXOnHover(negativeX)};
      .carrier,
      .company,
      .operation {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 200ms 300ms ease-in-out,
          transform 400ms 300ms ease-in-out;
      }
    }
  }

  :hover {
    cursor: pointer;
    transform: scale(1.05);
    border-radius: 4px;
    box-shadow: #00000033 0 2px 4px 0px;
    z-index: 2;
    min-width: 256px;
  }

  &.not-owner {
    background: ${Colors.Gray50};
  }
`;

export const OrderContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
  color: ${Colors.White};
  font-size: 11px;
  font-family: ${Fonts.GilroyBold};
  text-transform: uppercase;
  overflow: hidden;
`;

// order header

export const OrderHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0 24px;
`;

export const OrderHeaderIcon = styled.div``;

export const OrderHeaderMain = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  text-align: right;
  gap: 2px 0;
`;

export const CarrierName = styled.div.attrs({ className: 'carrier' })``;

export const CompanyName = styled.div.attrs({ className: 'company' })`
  font-family: ${Fonts.GilroyRegular};
  font-size: 12px;
`;

// order footer

export const OrderFooter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0 24px;
  margin-top: auto;
`;

export const VehiclePlate = styled.div.attrs({ className: 'vehicle-plate' })``;

export const OperationName = styled.div.attrs({ className: 'operation' })`
  flex: 1 1 100%;
  text-align: right;
`;
