import * as Yup from 'yup';

export class CreateWarehouseMemberAccess {
  public get schema() {
    return Yup.object().shape({
      warehouseId: Yup.number()
        .typeError('Informe o id do armazém')
        .required('Informe o id do armazém'),
    });
  }
}
