import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler } from 'services';
import {
  UpdateDockReservedIntervalActions as Actions,
  UpdateDockReservedIntervalRequestAction as RequestAction,
} from 'store/ducks/dockReservedIntervals';

export function* updateDockReservedIntervalRequest(action: any) {
  const { id, putData, onSuccess, onFailure }: RequestAction = action;
  try {
    yield call(api.put, `dock-reserved-intervals/${id}`, putData);
    onSuccess && onSuccess();
    notify('success', 'Bloqueio de horário atualizado com sucesso');
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
