import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { CompactSelect } from 'components/Shared';
import { FindMany, SelectOption } from 'contracts/Common';
import { useAuth, useDocks, useWarehouses } from 'hooks';
import { throttle } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as S from './styles';

export interface FindIntervals extends FindMany {
  warehouseId?: unknown;
  dockId?: unknown;
}

interface Props {
  onFilter?: (query: FindIntervals) => void;
  currentFilter: FindIntervals;
  delay?: number;
}

interface GeneralState {
  warehouseId?: string | number;
}

export const IntervalFilters: React.FC<Props> = ({
  delay = 1000,
  currentFilter,
  onFilter,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { warehouseOptions, loadingWarehouses, fetchWarehouses } =
    useWarehouses();
  const { dockOptions, loadingDocks, fetchDocks } = useDocks();
  const { profile } = useAuth();

  const [generalState, setGeneralState] = useState<GeneralState>({});

  const [filters, setFilters] = useState<FindIntervals>({
    ...currentFilter,
    dirty: false,
  });

  const handleWarehouseChange = useCallback(
    (option: SelectOption | null): void => {
      formRef.current?.setFieldValue('dockId', null);
      setFilters((state) => ({
        ...state,
        // if dockId is present on currentFilter, dont make the filter dirty right now
        dirty: currentFilter?.dockId ? false : true,
        warehouseId: option?.value,
      }));
      setGeneralState((state) => ({
        ...state,
        warehouseId: option?.value,
      }));
      if (option) {
        fetchDocks({ warehouseId: option.value });
      }
    },
    [currentFilter?.dockId, fetchDocks]
  );

  const handleDockChange = useCallback((option: SelectOption | null): void => {
    setFilters((state) => ({
      ...state,
      dirty: true,
      dockId: option?.value,
    }));
  }, []);

  const setDefaultWarehouse = useCallback(() => {
    const warehouseId =
      profile?.warehouse?.id ??
      currentFilter?.warehouseId ??
      warehouseOptions[0]?.value ??
      null;

    if (!warehouseId) return;

    const warehouse = warehouseOptions.find(
      (option) => option.value === warehouseId
    );

    if (!warehouse) return;

    formRef.current?.setFieldValue('warehouseId', warehouse);
  }, [currentFilter, profile, warehouseOptions]);

  const setDefaultDock = useCallback(() => {
    if (!currentFilter?.dockId) return;

    const option = dockOptions.find(
      (option) => option.value === currentFilter.dockId
    );

    if (option) {
      formRef.current?.setFieldValue('dockId', option);
    }
  }, [currentFilter, dockOptions]);

  // throttled methods

  const onWarehouseChange = useMemo(
    () => throttle(handleWarehouseChange, delay),
    [delay, handleWarehouseChange]
  );

  const onDockChange = useMemo(
    () => throttle(handleDockChange, delay),
    [delay, handleDockChange]
  );

  useEffect(() => {
    fetchWarehouses();
  }, [fetchWarehouses]);

  useEffect(() => {
    setDefaultWarehouse();
  }, [warehouseOptions]);

  useEffect(() => {
    setDefaultDock();
  }, [setDefaultDock]);

  const invokeOnFilter = useCallback((): void => {
    if (filters.dirty && onFilter) {
      onFilter(filters);
    }
  }, [filters, onFilter]);

  useEffect(() => {
    invokeOnFilter();
  }, [invokeOnFilter]);

  return (
    <S.Container>
      <Form ref={formRef} onSubmit={() => {}}>
        <CompactSelect
          name="warehouseId"
          placeholder="Armazém"
          options={warehouseOptions}
          isLoading={loadingWarehouses}
          onChange={onWarehouseChange}
        />
        <CompactSelect
          name="dockId"
          placeholder="Doca"
          options={dockOptions}
          isLoading={loadingDocks}
          onChange={onDockChange}
          isDisabled={!generalState.warehouseId}
        />
      </Form>
    </S.Container>
  );
};
