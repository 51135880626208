import {
  OperationTypeEnum,
  SCHEDULING_DATE_PICKER_INTERVAL,
} from 'constants/Common';
import { addDays, format, isAfter, isPast, isToday } from 'date-fns';
import { Validator } from 'utils';
import * as Yup from 'yup';

interface Props {
  operationTypeId: number;
  isCarrier: boolean;
}

export class CreateOrderValidator {
  private acceptExt = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "application/pdf",
  ];
  constructor(private props: Props) {}
  public get schema() {
    const isCarrier = this.props.isCarrier;
    return Yup.object().shape({
      // parent relationships
      orderTypeId: Yup.number()
        .typeError('Selecione o tipo de ordem')
        .required('Selecione o tipo de ordem'),
      operationTypeId: Yup.number()
        .typeError('Selecione o tipo de operação')
        .required('Selecione o tipo de operação'),
      vehicleTypeId: Yup.number()
        .typeError('Selecione o tipo de veículo')
        .required('Selecione o tipo de veículo'),
      cargoTypeId: Yup.number()
        .typeError('Selecione o tipo de carga')
        .required('Selecione o tipo de carga'),
      companyId: Yup.number()
        .typeError('Selecione o cliente')
        .required('Selecione o cliente'),
      carrierId: Yup.number()
        .typeError('Selecione a transportadora')
        .required('Selecione a transportadora'),
      warehouseId: Yup.number()
        .typeError('Selecione o armazém')
        .required('Selecione o armazém'),
      // this will show up on the form for validation purposes
      date: Yup.date()
        .typeError('Informe uma data')
        .test({
          name: 'testDate',
          test: function (value) {
            try {
              if (!value) {
                throw new Error('Data inválida');
              }

              const maxDate = addDays(
                new Date(),
                SCHEDULING_DATE_PICKER_INTERVAL
              );

              if (isPast(value) && !isToday(value)) {
                throw new Error('A data não pode estar no passado');
              }

              if (isCarrier) {
                if (isToday(value)) {
                  throw new Error('A data não pode ser hoje');
                }
              }

              if (isAfter(value, maxDate)) {
                throw new Error(
                  `A data não pode ser posterior à ${format(
                    maxDate,
                    'dd/MM/yyyy'
                  )}`
                );
              }

              return true;
            } catch (error: any) {
              return this.createError({
                message: error?.message,
              });
            }
          },
        }),
      // this will show up on the form for validation purposes
      dockAndTimeframe: Yup.string().required('Selecione a doca e horário'),
      // ===============================================
      // the following will not be shown on the form, but will be sent to the API
      dockId: Yup.number().required('Informe o id da doca'),
      scheduledAt: Yup.date()
        .typeError('Data inválida')
        .required('Data inválida'),
      weightCapacity: Yup.number().required('Informe a capacidade de peso'),
      duration: Yup.number().required('Informe a duração'),
      // ===============================================
      driverName: Yup.string().required('Informe o nome do motorista'),
      cellPhone: Yup.string().nullable().optional(),
      driverDocument: Yup.mixed().test({
        name: 'testDocument',
        test: function (value: string) {
          try {
            Validator.validateDocument(value, 'cpf');
            return true;
          } catch (error: any) {
            return this.createError({
              message: error?.message,
            });
          }
        },
      }),
      vehiclePlate: Yup.string().required('Informe a placa do veículo'),
      // pseudo-required stuff
      ...(this.props?.operationTypeId === OperationTypeEnum.DEVOLUCAO && {
        devolutionNumber: Yup.string().required(
          'Informe o número da order de devolução'
        ),
      }),
      ...(this.props?.operationTypeId === OperationTypeEnum.EXPORTACAO && {
        exportationContainer: Yup.string().required(
          'Informe a indentificação do container'
        ),
        exportationSecuritySeal: Yup.string().required(
          'Informe a identificação do lacre de segurança'
        ),
      }),
      ...(this.props?.operationTypeId === OperationTypeEnum.IMPORTACAO && {
        importationDeclaration: Yup.string().required(
          'Informe o número da declaração de importação'
        ),
        importationOrder: Yup.string().required(
          'Informe o número da ordem de importação'
        ),
        importationSequential: Yup.string().required(
          'Informe o número sequencial da importação'
        ),
        importationContainer: Yup.string().required(
          'Informe a indentificação do container'
        ),
      }),
      // optional stuff
      observation: Yup.string()
        .nullable()
        .transform((value: string) => {
          if (!value) return null;
          return value.trim();
        }),
      material: Yup.string()
        .nullable()
        .transform((value: string) => {
          if (!value) return null;
          return value.trim();
        }),
      documents: Yup.array().of(Yup.mixed())
        .test(
          "file",
          "O arquivo deve ser PNG, JPG, JPEG ou PDF",
          async (value) => {
            if(!value) return true

            return value.every(file => this.acceptExt.includes(file.type))
          }
        )
        .test(
          "fileSize",
          "O tamanho máximo do arquivo é de 5 MB",
          (value) => {
            if (!value) return true;

            return value.every(file => file.size <= 5242880);
          }
        ).optional(),
    });
  }
}
