import { FormPageHeader } from 'components/Shared/PageFragments';
import { useAuth } from 'hooks';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppDispatch, RootState } from 'store';
import { WarehouseScheduleParamsCacheActions as CacheActions } from 'store/ducks/cache';
import { ListWarehouseScheduleEntriesActions as MainActions } from 'store/ducks/scheduling';
import OrderModal, { Ref as OrderModalRef } from '../OrderModal';
import Filters, { FindOrders } from './Filters';
import ScheduleEntry from './ScheduleEntry';
import * as S from './styles';

export const WarehouseSchedule: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const { userBelongsToAnyOf } = useAuth();
  const orderModalRef = useRef<OrderModalRef>(null);
  const { data: cachedQuery } = useSelector(
    (state: RootState) => state.warehouseScheduleParamsCache
  );

  const [query, setQuery] = useState<FindOrders>({
    status: [
      'aguardando',
      'chegada',
      'iniciado',
      'finalizado',
      'liberado',
      'noShow',
      'cancelado',
    ],
    ...cachedQuery,
  });

  const { data: scheduleEntries, loading: loadingEntries } = useSelector(
    (state: RootState) => state.listWarehouseScheduleEntries
  );

  const reloadScheduleEntries = useCallback((): void => {
    dispatch(MainActions.request(query));
  }, [dispatch, query]);

  const onFilter = useCallback(
    (filter: FindOrders): void => {
      const { dirty: oldDirty, ...oldState } = query;
      const { dirty: newDirty, ...newState } = filter;
      if (isEqual(oldState, newState)) return;
      setQuery((state) => ({ ...state, ...newState }));
    },
    [query]
  );

  const onQueryChange = useCallback((): void => {
    const { warehouseId, date, status } = query;
    if (warehouseId && date && status) {
      dispatch(MainActions.request(query));
    }
  }, [dispatch, query]);

  const CreateOrderButton = useCallback((): JSX.Element => {
    if (
      !userBelongsToAnyOf(
        'admin',
        'carrierMember',
        'warehouseMember',
        'companyMember'
      )
    ) {
      return <></>;
    }

    return (
      <S.LinkButton
        size="small"
        to="/agendamento/criar"
        state={{ from: location.pathname }}
      >
        <S.PlusIcon /> Novo agendamento
      </S.LinkButton>
    );
  }, [location.pathname, userBelongsToAnyOf]);

  useEffect(() => {
    onQueryChange();
  }, [onQueryChange]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
      dispatch(CacheActions.updateCache(query));
    };
  }, [dispatch, query]);

  return (
    <S.Container>
      <S.Panel>
        {/* <pre>{JSON.stringify(query, null, 2)}</pre> */}
        <OrderModal onUpdate={reloadScheduleEntries} ref={orderModalRef} />
        <FormPageHeader
          title="Agendamento"
          icon={<S.CalendarIcon />}
          isLoading={loadingEntries}
          actions={<CreateOrderButton />}
        />
        <Filters currentFilter={query} onFilter={onFilter} />
        <S.ScheduleEntries>
          {scheduleEntries.map((entry) => (
            <ScheduleEntry
              onSelectOrder={(orderId) =>
                orderModalRef.current?.selectOrder(orderId)
              }
              key={entry.dock.id}
              entry={entry}
            />
          ))}
        </S.ScheduleEntries>
      </S.Panel>
    </S.Container>
  );
};
