import { SelectOption } from 'contracts/Common';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { ListCancelationReasonsActions as MainActions } from 'store/ducks/cancelationReasons';

interface FindCancelationReasons {
  excludeBlocked?: boolean;
}

export const useCancelationReasons = () => {
  const dispatch: AppDispatch = useDispatch();

  const { data: cancelationReasons, loading: loadingCancelationReasons } =
    useSelector((state: RootState) => state.listCancelationReasons);

  const [ignoredBlockedId, enableCancelationReasonIfBlocked] = useState<
    number | null
  >(null);

  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchCancelationReasons = useCallback(
    (query: FindCancelationReasons): void => {
      dispatch(MainActions.request(query));
    },
    [dispatch]
  );

  const onCancelationReasonsLoad = useCallback((): void => {
    setOptions(
      cancelationReasons.map(({ id, name, blockedAt }) => ({
        value: id,
        label: name,
        isDisabled: ignoredBlockedId !== id && blockedAt !== null,
      }))
    );
  }, [ignoredBlockedId, cancelationReasons]);

  useEffect(() => {
    onCancelationReasonsLoad();
  }, [onCancelationReasonsLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    cancelationReasons,
    cancelationReasonOptions: options,
    loadingCancelationReasons,
    fetchCancelationReasons,
    enableCancelationReasonIfBlocked,
  };
};

export type CancelationReasonsHook = ReturnType<typeof useCancelationReasons>;
