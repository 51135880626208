import styled from 'styled-components';
import { Colors } from 'styles/constants';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: +10;
  display: none;
  &.open {
    display: initial;
    animation: ModalSlideUp 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    .modal-content {
      animation: OverlayDarkening 1200ms ease forwards;
    }
  }
`;

export const Modal = styled.div.attrs({
  className: 'modal',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-left: auto;
  background: ${Colors.Gray20};
  color: ${Colors.Gray50};
  width: 28px;
  height: 28px;
  border-radius: 50%;
  font-family: sans-serif;
  font-weight: bolder;
  font-size: 12px;
  line-height: 12px;
  transition: all 200ms ease-in-out;
  &:hover {
    background: ${Colors.Magenta};
    color: ${Colors.White};
  }
`;
