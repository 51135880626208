import { Order } from 'contracts/Orders';
import * as Yup from 'yup';

interface Props {
  order: Order;
}

export class UpdateOrderEventsValidator {
  constructor(private props: Props) {}

  public get schema() {
    const { vehicleArrivedAt, startedAt, finishedAt } = this.props.order;

    return Yup.object().shape({
      vehicleArrivedAt: Yup.string().required('Informe o horário'),
      ...(vehicleArrivedAt && {
        startedAt: Yup.string().test({
          name: 'testStartedAt',
          test: function (time) {
            try {
              if (!time) {
                throw new Error('Informe o horário');
              }

              if (this.parent.vehicleArrivedAt > time) {
                throw new Error('Deve ser após o anterior');
              }

              return true;
            } catch (error: any) {
              return this.createError({
                message: error?.message,
              });
            }
          },
        }),
      }),
      ...(startedAt && {
        finishedAt: Yup.string().test({
          name: 'testFinishedAt',
          test: function (time) {
            try {
              if (!time) {
                throw new Error('Informe o horário');
              }

              if (this.parent.startedAt > time) {
                throw new Error('Deve ser após o anterior');
              }

              return true;
            } catch (error: any) {
              return this.createError({
                message: error?.message,
              });
            }
          },
        }),
      }),
      ...(finishedAt && {
        vehicleDepartedAt: Yup.string().test({
          name: 'testVehicleDepartedAt',
          test: function (time) {
            try {
              if (!time) {
                throw new Error('Informe o horário');
              }

              if (this.parent.finishedAt > time) {
                throw new Error('Deve ser após o anterior');
              }

              return true;
            } catch (error: any) {
              return this.createError({
                message: error?.message,
              });
            }
          },
        }),
      }),
    });
  }
  public get schemaAdmin() {
    const { vehicleArrivedAt, startedAt, finishedAt } = this.props.order;

    return Yup.object().shape({
      vehicleArrivedAt: Yup.string().required('Informe o horário'),
      ...(vehicleArrivedAt && {
        startedAt: Yup.string().test({
          name: 'testStartedAt',
          test: function (time) {
            try {
              if (time && this.parent.vehicleArrivedAt > time) {
                throw new Error('Deve ser após o anterior');
              }

              return true;
            } catch (error: any) {
              return this.createError({
                message: error?.message,
              });
            }
          },
        }),
      }),
      ...(startedAt && {
        finishedAt: Yup.string().test({
          name: 'testFinishedAt',
          test: function (time) {
            try {
              if (time && this.parent.startedAt > time) {
                throw new Error('Deve ser após o anterior');
              }

              return true;
            } catch (error: any) {
              return this.createError({
                message: error?.message,
              });
            }
          },
        }),
      }),
      ...(finishedAt && {
        vehicleDepartedAt: Yup.string().test({
          name: 'testVehicleDepartedAt',
          test: function (time) {
            try {
              if (time && this.parent.finishedAt > time) {
                throw new Error('Deve ser após o anterior');
              }

              return true;
            } catch (error: any) {
              return this.createError({
                message: error?.message,
              });
            }
          },
        }),
      }),
    });
  }
}
