import { OrderItem } from 'contracts/OrderItems';
import React from 'react';
import { Formatter } from 'utils';
import * as S from './styles';

interface Props {
  items: OrderItem[];
}

const OrderItems: React.FC<Props> = ({ items }) => {
  return (
    <S.List>
      <S.ListHeader>
        <div>Documento</div>
        <div>Peso</div>
        <div>Valor</div>
      </S.ListHeader>
      {items.map((item) => (
        <S.ListItem key={item.id}>
          <S.Column>{item.document}</S.Column>
          <S.Column>{Formatter.weight(item.weight)}</S.Column>
          <S.Column>{Formatter.currency(item.value)}</S.Column>
        </S.ListItem>
      ))}
    </S.List>
  );
};

export default OrderItems;
