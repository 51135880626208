import { CheckboxOption } from 'contracts/Common';
import { OrderStatusColor } from 'styles/constants';

const ORDER_STATUS: CheckboxOption[] = [
  {
    id: '1',
    value: 'aguardando',
    label: 'Aguardando',
    color: OrderStatusColor.Aguardando,
  },
  {
    id: '2',
    value: 'chegada',
    label: 'Chegada',
    color: OrderStatusColor.Chegada,
  },
  {
    id: '3',
    value: 'iniciado',
    label: 'Iniciado',
    color: OrderStatusColor.Iniciado,
  },
  {
    id: '4',
    value: 'finalizado',
    label: 'Finalizado',
    color: OrderStatusColor.Finalizado,
  },
  {
    id: '5',
    value: 'liberado',
    label: 'Liberado',
    color: OrderStatusColor.Liberado,
  },
  {
    id: '6',
    value: 'noShow',
    label: 'No show',
    color: OrderStatusColor.NoShow,
  },
  {
    id: '7',
    value: 'cancelado',
    label: 'Cancelado',
    color: OrderStatusColor.Cancelado,
  },
  {
    id: '8',
    value: 'todos',
    label: 'Todos',
    color: OrderStatusColor.Todos,
  }
];

export const ALL_ORDER_STATUS = [
  "aguardando",
  "chegada",
  "iniciado",
  "finalizado",
  "liberado",
  "noShow",
  "cancelado",
  "todos"
]

export const CHECKBOX_OPTIONS = {
  ORDER_STATUS,
};
