import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Scaffold } from 'layouts';
import { FormPageHeader } from 'components/Shared';
import {
  MemberUpdateForm,
  CompanyAccessManager,
} from 'components/Pages/CompanyMembers';
import * as S from './styles';
import { RulesManager } from 'components/Pages/CompanyMembers/Rules';
import { useDispatch } from 'react-redux';
import { FetchCompanyMemberActions as FetchActions } from 'store/ducks/companyMembers';

export const CompanyMemberUpdatePage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading } = useSelector(
    (state: RootState) => state.fetchCompanyMember
  );

  const fetchMember = useCallback((): void => {
    dispatch(FetchActions.request(id));
  }, [dispatch, id]);

  const onUpdate = useCallback((): void => {
    navigate('/configuracoes/clientes/usuarios');
  }, [navigate]);

  useEffect(() => {
    fetchMember();
  }, [fetchMember]);

  return (
    <Scaffold>
      <S.PageContainer>
        <S.MainPanel>
          <FormPageHeader
            icon={<S.UserRectangleIcon />}
            title="Editar usuário de cliente"
            isLoading={loading}
            actions={
              <S.LinkButton size="small" to="/configuracoes/clientes/usuarios">
                <S.ArrowLeftIcon /> Voltar
              </S.LinkButton>
            }
          />
          <MemberUpdateForm memberId={id} onUpdate={onUpdate} />
        </S.MainPanel>
        <RulesManager companyMemberId={id} onUpdate={fetchMember} />
      </S.PageContainer>
    </Scaffold>
  );
};
