import styled from "styled-components";
import { ColorScheme, Colors, Fonts } from "styles/constants";
import { FieldContainer, Upload } from 'styles/components';

export {
  FieldError,
  FieldLabel,
  ActivityIndicator,
} from 'styles/components';

export const Container = styled(FieldContainer)`
  input {
    width: 100%;
    font-size: inherit;
    border-radius: 6px;
    background: ${Colors.White};
    border: 1px solid ${Colors.Gray50};
    color: ${ColorScheme.Text};
    height: 48px;
    padding: 0 16px;
    position: absolute;
    left: -30000px;
    visibility: hidden;
  }
`;

export const FakeInput = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${Fonts.OpenSans};
  font-size: 14px;
  color: ${ColorScheme.Text};
  background-color: #fff;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  height: 50px;
  width: 100%;
`;

export const FileName = styled.div`
  padding: 16px;
`;

export const AuxText = styled.span`
  display: block;
  font-size: 14px;
  font-family: ${Fonts.GilroySemiBold};
  color: ${ColorScheme.Text};
`

export const Button = styled.button.attrs({
  type: "button",
})`
  background-color: ${ColorScheme.Secondary};
  color: #fff;
  padding: 0px 32px;
  border-radius: 0px 6px 6px 0px;
`;

export const ButtonIcon = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  height: 32px;
  color: ${ColorScheme.Text};
`;

export const UploadIcon = styled(Upload)`
  height: 1.6rem;
  width: 1.6rem;
`