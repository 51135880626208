import styled from 'styled-components';
import { BasePanel } from 'styles/components';
import { Colors } from 'styles/constants';
export {
  FormRow,
  Button,
  LinkButton,
  ActivityIndicator,
  FormActions,
  TruckIcon,
  ArrowLeftIcon,
} from 'styles/components';

export const MainPanel = styled(BasePanel)`
  flex: 0 1 100%;
  flex-direction: column;
  background: ${Colors.White};
  padding: 24px;
`;
