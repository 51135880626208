import { SortableHeader, SortableHeadersGroup } from 'components/Shared';
import { SortingParams } from 'contracts/Common';
import type { PaginatedOrder } from 'contracts/Orders';
import React, { useCallback } from 'react';
import { Formatter } from 'utils';
import * as S from './styles';
import * as HoverCard from '@radix-ui/react-hover-card';

interface Props {
  orders: PaginatedOrder[];
  currentSort: SortingParams;
  onSort?: (sort: SortingParams) => void;
  onSelectOrder?: (orderId: number) => void;
}

interface ItemProps {
  order: PaginatedOrder;
}

const OrdersList: React.FC<Props> = ({
  orders,
  currentSort,
  onSort,
  onSelectOrder,
}) => {
  const Item = useCallback(
    ({ order }: ItemProps): JSX.Element => {
      const {
        id,
        orderItems,
        scheduledAt,
        dock,
        totalWeight,
        totalValue,
        vehicleType,
        vehiclePlate,
        company,
        carrier,
        status,
        orderReader,
      } = order;

      return (
        <S.ListItem>
          <S.Column>{`${id}`.padStart(4, '0')}</S.Column>
          <S.Column>
            <S.StatusLabel status={status}>
              {Formatter.orderStatusName(status)}
            </S.StatusLabel>
          </S.Column>
          <S.Column>{orderItems.length}</S.Column>
          <S.Column>
            {Formatter.date(scheduledAt, { format: 'dd/MM/yy HH:mm' })}
          </S.Column>
          <S.Column>{dock.name}</S.Column>
          <S.Column>{Formatter.weight(totalWeight)}</S.Column>
          <S.Column>{Formatter.currency(totalValue)}</S.Column>
          <S.Column>{vehicleType.name}</S.Column>
          <S.Column>{vehiclePlate}</S.Column>
          <S.Column title={company.tradeName}>{company.tradeName}</S.Column>
          <S.Column title={carrier.tradeName}>{carrier.tradeName}</S.Column>
          <S.ActionsColumn>
            {orderReader && (
              <>
                <HoverCard.Root>
                  <HoverCard.Trigger asChild>
                    <a
                      href="#"
                      rel="noreferrer noopener"
                    >
                      <S.Eye />
                    </a>
                  </HoverCard.Trigger>
                  <HoverCard.Portal>
                    <S.HoverCardContent>
                      <S.HoverCardArrow />
                      <p>{orderReader.user.name} <br/> {Formatter.date(orderReader.createdAt, { format: "dd/MM/yyyy - HH:mm" })}</p>
                    </S.HoverCardContent>
                  </HoverCard.Portal>
                </HoverCard.Root>
              </>
              
            )}
            <S.ActionButton mood="void" onClick={() => onSelectOrder?.(id)}>
              <S.ChevronRightCircleIcon />
            </S.ActionButton>
          </S.ActionsColumn>
        </S.ListItem>
      );
    },
    [onSelectOrder]
  );

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={currentSort}>
          <SortableHeader column="id" label="COLETA" />
          <SortableHeader column="statusName" label="STATUS" />
          <SortableHeader column="itemsCount" label="DOCS" />
          <SortableHeader column="scheduledAt" label="DATA" />
          <SortableHeader column="dockName" label="DOCA" />
          <SortableHeader column="totalWeight" label="PESO" />
          <SortableHeader column="totalValue" label="VALOR" />
          <SortableHeader column="vehicleTypeName" label="VEÍCULO" />
          <SortableHeader column="vehiclePlate" label="PLACA" />
          <SortableHeader column="companyTradeName" label="CLIENTE" />
          <SortableHeader column="carrierTradeName" label="TRANSPORTADORA" />
        </SortableHeadersGroup>
      </S.ListHeader>
      {orders.map((order) => (
        <Item key={order.id} order={order} />
      ))}
    </S.List>
  );
};

export default OrdersList;
