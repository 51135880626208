import { Form } from '@unform/web';
import { CompactSearch } from 'components/Shared';
import { FindMany } from 'contracts/Common';
import { throttle } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as S from './styles';
import { DownloadXLS } from 'components/Shared/DownloadXLS';
import { ExportWarehouseMembersActions as ExportActions } from 'store/ducks/warehouseMembers';
import { RootState } from 'store';
import { ExportWarehouseMember } from 'contracts/WarehouseMembers';
import { useSelector } from 'react-redux';
import { Formatter, exportXLSX } from 'utils';
import { useDispatch } from 'react-redux';

export interface FindMembers extends FindMany {}

interface Props {
  onFilter?: (query: FindMembers) => void;
  currentFilter: FindMembers;
  delay?: number;
  isRoot?: boolean;
}

export const MemberFilters: React.FC<Props> = ({
  delay = 1000,
  currentFilter,
  onFilter,
  isRoot,
}) => {
  const [filters, setFilters] = useState<FindMembers>({
    ...currentFilter,
    dirty: false,
  });

  const dispatch = useDispatch();

  const { loading: loadingExport } = useSelector(
    (state: RootState) => state.exportWarehouseMembers
  );

  const prepareData = useCallback((data?: ExportWarehouseMember[]) => {
    if (!data?.length) return;

    const not = '';
    const fileName = 'RELATORIO_USUARIO_ARMAZEM';
    const downloadData = [];

    for (const exportData of data) {
      const { user, warehouse, warehouseAccess } = exportData;
      downloadData.push({
        'ID': user?.id ?? not,
        'NOME': user?.name ?? not,
        'EMAIL': user?.email ?? not,
        'ARMAZÉM': warehouse?.tradeName ?? not,
        'ACESSO A OUTROS ARMAZÉNS': warehouseAccess.length ? 'Sim' : 'Não',
        'DOCUMENTO':
          Formatter.document(warehouse?.document, warehouse.documentType) ??
          not,
        'UF': warehouse?.addressUf ?? not,
        'CIDADE': warehouse?.addressCity ?? not,
      });
    }
    exportXLSX(downloadData, fileName);
  }, []);

  const onExport = useCallback(() => {
    dispatch(ExportActions.request(filters, prepareData));
  }, [dispatch, filters]);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setFilters((state) => ({
        ...state,
        dirty: true,
        search: e.target.value,
      }));
    },
    []
  );

  // throttled methods

  const onSearch = useMemo(
    () => throttle(handleSearch, delay),
    [delay, handleSearch]
  );

  useEffect(() => {
    if (filters.dirty && onFilter) {
      onFilter(filters);
    }
  }, [filters, onFilter]);

  return (
    <S.Container>
      <Form onSubmit={() => {}}>
        <CompactSearch
          onChange={onSearch}
          defaultValue={filters?.search}
          placeholder="Buscar"
          name="search"
        />
        {Boolean(isRoot) && (
          <S.ButtonWrapper>
            <DownloadXLS onExport={onExport} loading={loadingExport} />
          </S.ButtonWrapper>
        )}
      </Form>
    </S.Container>
  );
};
