import { OrderStatus } from 'contracts/Orders';
import styled, { css } from 'styled-components';
import {
  ModalHeader as BaseModalHeader,
  LinkButton as BaseLinkButton,
} from 'styles/components';
import { Colors, Fonts, OrderStatusColor } from 'styles/constants';
export {
  ActivityIndicator,
  Button,
  LinkButton,
  FormActions,
  FormRow,
  ModalBody,
  ModalContent,
  PackageIcon,
  PrinterIcon,
} from 'styles/components';

// Header

export const ModalHeader = styled(BaseModalHeader)`
  justify-content: space-between;
`;

const HeaderSegment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderTitle = styled(HeaderSegment)`
  gap: 0 8px;
  flex: 0 1 270px;
`;

export const HeaderCenter = styled(HeaderSegment)`
  flex: 1;
  justify-content: center;
`;

export const Reader = styled.div`
  font-family: ${Fonts.GilroyRegular};
  font-size: 14px;
  margin-left: 16px;
`;

const applyStatusColor = (status?: OrderStatus) => {
  switch (status) {
    case 'aguardando':
      return css`
        background-color: ${OrderStatusColor.Aguardando};
      `;
    case 'chegada':
      return css`
        background-color: ${OrderStatusColor.Chegada};
      `;
    case 'iniciado':
      return css`
        background-color: ${OrderStatusColor.Iniciado};
      `;
    case 'finalizado':
      return css`
        background-color: ${OrderStatusColor.Finalizado};
      `;
    case 'liberado':
      return css`
        background-color: ${OrderStatusColor.Liberado};
      `;
    case 'noShow':
      return css`
        background-color: ${OrderStatusColor.NoShow};
      `;
    case 'cancelado':
      return css`
        background-color: ${OrderStatusColor.Cancelado};
      `;
    default:
      return css``;
  }
};

interface IStatusLabel {
  status?: OrderStatus;
}

export const StatusLabel = styled.label<IStatusLabel>`
  display: flex;
  padding: 8px;
  font-family: ${Fonts.GilroyBold};
  font-size: 14px;
  border-radius: 4px;
  color: ${Colors.White};
  background-color: ${Colors.Blue};
  ${({ status }) => applyStatusColor(status)}
`;

export const HeaderButtons = styled(HeaderSegment)`
  flex: 0 1 calc(270px - 32px);
  gap: 0 24px;
  padding-right: 16px;
  button,
  a {
    padding: 0px;
    &:first-child {
      margin-left: auto;
    }
  }
`;

export const PrintButton = styled(BaseLinkButton)``;

// Body

const BaseGrid = styled.div`
  display: grid;
  gap: 32px 24px;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const MainGrid = styled(BaseGrid)`
  display: grid;
  gap: 32px 24px;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const InfoGrid = styled(BaseGrid)`
  grid-template-columns: 1fr 1fr;
`;

export const WarehouseGrid = styled(BaseGrid)`
  > div:nth-child(1) {
    grid-area: warehouse;
  }
  > div:nth-child(2) {
    grid-area: dock;
  }
  > div:nth-child(3) {
    grid-area: address;
  }

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'warehouse dock address address';

  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'warehouse dock' 'address address';
  }
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DetailLabel = styled.label`
  font-family: ${Fonts.GilroySemiBold};
  font-size: 14px;
`;

export const DetailValue = styled.span`
  font-family: ${Fonts.GilroyRegular};
  font-size: 14px;
`;

// Canceled order reason alert
// TODO: Move this to its own component if another alert is to be used
export const Alert = styled.div`
  background-color: ${Colors.PaleGold};
  padding: 16px 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  font-size: 14px;
`;

export const AlertTitle = styled.label`
  font-family: ${Fonts.GilroySemiBold};
`;

export const AlertMessage = styled.span`
  font-family: ${Fonts.GilroyRegular};
`;

export const TabsContainer = styled.div``;
