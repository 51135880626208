import { SortableHeader, SortableHeadersGroup } from 'components/Shared';
import { EMPTY_COLUMN_VALUE } from 'constants/Common';
import { PaginatedCarrierMember } from 'contracts/CarrierMembers';
import { SortingParams } from 'contracts/Common';
import React, { useCallback } from 'react';
import { Formatter } from 'utils';
import * as S from './styles';

interface Props {
  members: PaginatedCarrierMember[];
  onSort?: (sort: SortingParams) => void;
  onImpersonate?: (email: string) => void;
  currentSort: SortingParams;
  isRoot?: boolean;
}

interface ItemProps {
  member: PaginatedCarrierMember;
}

export const MembersList: React.FC<Props> = ({
  members,
  onSort,
  onImpersonate,
  currentSort,
  isRoot,
}) => {
  const Item = useCallback(
    ({ member }: ItemProps): JSX.Element => {
      const { id, carrier, user } = member;

      return (
        <S.ListItem>
          <S.Column>{id}</S.Column>
          <S.Column title={user.name}>{user.name}</S.Column>
          <S.Column title={user.email}>{user.email}</S.Column>
          <S.Column title={carrier.tradeName}>{carrier.tradeName}</S.Column>
          <S.Column>
            {user.loggedAt
              ? Formatter.date(user.loggedAt, {
                  format: "dd/MM/yyyy 'as' HH:mm",
                })
              : EMPTY_COLUMN_VALUE}
          </S.Column>
          {Boolean(isRoot) && (
            <S.ActionsColumn>
              {!!user.blockedAt && <S.LockIcon title="Usuário bloqueado" />}
              <S.ActionButton
                mood="void"
                title="Fazer login como este usuário"
                onClick={() => onImpersonate?.(user.email)}
              >
                <S.LoginIcon />
              </S.ActionButton>
              <S.LinkActionButton
                to={`/configuracoes/transportadoras/usuarios/${id}/editar`}
              >
                <S.EditIcon />
              </S.LinkActionButton>
            </S.ActionsColumn>
          )}
        </S.ListItem>
      );
    },
    [onImpersonate]
  );

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={currentSort}>
          <SortableHeader column="id" label="ID" />
          <SortableHeader column="userName" label="NOME" />
          <SortableHeader column="userEmail" label="E-MAIL" />
          <SortableHeader column="carrierName" label="TRANSPORTADORA" />
          <SortableHeader column="userLoggedAt" label="ÚLTIMO LOGIN" />
        </SortableHeadersGroup>
      </S.ListHeader>
      {members.map((member) => (
        <Item key={member.id} member={member} />
      ))}
    </S.List>
  );
};
