import styled from 'styled-components';
import { Colors } from 'styles/constants';

// tabs

export const Controller = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 8px;
  border-bottom: 1px solid ${Colors.Gray30};
`;

export const ControllerButton = styled.button`
  /** for modal tabs */
  .modal-tab {
    position: relative;
    color: ${Colors.Gray40};
  }

  &.active,
  &:hover {
    .modal-tab {
      color: ${Colors.Gray70};
      /** for the expanding line effect */
      &::after {
        width: 100%;
      }
    }
  }
`;
