import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Select } from 'components/Shared';
import type { SelectOption } from 'contracts/Common';
import type { DockOperationType } from 'contracts/Docks';
import { useValidation } from 'hooks';
import React, {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import {
  CreateDockOperationTypeActions as CreateActions,
  DeleteDockOperationTypeActions as DeleteActions,
} from 'store/ducks/dockOperationTypes';
import * as Yup from 'yup';
import * as S from './styles';

export interface Ref {
  resetForm: () => void;
}

interface Props {
  dockId?: number;
  dockOperationTypes: DockOperationType[];
  onUpdate?: () => void;
}

interface IOperationTypesManager
  extends ForwardRefExoticComponent<Props & RefAttributes<Ref>> {}

interface ItemProps {
  dockOperationType: DockOperationType;
}

const OperationTypesManager: IOperationTypesManager = forwardRef<Ref, Props>(
  (props, ref) => {
    const { dockId, dockOperationTypes, onUpdate } = props;
    const dispatch: AppDispatch = useDispatch();
    const formRef = useRef<FormHandles>(null);

    const { handleFormErrors, handleApiErrors } = useValidation();
    const [operationTypeOptions, setOperationTypeOptions] = React.useState<
      SelectOption[]
    >([]);

    // this component will not dispatch the listOperationTypes action
    // we lifted it to the top level to avoid dispatching multiple times
    const { data: operationTypesList, loading: loadingOperationTypesList } =
      useSelector((state: RootState) => state.listOperationTypes);

    const { loading: creating, validationErrors } = useSelector(
      (state: RootState) => state.createDockOperationType
    );

    const { id: deletingId } = useSelector(
      (state: RootState) => state.deleteDockOperationType
    );

    // we will also filter the list of operationTypes to only show the
    // ones that are not already bound to the dock
    const onCarrriersListLoad = useCallback((): void => {
      setOperationTypeOptions(
        operationTypesList
          .filter((operationType) => {
            return !dockOperationTypes.find(
              (dockOperationType) =>
                dockOperationType.operationType.id === operationType.id
            );
          })
          .map((operationType) => ({
            value: operationType.id,
            label: operationType.name,
          }))
      );
    }, [operationTypesList, dockOperationTypes]);

    const onDelete = useCallback(
      (id: number): void => {
        dispatch(DeleteActions.request(id, onUpdate));
      },
      [dispatch, onUpdate]
    );

    const onSuccess = useCallback((): void => {
      onUpdate && onUpdate();
      formRef.current?.reset();
    }, [onUpdate]);

    const onSubmit = useCallback(
      async (data: any): Promise<void> => {
        try {
          formRef.current?.setErrors({});

          const schema = Yup.object().shape({
            operationTypeId: Yup.number()
              .typeError('Selecione a transportadora')
              .required('Selecione a transportadora'),
          });

          const validData = await schema.validate(data, {
            abortEarly: false,
          });

          dispatch(CreateActions.request({ ...validData, dockId }, onSuccess));
        } catch (error) {
          handleFormErrors(error, formRef);
        }
      },
      [dispatch, dockId, handleFormErrors, onSuccess]
    );

    const Item = useCallback(
      ({ dockOperationType }: ItemProps): JSX.Element => {
        const { id, operationType } = dockOperationType;

        return (
          <S.ListItem>
            <S.Column>{operationType.name}</S.Column>
            <S.ActionsColumn>
              <S.ActionButton
                disabled={deletingId === id}
                onClick={() => onDelete(id)}
                mood="danger"
              >
                {deletingId === id ? <S.ActivityIndicator /> : <S.TrashIcon />}
              </S.ActionButton>
            </S.ActionsColumn>
          </S.ListItem>
        );
      },
      [deletingId, onDelete]
    );

    useEffect(() => {
      onCarrriersListLoad();
    }, [onCarrriersListLoad]);

    useEffect(() => {
      handleApiErrors(validationErrors, formRef);
    }, [handleApiErrors, validationErrors]);

    useImperativeHandle(
      ref,
      () => ({
        resetForm: () => {
          formRef.current?.reset();
          formRef.current?.setErrors({});
        },
      }),
      []
    );

    return (
      <S.Container>
        <Form ref={formRef} onSubmit={onSubmit}>
          <S.FormRow>
            <Select
              name="operationTypeId"
              label="Tipo de operação"
              options={operationTypeOptions}
              isLoading={loadingOperationTypesList}
              menuPortalTarget={document.body}
            />
            <S.Button type="submit">
              {creating ? <S.ActivityIndicator /> : 'Adicionar'}
            </S.Button>
          </S.FormRow>
        </Form>
        {dockOperationTypes.map((operationType) => (
          <Item dockOperationType={operationType} key={operationType.id} />
        ))}
      </S.Container>
    );
  }
);

export default OperationTypesManager;
