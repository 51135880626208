import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Scaffold } from 'layouts';
import { FormPageHeader } from 'components/Shared';
import { TypeCreationForm } from 'components/Pages/CargoTypes';
import * as S from './styles';

export const CargoTypeCreationPage: React.FC = () => {
  const navigate = useNavigate();

  const onCreate = useCallback((): void => {
    navigate('/configuracoes/tipos-cargas');
  }, [navigate]);

  return (
    <Scaffold>
      <S.PageContainer>
        <S.MainPanel>
          <FormPageHeader
            title="Novo tipo de carga"
            icon={<S.TruckIcon />}
            actions={
              <S.LinkButton size="small" to="/configuracoes/tipos-cargas">
                <S.ArrowLeftIcon /> Voltar
              </S.LinkButton>
            }
          />
          <TypeCreationForm onCreate={onCreate} />
        </S.MainPanel>
      </S.PageContainer>
    </Scaffold>
  );
};
