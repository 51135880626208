import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler } from 'services';
import {
  FetchCompanyWarehouseAccessActions as Actions,
  FetchCompanyWarehouseAccessRequestAction as RequestAction,
} from 'store/ducks/companyWarehouseAccesses';

export function* fetchCompanyWarehouseAccessRequest(action: any) {
  const { id, onSuccess, onFailure }: RequestAction = action;
  try {
    const { data } = yield call(api.get, `company-warehouse-accesses/${id}`);
    onSuccess && onSuccess();
    yield put(Actions.success(data));
  } catch (error) {
    const { errorMessage } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
