import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  DurationInput,
  NumberInput,
  Select,
  ToggleInput,
} from 'components/Shared';
import { FORM_BACK_ACTION } from 'constants/Common';
import { useCargoTypes, useValidation, useVehicleTypes } from 'hooks';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import {
  FetchVehicleSetupActions as FetchActions,
  UpdateVehicleSetupActions as UpdateActions,
} from 'store/ducks/vehicleSetups';
import { Formatter } from 'utils';
import { UpdateVehicleSetupValidator } from 'validators/VehicleSetups';
import * as S from './styles';

interface Props {
  setupId: string | number;
  onUpdate?: () => void;
}

export const SetupUpdateForm: React.FC<Props> = ({ setupId, onUpdate }) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors, handleApiErrors } = useValidation();

  const {
    vehicleTypeOptions,
    loadingVehicleTypes,
    fetchVehicleTypes,
    enableVehicleTypeIfBlocked,
  } = useVehicleTypes();
  const {
    cargoTypeOptions,
    loadingCargoTypes,
    fetchCargoTypes,
    enableCargpTypeIfBlocked,
  } = useCargoTypes();

  const { data: setup } = useSelector(
    (state: RootState) => state.fetchVehicleSetup
  );

  const { loading: updatingSetup, validationErrors } = useSelector(
    (state: RootState) => state.updateVehicleSetup
  );

  const fetchSetup = useCallback(() => {
    dispatch(FetchActions.request(setupId));
  }, [dispatch, setupId]);

  const onSetupLoad = useCallback((): void => {
    if (!setup) return;
    const {
      vehicleType,
      cargoType,
      weightCapacity,
      loadDuration,
      unloadDuration,
      blockedAt,
    } = setup;

    enableCargpTypeIfBlocked(cargoType.id);
    enableVehicleTypeIfBlocked(vehicleType.id);

    const vehicleTypeOption = vehicleTypeOptions.find(
      (option) => option.value === vehicleType.id
    );

    const cargoTypeOption = cargoTypeOptions.find(
      (option) => option.value === cargoType.id
    );

    formRef.current?.setData({
      ...(vehicleTypeOption && { vehicleTypeId: vehicleTypeOption }),
      ...(cargoTypeOption && { cargoTypeId: cargoTypeOption }),
      weightCapacity,
      loadDuration: Formatter.minutesToTime(loadDuration),
      unloadDuration: Formatter.minutesToTime(unloadDuration),
      blockedAt: blockedAt ? '1' : '0',
    });
  }, [
    cargoTypeOptions,
    vehicleTypeOptions,
    enableCargpTypeIfBlocked,
    enableVehicleTypeIfBlocked,
    setup,
  ]);

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
    onUpdate && onUpdate();
  }, [onUpdate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef?.current?.setErrors({});

        const { schema } = new UpdateVehicleSetupValidator();

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(UpdateActions.request(setupId, validData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess, setupId]
  );

  useEffect(() => {
    fetchCargoTypes({});
  }, [fetchCargoTypes]);

  useEffect(() => {
    fetchVehicleTypes({});
  }, [fetchVehicleTypes]);

  useEffect(() => {
    fetchSetup();
  }, [fetchSetup]);

  useEffect(() => {
    onSetupLoad();
  }, [onSetupLoad]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(FetchActions.reset());
      dispatch(UpdateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.Container>
      <Form ref={formRef} onSubmit={onSubmit}>
        <S.FormRow>
          <Select
            name="vehicleTypeId"
            label="Tipo de veículo"
            options={vehicleTypeOptions}
            isLoading={loadingVehicleTypes}
          />
          <Select
            name="cargoTypeId"
            label="Tipo de carga"
            options={cargoTypeOptions}
            isLoading={loadingCargoTypes}
          />
          <NumberInput
            name="weightCapacity"
            label="Capacidade de carga (Kg)"
            suffix=" Kg"
          />
        </S.FormRow>
        <S.FormRow>
          <DurationInput name="loadDuration" label="Tempo de carregamento" />
          <DurationInput
            name="unloadDuration"
            label="Tempo de descarregamento"
          />
          <ToggleInput name="blockedAt" label="Bloqueado" />
        </S.FormRow>
        <S.FormActions>
          <S.LinkButton mood="light" to="/configuracoes/setups-veiculos">
            {FORM_BACK_ACTION}
          </S.LinkButton>
          <S.Button type="submit">
            {updatingSetup ? <S.ActivityIndicator /> : 'Salvar'}
          </S.Button>
        </S.FormActions>
      </Form>
    </S.Container>
  );
};
