import styled, { css } from 'styled-components';
import { BasePaginatorHeader, BasePaginatorListItem } from 'styles/components';
export {
  ActivityIndicator,
  PaginatorColumn as Column,
  PaginatorActionsColumn as ActionsColumn,
} from 'styles/components';

export const PageContainer = styled.main`
  margin: 0 auto;
  max-width: 21cm;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
`;

export const PageHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.5cm;
  height: 2cm;
  border-bottom: 1px solid #000;
  margin-bottom: 0.5cm;
`;

export const CompanyLogo = styled.figure`
  text-align: center;
  width: 1.4cm;
  img {
    max-width: 100%;
  }
`;

export const PageHeaderTitle = styled.p`
  font-size: 14px;
  > span:nth-child(2) {
    font-size: 11px;
  }
`;

export const PageHeaderStatus = styled.p`
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-left: auto;
  > span:nth-child(2) {
    font-size: 11px;
  }
`;

// details grid

const BaseGrid = styled.div`
  display: grid;
  gap: 0.5cm 0.25cm;
  &:not(:last-child) {
    margin-bottom: 1cm;
  }
`;

export const MainGrid = styled(BaseGrid)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const InfoGrid = styled(BaseGrid)`
  grid-template-columns: 1fr;
`;

export const EventsGrid = styled(BaseGrid)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const WarehouseGrid = styled(BaseGrid)`
  > div:nth-child(1) {
    grid-area: warehouse;
  }
  > div:nth-child(2) {
    grid-area: dock;
  }
  > div:nth-child(3) {
    grid-area: address;
  }

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'warehouse dock address address';

  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'warehouse dock' 'address address';
  }
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DetailLabel = styled.label`
  font-weight: bold;
`;

export const DetailValue = styled.span``;

export const OrderItemsGrid = styled.div``;

export const Subtitle = styled.h4`
  font-size: 13px;
  margin-bottom: 0.5cm;
`;

const baseGridCss = css`
  grid-template-columns: 1fr 1fr 2fr;
  padding: 0 0;
  > div {
    padding: 0 0;
  }
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridCss}
  margin-bottom: 0.5cm;
`;

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridCss};
  &:not(:last-child) {
    margin-bottom: 0.25cm;
  }
`;
