import styled from 'styled-components';
import { BaseFiltersContainer } from 'styles/components';
export { FormRow } from 'styles/components';

export const Container = styled(BaseFiltersContainer)`
  form {
    > div:nth-child(1) {
      grid-area: warehouse;
      // fot the select to be above the acalendar content
      z-index: 2;
    }

    > div:nth-child(2) {
      grid-area: fromDate;
    }

    > div:nth-child(3) {
      grid-area: toDate;
    }

    > div:nth-child(4) {
      grid-area: status;
    }

    grid-template-columns: 360px 180px 180px auto;
    grid-template-areas: 'warehouse fromDate toDate status';

    @media (max-width: 2000px) {
      grid-template-columns: auto 200px 200px;
      grid-template-areas:
        'warehouse fromDate toDate'
        'status status status';
    }
  }
`;
