import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Scaffold } from 'layouts';
import { FormPageHeader } from 'components/Shared';
import { ReasonCreationForm } from 'components/Pages/CancelationReasons';
import * as S from './styles';

export const CancelationReasonCreationPage: React.FC = () => {
  const navigate = useNavigate();

  const onCreate = useCallback((): void => {
    navigate('/configuracoes/justificativas-cancelamento');
  }, [navigate]);

  return (
    <Scaffold>
      <S.PageContainer>
        <S.MainPanel>
          <FormPageHeader
            title="Nova justificativa"
            icon={<S.CancelationIcon />}
            actions={
              <S.LinkButton
                size="small"
                to="/configuracoes/justificativas-cancelamento"
              >
                <S.ArrowLeftIcon /> Voltar
              </S.LinkButton>
            }
          />
          <ReasonCreationForm onCreate={onCreate} />
        </S.MainPanel>
      </S.PageContainer>
    </Scaffold>
  );
};
