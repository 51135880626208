import { createSlice } from "@reduxjs/toolkit";

interface IRecall {
  data: boolean
}

const initialState: IRecall = {
  data: false,
};

export const recallSlice = createSlice({
  name: "recall",
  initialState,
  reducers: {
    setRecall: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setRecall } = recallSlice.actions;
export default recallSlice.reducer;
