import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Select } from 'components/Shared';
import type { SelectOption } from 'contracts/Common';
import {
  DeleteWarehouseOperationTypeActions as DeleteActions,
  CreateWarehouseOperationTypeActions as CreateActions,
} from 'store/ducks/warehouseOperationTypes';
import { useValidation } from 'hooks';
import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import * as Yup from 'yup';
import * as S from './styles';
import { WarehouseOperationType } from 'contracts/Warehouses';

export interface Ref {
  resetForm: () => void;
}

interface Props {
  onUpdate?: () => void;
  warehouseId: string;
}

interface IOperationTypesManager
  extends ForwardRefExoticComponent<Props & RefAttributes<Ref>> {}

interface ItemProps {
  operationType: WarehouseOperationType['operationType'];
  id: number;
}

const OperationTypesManager: IOperationTypesManager = forwardRef<Ref, Props>(
  ({ warehouseId, onUpdate }, ref) => {
    const { data: warehouse } = useSelector(
      (state: RootState) => state.fetchWarehouse
    );

    const dispatch: AppDispatch = useDispatch();
    const formRef = useRef<FormHandles>(null);

    const { handleFormErrors, handleApiErrors } = useValidation();

    /**
     * this component will not fetch OperationTypes here,
     * we lifted it to a upper level (DocksManager component)
     * to avoid dispatching multiple times
     * */
    const { loading: loadingOperationTypes, data: OperationTypes } =
      useSelector((state: RootState) => state.listOperationTypes);

    const { loading: creating, validationErrors } = useSelector(
      (state: RootState) => state.createWarehouseOperationType
    );

    const { id: deletingId } = useSelector(
      (state: RootState) => state.deleteWarehouseOperationType
    );

    /**
     * we will also filter the list of OperationTypes to only show
     * the ones that are not already bound to the dock
     * */

    const operationTypeOptions: SelectOption[] | undefined = useMemo(() => {
      if (!OperationTypes?.length) return undefined;

      return OperationTypes.filter((operationType) =>
        warehouse?.warehouseOperationTypes
          ? !warehouse.warehouseOperationTypes.find(
              (warehouseOperationType) =>
                warehouseOperationType.operationType.id === operationType.id
            )
          : true
      ).map((operationType) => ({
        value: operationType.id,
        label: operationType.name,
      }));
    }, [OperationTypes, warehouse]);

    const onDelete = useCallback(
      (id?: number) => dispatch(DeleteActions.request(id, onUpdate)),
      [dispatch, onUpdate]
    );

    const onSuccess = useCallback((): void => {
      onUpdate && onUpdate();
      formRef.current?.reset();
    }, [onUpdate]);

    const onSubmit = useCallback(
      async (data: any): Promise<void> => {
        try {
          formRef.current?.setErrors({});
          const schema = Yup.object().shape({
            operationTypeId: Yup.number()
              .typeError('Selecione o tipo de operação')
              .required('Selecione o tipo de operação'),
          });
          const validData = await schema.validate(data, {
            abortEarly: false,
          });
          dispatch(
            CreateActions.request({ ...validData, warehouseId }, onSuccess)
          );
        } catch (error) {
          handleFormErrors(error, formRef);
        }
      },
      [dispatch, handleFormErrors, onSuccess, warehouseId]
    );

    const Item = useCallback(
      ({ operationType, id }: ItemProps): JSX.Element => {
        const { id: operationTypeId, name } = operationType;

        return (
          <S.ListItem>
            <S.Column>{operationTypeId}</S.Column>
            <S.Column>{name}</S.Column>
            <S.ActionsColumn>
              <S.ActionButton
                disabled={deletingId === operationTypeId}
                onClick={() => onDelete(id)}
                mood="danger"
              >
                {deletingId === operationTypeId ? (
                  <S.ActivityIndicator />
                ) : (
                  <S.TrashIcon />
                )}
              </S.ActionButton>
            </S.ActionsColumn>
          </S.ListItem>
        );
      },
      [deletingId, onDelete]
    );

    useImperativeHandle(
      ref,
      () => ({
        resetForm: () => {
          formRef.current?.reset();
          formRef.current?.setErrors({});
        },
      }),
      []
    );

    useEffect(() => {
      handleApiErrors(validationErrors, formRef);
    }, [handleApiErrors, validationErrors]);

    return (
      <S.Container>
        <Form ref={formRef} onSubmit={onSubmit}>
          <S.FormRow>
            <Select
              name="operationTypeId"
              label="Tipo de Operação"
              options={operationTypeOptions}
              isLoading={loadingOperationTypes}
              menuPortalTarget={document.body}
              menuPlacement="top"
            />
            <S.Button type="submit">
              {creating ? <S.ActivityIndicator /> : 'Adicionar'}
            </S.Button>
          </S.FormRow>
        </Form>
        {!warehouse?.warehouseOperationTypes?.length ? (
          <S.EmptyListPlaceholder>
            Esse armazém ainda não possui restrições para tipos de operação
          </S.EmptyListPlaceholder>
        ) : (
          <>
            <S.ListHeader>
              <div>ID</div>
              <div>NOME</div>
            </S.ListHeader>
            {warehouse?.warehouseOperationTypes?.map((props) => (
              <Item {...props} key={props.id} />
            ))}
          </>
        )}
      </S.Container>
    );
  }
);

export default OperationTypesManager;
