import styled from 'styled-components';
import { Colors, ColorScheme } from 'styles/constants';
import { FieldContainer, FieldLabel } from 'styles/components';
export { FieldError, FieldLabel } from 'styles/components';

export const Container = styled(FieldContainer)``;

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: inherit;
  border-radius: 6px;
  background: ${Colors.Gray10};
  border: 1px solid ${Colors.Gray30};
  color: ${ColorScheme.Text};
  height: 48px;
  padding: 0 16px;
  gap: 0 8px;
`;

export const Input = styled.input`
  appearance: none;
  &:checked + label > div .thumb {
    right: 0px;
  }
  &:not(:checked) + label > div .thumb {
    right: calc(100% - 24px);
    background: ${Colors.Gray50};
    opacity: 0.5;
  }
`;

export const Label = styled(FieldLabel)`
  margin-bottom: 0px !important;
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  align-items: center;
  ::selection {
    background: transparent;
  }
`;

export const Toggler = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 44px;
  width: 44px;
`;

export const TogglerTrack = styled.div.attrs({ className: 'track' })`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 24px;
  background: ${Colors.Gray30};
  border-radius: 12px;
`;

export const TogglerThumb = styled.div.attrs({ className: 'thumb' })`
  position: absolute;
  display: flex;
  flex: 0 0 24px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: ${ColorScheme.Primary};
  border: 2px solid ${Colors.Gray30};
  transition: all 0.3s ease-in-out;
`;
