import styled from 'styled-components';
import { Colors, ColorScheme, Fonts } from 'styles/constants';
import { BasePanel, BaseButtonStyle, LogOutCircle } from 'styles/components';
export { AdminIcon, CompanyIcon, WarehouseIcon } from 'styles/components';

export const TopPanel = styled(BasePanel)`
  flex-direction: row;
  width: 100%;
  padding: 8px 32px;
  font-size: 14px;
  font-family: ${Fonts.GilroySemiBold};
  margin-bottom: 32px;
  @media (max-width: 1800px) {
    margin-bottom: 24px;
  }
`;

export const Domain = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  gap: 0 16px;
`;

export const User = styled.span``;

export const LogoutButton = styled.button`
  ${BaseButtonStyle};
  background: ${Colors.Peach};
  color: ${ColorScheme.Primary};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 10px;
`;

export const LogoutIcon = styled(LogOutCircle).attrs({
  size: 20,
})``;
