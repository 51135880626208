import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from 'contracts/Router';
import {
  OperationTypesListPage,
  OperationTypeUpdatePage,
} from 'pages/OperationTypes';

const operationTypesRoutes = ({
  location,
  userBelongsToAnyOf,
}: RouterProps): RouteObject => ({
  path: 'configuracoes/tipos-operacoes',
  element: userBelongsToAnyOf('admin') ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: '',
      element: <OperationTypesListPage />,
    },
    {
      path: ':id/editar',
      element: <OperationTypeUpdatePage />,
    },
  ],
});

export default operationTypesRoutes;
