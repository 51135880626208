import { ApiValidationError } from 'contracts/Common';
import type {
  FindWarehouseSchedule,
  WarehouseScheduleEntry,
} from 'contracts/Scheduling';
import update from 'immutability-helper';
import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions(
  {
    request: ['query', 'onSuccess', 'onFailure'],
    success: ['data'],
    failure: ['errorMessage'],
    softReset: [],
    reset: [],
  },
  {
    prefix: 'LIST_WAREHOUSE_SCHEDULE_ENTRIES_',
  }
);

export interface ListWarehouseScheduleEntriesState {
  data: WarehouseScheduleEntry[];
  loading: boolean;
  errorMessage: string | null;
  validationErrors: ApiValidationError[];
}

export interface ListWarehouseScheduleRequestAction {
  query: FindWarehouseSchedule;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface SuccessAction {
  data: WarehouseScheduleEntry[];
}

interface FailureAction {
  errorMessage: string;
  validationErrors?: ApiValidationError[];
}

const INITIAL_STATE: ListWarehouseScheduleEntriesState = {
  data: [],
  loading: false,
  errorMessage: null,
  validationErrors: [],
};

const request = (state: ListWarehouseScheduleEntriesState) =>
  update(state, {
    loading: { $set: true },
    errorMessage: { $set: null },
    validationErrors: { $set: [] },
  });

const success = (
  state: ListWarehouseScheduleEntriesState,
  action: SuccessAction
) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

const failure = (
  state: ListWarehouseScheduleEntriesState,
  action: FailureAction
) =>
  update(state, {
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
    validationErrors: { $set: action.validationErrors || [] },
  });

const softReset = (state: ListWarehouseScheduleEntriesState) =>
  update(state, {
    loading: { $set: false },
  });

const reset = () => INITIAL_STATE;

export const listWarehouseScheduleEntries = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.SOFT_RESET]: softReset,
  [Types.RESET]: reset,
});

export const ListWarehouseScheduleEntriesTypes = Types;
export const ListWarehouseScheduleEntriesActions = Creators;
