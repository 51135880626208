import styled, { css } from 'styled-components';
import { BasePanel } from 'styles/components';
import { Colors, ColorScheme } from 'styles/constants';
import { BasePaginatorHeader, BasePaginatorListItem } from 'styles/components';
export {
  FormRow,
  Button,
  ActionButton,
  ActivityIndicator,
  FormActions,
  PackageIcon,
  WeightIcon,
  TimerIcon,
  TrashIcon,
  PaginatorColumn as Column,
  PaginatorActionsColumn as ActionsColumn,
} from 'styles/components';

export const Panel = styled(BasePanel)`
  flex: 0 1 100%;
  flex-direction: column;
  background: ${Colors.White};
  padding: 24px;
`;

export const WeightAndDuration = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 24px;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0 8px;
    color: ${ColorScheme.LightText};
    font-size: 14px;
  }
`;
export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const baseGridCss = css`
  grid-template-columns: 240px 200px auto minmax(0px, max-content);
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridCss}
`;

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridCss}
`;
