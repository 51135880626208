import { SortableHeader, SortableHeadersGroup } from 'components/Shared';
import type { PaginatedCargoType } from 'contracts/CargoTypes';
import { SortingParams } from 'contracts/Common';
import React, { useCallback } from 'react';
import * as S from './styles';

interface Props {
  cargoTypes: PaginatedCargoType[];
  onSort?: (sort: SortingParams) => void;
  currentSort: SortingParams;
}

interface ItemProps {
  cargoType: PaginatedCargoType;
}

export const TypesList: React.FC<Props> = ({
  cargoTypes,
  onSort,
  currentSort,
}) => {
  const Item = useCallback(({ cargoType }: ItemProps): JSX.Element => {
    const { id, name, blockedAt } = cargoType;

    return (
      <S.ListItem>
        <S.Column>{id}</S.Column>
        <S.Column>{name}</S.Column>
        <S.Column>{blockedAt && <S.LockIcon />}</S.Column>
        <S.ActionsColumn>
          <S.LinkActionButton to={`/configuracoes/tipos-cargas/${id}/editar`}>
            <S.EditIcon />
          </S.LinkActionButton>
        </S.ActionsColumn>
      </S.ListItem>
    );
  }, []);

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={currentSort}>
          <SortableHeader column="id" label="ID" />
          <SortableHeader column="name" label="NOME" />
          <div></div>
        </SortableHeadersGroup>
      </S.ListHeader>
      {cargoTypes.map((setup) => (
        <Item key={setup.id} cargoType={setup} />
      ))}
    </S.List>
  );
};
