import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler } from 'services';
import {
  DeleteWarehouseVehicleSetupActions as Actions,
  DeleteWarehouseVehicleSetupRequestAction as RequestAction,
} from 'store/ducks/warehouseVehicleSetups';

export function* deleteWarehouseVehicleSetupRequest(action: any) {
  const { id, onSuccess, onFailure }: RequestAction = action;
  try {
    yield call(api.delete, `warehouse-vehicle-setups/${id}`);
    onSuccess && onSuccess();
    notify('success', 'Setup de veículo desvinculado com sucesso');
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
