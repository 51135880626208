import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { PaginateWarehouseVehicleSetupsActions as PaginateActions } from 'store/ducks/warehouseVehicleSetups';
import { Paginator, FormPageHeader } from 'components/Shared';
import SetupsList from './SetupsList';
import SetupCreationModal, {
  Ref as SetupCreationModalRef,
} from './CreationModal';
import SetupUpdateModal, { Ref as UpdateModalRef } from './UpdateModal';
import * as S from './styles';

interface Props {
  warehouseId: string | number;
}

export const WarehouseVehicleSetupsManager: React.FC<Props> = ({
  warehouseId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const setupCreationModalRef = useRef<SetupCreationModalRef>(null);
  const setupUpdateModalRef = useRef<UpdateModalRef>(null);

  const {
    data: setups,
    pagination,
    loading: loadingSetups,
  } = useSelector((state: RootState) => state.paginateWarehouseVehicleSetups);

  const [query, setQuery] = useState({
    search: '',
    page: 1,
    limit: 99,
    warehouseId,
    orderBy: 'id',
    direction: 'asc',
  });

  const onPageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const onQueryChange = useCallback((): void => {
    dispatch(PaginateActions.request(query));
  }, [dispatch, query]);

  useEffect(() => {
    onQueryChange();
  }, [onQueryChange]);

  useEffect(() => {
    return () => {
      dispatch(PaginateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.MainPanel>
      <FormPageHeader
        title="Setups de veículos"
        icon={<S.TruckIcon />}
        isLoading={loadingSetups}
        actions={
          <S.Button
            size="small"
            onClick={setupCreationModalRef?.current?.openModal}
          >
            <S.PlusIcon /> Novo setup de veículo
          </S.Button>
        }
      />
      <SetupCreationModal
        ref={setupCreationModalRef}
        warehouseId={warehouseId}
        onCreate={onQueryChange}
      />
      <SetupUpdateModal ref={setupUpdateModalRef} onUpdate={onQueryChange} />
      <SetupsList
        setups={setups}
        onSelectSetup={setupUpdateModalRef.current?.selectSetup}
        onDelete={onQueryChange}
      />
      <Paginator onPageChange={onPageChange} pagination={pagination} />
    </S.MainPanel>
  );
};
