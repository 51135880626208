import {
  FindIntervals,
  IntervalFilters,
  IntervalsList,
} from 'components/Pages/DockReservedIntervals';
import { ListingPageHeader, Paginator, SettingsMenu } from 'components/Shared';
import { useAuth, usePaginationCache } from 'hooks';
import { Scaffold } from 'layouts';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { PaginateDockReservedIntervalsActions as PaginateActions } from 'store/ducks/dockReservedIntervals';
import * as S from './styles';

export const IntervalsListPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { userBelongsToAnyOf } = useAuth();

  const { paginationCache, updatePaginationCache, handleFilter, handleSort } =
    usePaginationCache<FindIntervals>('dockReservedIntervals');

  const {
    data: intervals,
    pagination,
    loading,
  } = useSelector((state: RootState) => state.paginateDockReservedIntervals);

  const [query, setQuery] = useState<FindIntervals>({
    page: 1,
    limit: 10,
    ...paginationCache,
  });

  const onPageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const onQueryChange = useCallback((): void => {
    dispatch(PaginateActions.request(query));
  }, [dispatch, query]);

  useEffect(() => {
    onQueryChange();
  }, [onQueryChange]);

  useEffect(() => {
    return () => {
      dispatch(PaginateActions.reset());
      updatePaginationCache(query);
    };
  }, [dispatch, query, updatePaginationCache]);

  return (
    <Scaffold>
      {userBelongsToAnyOf('admin') && <SettingsMenu />}
      <S.MainPanel>
        <ListingPageHeader
          icon={<S.ReservedIntervalIcon />}
          title="Bloqueio de horários"
          isLoading={loading}
          actions={
            <S.LinkButton
              to={'/configuracoes/bloqueio-de-horarios/criar'}
              size="small"
            >
              <S.PlusIcon /> Novo horário
            </S.LinkButton>
          }
        />
        <IntervalFilters
          currentFilter={query}
          onFilter={(filter) => handleFilter(query, filter, setQuery)}
        />
        <IntervalsList
          intervals={intervals}
          currentSort={query}
          onSort={(sort) => handleSort(query, sort, setQuery)}
        />
        <Paginator onPageChange={onPageChange} pagination={pagination} />
      </S.MainPanel>
    </Scaffold>
  );
};
