import { FindWarehouseSchedule } from 'contracts/Scheduling';
import update from 'immutability-helper';
import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions(
  {
    updateCache: ['params'],
    reset: [],
  },
  { prefix: 'WAREHOUSE_SCHEDULE_PARAMS_CACHE_' }
);

export interface WarehouseScheduleParamsCacheState {
  data: Partial<FindWarehouseSchedule>;
}

interface UpdateCacheAction {
  params: Partial<FindWarehouseSchedule>;
}

const INITIAL_STATE: WarehouseScheduleParamsCacheState = {
  data: {},
};

const updateCache = (
  state: WarehouseScheduleParamsCacheState,
  action: UpdateCacheAction
) =>
  update(state, {
    data: {
      $apply: (currentData: Partial<FindWarehouseSchedule>) => {
        const { params } = action;
        delete params?.dirty;
        return { ...currentData, ...params };
      },
    },
  });

const reset = (state: WarehouseScheduleParamsCacheState) =>
  update(state, {
    data: { $set: {} },
  });

export const warehouseScheduleParamsCache = createReducer(INITIAL_STATE, {
  [Types.UPDATE_CACHE]: updateCache,
  [Types.RESET]: reset,
});

export const WarehouseScheduleParamsCacheActions = Creators;
export const WarehouseScheduleParamsCacheTypes = Types;
