import React, { useRef, useEffect, useState, InputHTMLAttributes } from "react";
import { useField } from "@unform/core";

import * as S from "./styles";

interface FileInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  className?: "field-container";
  label?: string;
  buttonLabel?: string;
  labelStyle?: Record<string, any>;
  inputStyle?: Record<string, any>;
  containerStyle?: Record<string, any>;
  required?: boolean;
  onlyIcon?: boolean;
  auxText?: string;
}

type Props = FileInputProps;

export const FileInput: React.FC<Props> = ({
  name,
  label,
  buttonLabel = "Selecione",
  className = "field-container",
  labelStyle = {},
  inputStyle = {},
  containerStyle = {},
  required = false,
  onlyIcon = false,
  auxText,
  ...rest
}) => {
  const [filename, setFilename] = useState<string>(
    "Nenhum arquivo selecionado"
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget.files) {
      const file = event.currentTarget.files[0];
      setFilename(file.name);
      if (rest.onChange) {
        rest.onChange(event);
      }
    }
  }

  function handleInputTrigger() {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "files[0]",
      setValue: function (ref: HTMLInputElement, value: any) {
        ref.value = value;
        if (!value) {
          setFilename("Nenhum arquivo selecionado");
        }
      },
      clearValue: (ref: HTMLInputElement) => {
        ref.value = "";
      },
    });
  }, [fieldName, registerField, setFilename]);

  return (
    <S.Container style={containerStyle} className={className}>
      {label && (
        <S.FieldLabel htmlFor={fieldName} style={labelStyle}>
          {label}
          {required && <span>*</span>}
        </S.FieldLabel>
      )}
      {onlyIcon ? (
        <S.ButtonIcon type="button" onClick={handleInputTrigger}>
          {auxText && (
            <S.AuxText>{auxText}</S.AuxText>
          )}
          <S.UploadIcon />
        </S.ButtonIcon>
      ) : (
        <S.FakeInput style={inputStyle}>
          <S.FileName>{filename}</S.FileName>
          <S.Button type="button" onClick={handleInputTrigger}>{buttonLabel}</S.Button>
        </S.FakeInput>
      )}

      <input
        type="file"
        className="hidden"
        ref={inputRef}
        name={fieldName}
        id={fieldName}
        defaultValue={defaultValue}
        onChange={handleFileChange}
        {...rest}
      />
      {error && error !== 'array validation failed' && <S.FieldError>{error}</S.FieldError>}
    </S.Container>
  );
};