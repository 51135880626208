import React, { useCallback, useEffect, useRef } from 'react';
import * as S from './styles';
import * as Tabs from 'components/Shared/Tabs';
import { FormPageHeader } from 'components/Shared';
import { useWarehouses } from 'hooks';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

import WarehousesManager, {
  Ref as WarehousesManagerRef,
} from './WarehousesManager';

interface Props {
  onUpdate: () => void;
  companyId: string;
}

export const RulesManager: React.FC<Props> = (props) => {
  const warehousesManagerRef = useRef<WarehousesManagerRef>(null);
  const tabsOutletRef = useRef<Tabs.Ref>(null);

  const { loading } = useSelector((state: RootState) => state.fetchWarehouse);

  const { fetchWarehouses } = useWarehouses();

  // We will dispatch this action here on top level
  // to avoid dispatching multiple times down the tree

  const fetchLists = useCallback((): void => {
    fetchWarehouses();
  }, [fetchWarehouses]);

  useEffect(() => {
    fetchLists();
  }, [fetchLists]);

  return (
    <S.MainPanel>
      <FormPageHeader
        title="Armazéns associados"
        icon={<S.BookContentIcon />}
        isLoading={loading}
        removeSpacing
        showBorder={false}
      />
      <S.TabedContainer>
        <Tabs.Controller
          onTabChange={(i) => tabsOutletRef?.current?.selectTab(i)}
        >
          <Tabs.ModalTab title="Armazéns" />
          <></>
        </Tabs.Controller>
        <Tabs.Outlet ref={tabsOutletRef}>
          <WarehousesManager ref={warehousesManagerRef} {...props} />
          <></>
        </Tabs.Outlet>
      </S.TabedContainer>
    </S.MainPanel>
  );
};
