import * as Yup from 'yup';
import { getUserShape } from 'validators/Common/baseUserValidator';

export class UpdateMemberValidator {
  public get schema() {
    return Yup.object().shape({
      userId: Yup.number(),
      companyId: Yup.number()
        .typeError('Selecione o cliente')
        .required('Selecione o cliente'),
      allowToday: Yup.boolean().nullable(),
      user: Yup.object().shape({
        ...getUserShape(),
        password: Yup.string().optional(),
      }),
    });
  }
}
