import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler } from 'services';
import {
  DeleteWarehouseCompanyActions as Actions,
  DeleteWarehouseCompanyRequestAction as RequestAction,
} from 'store/ducks/warehouseCompanies';

export function* deleteWarehouseCompanyRequest(action: any) {
  const { id, onSuccess, onFailure }: RequestAction = action;
  try {
    yield call(api.delete, `warehouse-companies/${id}`);
    onSuccess && onSuccess();
    notify('success', 'Regra desvinculada com sucesso');
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
