import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  ForwardRefExoticComponent,
  RefAttributes,
  ReactNode,
} from 'react';
import * as S from './styles';

export interface Ref {
  selectTab: (index: number) => void;
}

interface Props {
  children: Array<ReactNode>;
}

interface IOutlet
  extends ForwardRefExoticComponent<Props & RefAttributes<Ref>> {}

export const Outlet: IOutlet = forwardRef<Ref, Props>((props, ref) => {
  const { children } = props;

  const [activeTab, setActiveTab] = useState<number>(0);
  const BASE_CLASS = 'tab-content';

  useImperativeHandle(
    ref,
    () => ({
      selectTab: setActiveTab,
    }),
    []
  );

  return (
    <S.TabsContent>
      {children.map((child, index) => (
        <S.TabContent
          key={index}
          className={
            index === activeTab
              ? `${BASE_CLASS} ${BASE_CLASS}--active`
              : BASE_CLASS
          }
        >
          {child}
        </S.TabContent>
      ))}
    </S.TabsContent>
  );
});
