import { ConfirmationDialog, ConfirmationDialogRef } from 'components/Shared';
import { Modal, ModalCloseButton } from 'components/Shared/Modal';
import * as Tabs from 'components/Shared/Tabs';
import { EMPTY_COLUMN_VALUE } from 'constants/Common';
import { useAuth } from 'hooks';
import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import {
  FetchOrderActions as FetchActions,
  UpdateOrderNoShowActions as NoShowActions,
} from 'store/ducks/orders';
import { Formatter } from 'utils';
import CancelationModal, {
  Ref as CancelationModalRef,
} from './CancelationModal';
import EventsManager from './EventsManager';
import OrderItems from './OrderItems';
import * as S from './styles';
import UpdateDriverAndVehicleModal, {
  Ref as UpdateDriverAndVehicleModalRef,
} from './UpdateDriverAndVehicleModal';
import OrderFiles from './OrderFiles';
import { api } from 'services/api';

export interface Ref {
  selectOrder: (orderId: number) => void;
}

interface Props {
  onUpdate?: () => void;
}

interface Detail {
  label: string;
  value: string | number;
}
interface IOrderModal
  extends ForwardRefExoticComponent<Props & RefAttributes<Ref>> {}

/**
 * Only admins and warehouse members can set events, No show and cancel orders
 *
 * Only admins, carrier members and warehouse members can update
 * driverName, driverDocument and vehiclePlate if the vehicle did not arrive.
 *
 * Any other users will only see the order details.
 */

const OrderModal: IOrderModal = forwardRef<Ref, Props>((props, ref) => {
  const { onUpdate } = props;
  const dispatch: AppDispatch = useDispatch();
  const { userBelongsToAnyOf } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<number | null>(null);
  const tabsOutletRef = useRef<Tabs.Ref>(null);
  const dialogRef = useRef<ConfirmationDialogRef>(null);
  const cancelationModalRef = useRef<CancelationModalRef>(null);
  const updateDriverAndVehicleModalRef =
    useRef<UpdateDriverAndVehicleModalRef>(null);

  const { data: order, loading: loadingOrder } = useSelector(
    (state: RootState) => state.fetchOrder
  );

  const { loading: updatingNoShow } = useSelector(
    (state: RootState) => state.updateOrderNoShow
  );

  const isNoShowLocked = useMemo((): boolean => {
    if (!order) return true;

    const { vehicleArrivedAt, noShowAt, cancelationReason } = order;

    return !!vehicleArrivedAt || !!noShowAt || !!cancelationReason;
  }, [order]);

  const isCancelationLocked = useMemo((): boolean => {
    if (!order) return true;

    const { status, startedAt, noShowAt, cancelationReason } = order;

    if (
      status === 'iniciado' &&
      userBelongsToAnyOf('admin', 'warehouseMember')
    ) {
      return false;
    }

    return !!startedAt || !!noShowAt || !!cancelationReason;
  }, [order, userBelongsToAnyOf]);

  const isEditLocked = useMemo((): boolean => {
    if (!order) return true;
    if (order?.vehicleArrivedAt) return true;
    return false;
  }, [order]);

  const fetchOrder = useCallback((): void => {
    if (!orderId) return;
    dispatch(FetchActions.request(orderId));
  }, [dispatch, orderId]);

  const onCloseModal = useCallback((): void => {
    setOrderId(null);
    setIsOpen(false);
    dispatch(FetchActions.reset());
  }, [dispatch]);

  const onEventsUpdate = useCallback((): void => {
    onUpdate && onUpdate();
    fetchOrder();
  }, [fetchOrder, onUpdate]);

  const onNoShowUpdate = useCallback((): void => {
    onUpdate && onUpdate();
    fetchOrder();
  }, [fetchOrder, onUpdate]);

  const onCancelationReasonUpdate = useCallback((): void => {
    onUpdate && onUpdate();
    fetchOrder();
  }, [fetchOrder, onUpdate]);

  const onDriverAndVehicleUpdate = useCallback((): void => {
    onUpdate && onUpdate();
    fetchOrder();
  }, [fetchOrder, onUpdate]);

  const onNoShow = useCallback(async (): Promise<void> => {
    if (order?.vehicleArrivedAt) return;

    const confirmed = await dialogRef.current?.openDialog({
      title: 'Confirmar no show do veículo?',
      message: 'Esta ação não poderá ser desfeita.',
    });

    if (confirmed) {
      const data = {
        noShowAt: new Date().toISOString(),
      };
      dispatch(NoShowActions.request(orderId, data, onNoShowUpdate));
    }
  }, [dispatch, onNoShowUpdate, order?.vehicleArrivedAt, orderId]);

  const ModalHeader = useCallback((): JSX.Element => {
    const orderNumber = orderId ? `${orderId}`.padStart(4, '0') : '';

    const type =
      order?.orderType.name === 'Carregamento'
        ? 'coleta'
        : order?.orderType.name === 'Descarregamento'
        ? 'entrega'
        : '...';

    return (
      <S.ModalHeader>
        <S.HeaderTitle>
          <S.PackageIcon />
          <h4>
            Ordem de {type} {orderNumber}
          </h4>
          {loadingOrder && <S.ActivityIndicator />}
        </S.HeaderTitle>

        <S.HeaderCenter>
          {!!order?.status && (
            <S.StatusLabel status={order.status}>
              {Formatter.orderStatusName(order.status)}
            </S.StatusLabel>
          )}
          {order?.orderReader && (
            <S.Reader>
              Lido por {order?.orderReader.user.name} <br/> em {Formatter.date(order?.orderReader.createdAt, {
                format: 'dd/MM/yyyy HH:mm',
              })}
            </S.Reader>
          )}
        </S.HeaderCenter>


        <S.HeaderButtons>
          {/* if order.status is ready, the entire order is also, so show the button */}
          {!!order?.status && (
            <S.PrintButton
              mood="void"
              size="small"
              target="_blank"
              to={`/agendamento/${orderId}/impressao`}
            >
              <S.PrinterIcon />
            </S.PrintButton>
          )}
        </S.HeaderButtons>
        <ModalCloseButton onClick={onCloseModal} />
      </S.ModalHeader>
    );
  }, [orderId, loadingOrder, order?.status, onCloseModal]);

  const insertReader = useCallback(async (): Promise<void> => {
    if (!order) return;
    try {
      await api.post(`/orders/${orderId}/reader`);
    } catch (error) {
      console.log(error);
    }
  }, [order, orderId]);

  const MainGrid = useCallback((): JSX.Element => {
    if (!order) return <></>;

    const {
      scheduledAt,
      orderItems,
      totalWeight,
      carrier,
      company,
      orderType,
      operationType,
      vehicleType,
      driverName,
      driverDocument,
      vehiclePlate,
      cargoType,
      devolutionNumber,
      exportationSecuritySeal,
      exportationContainer,
      importationOrder,
      importationSequential,
      importationContainer,
      importationDeclaration,
      orderReader,
    } = order;

    if(!orderReader && userBelongsToAnyOf('warehouseMember')) {
      insertReader();
    }

    const getNullableDetail = (
      label: string,
      value: string | number | null
    ): Detail[] => {
      if (value === null) return [];
      return [{ label, value }];
    };

    const details: Detail[] = [
      {
        label: 'Data do agendamento',
        value: Formatter.date(scheduledAt, {
          format: 'dd/MM/yyyy HH:mm',
        }),
      },
      {
        label: 'Cliente',
        value: company.tradeName,
      },
      {
        label: 'Número celular',
        value: Formatter.cellPhone(order.cellPhone),
      },
      {
        label: 'Qtd documentos',
        value: orderItems.length,
      },
      {
        label: 'Peso',
        value: Formatter.weight(totalWeight),
      },
      {
        label: 'Transportadora',
        value: carrier.tradeName,
      },
      {
        label: 'Tipo de ordem',
        value: orderType.name,
      },

      {
        label: 'Motorista',
        value: driverName,
      },
      {
        label: 'CPF do motorista',
        value: Formatter.document(driverDocument),
      },
      {
        label: 'Tipo de veículo',
        value: vehicleType.name,
      },
      {
        label: 'Placa do veículo',
        value: vehiclePlate,
      },
      {
        label: 'Tipo de carga',
        value: cargoType.name,
      },
      {
        label: 'Tipo de operação',
        value: operationType.name,
      },
      ...getNullableDetail('Nº ordem devolução', devolutionNumber),
      ...getNullableDetail('Container', exportationContainer),
      ...getNullableDetail('Lacre', exportationSecuritySeal),
      ...getNullableDetail('Declaração', importationDeclaration),
      ...getNullableDetail('Pedido', importationOrder),
      ...getNullableDetail('Sequencial', importationSequential),
      ...getNullableDetail('Container', importationContainer),
    ];

    return (
      <S.MainGrid>
        {details.map(({ label, value }, i) => (
          <S.Detail key={i}>
            <S.DetailLabel>{label}</S.DetailLabel>
            <S.DetailValue>{value}</S.DetailValue>
          </S.Detail>
        ))}
      </S.MainGrid>
    );
  }, [order]);

  const InfoGrid = useCallback((): JSX.Element => {
    if (!order) return <></>;
    const { observation, material } = order;

    return (
      <S.InfoGrid>
        <S.Detail>
          <S.DetailLabel>Observações</S.DetailLabel>
          <S.DetailValue>{observation || EMPTY_COLUMN_VALUE}</S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Materiais</S.DetailLabel>
          <S.DetailValue>{material || EMPTY_COLUMN_VALUE}</S.DetailValue>
        </S.Detail>
      </S.InfoGrid>
    );
  }, [order]);

  const WarehouseGrid = useCallback((): JSX.Element => {
    if (!order) return <></>;

    const { warehouse, dock } = order;

    return (
      <S.WarehouseGrid>
        <S.Detail>
          <S.DetailLabel>Armazém</S.DetailLabel>
          <S.DetailValue>{warehouse.tradeName}</S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Doca</S.DetailLabel>
          <S.DetailValue>{dock.name}</S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Endereço do armazém</S.DetailLabel>
          <S.DetailValue>{Formatter.address(warehouse)}</S.DetailValue>
        </S.Detail>
      </S.WarehouseGrid>
    );
  }, [order]);

  const CancelationReasonAlert = useCallback((): JSX.Element => {
    if (!order?.cancelationReason) return <></>;
    return (
      <S.Alert>
        <S.AlertTitle>Justificativa do cancelamento:</S.AlertTitle>
        <S.AlertMessage>{order.cancelationReason.name}</S.AlertMessage>
      </S.Alert>
    );
  }, [order?.cancelationReason]);

  useImperativeHandle(
    ref,
    () => ({
      selectOrder: (orderId: number): void => {
        setOrderId(orderId);
        setIsOpen(true);
      },
    }),
    []
  );

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  return (
    <Modal isOpen={isOpen} onClickOutside={onCloseModal}>
      <ConfirmationDialog ref={dialogRef} />
      {!!orderId && (
        <CancelationModal
          orderId={orderId}
          ref={cancelationModalRef}
          onUpdate={onCancelationReasonUpdate}
        />
      )}

      {!!order && (
        <UpdateDriverAndVehicleModal
          order={order}
          ref={updateDriverAndVehicleModalRef}
          onUpdate={onDriverAndVehicleUpdate}
        />
      )}

      <S.ModalContent style={{ maxWidth: '960px' }}>
        <ModalHeader />
        <S.ModalBody>
          <MainGrid />
          <InfoGrid />
          <WarehouseGrid />
          <CancelationReasonAlert />
          {!!order && (
            <S.TabsContainer>
              <Tabs.Controller
                onTabChange={(i) => tabsOutletRef.current?.selectTab(i)}
              >
                <Tabs.ModalTab title="Apontamento" />
                <Tabs.ModalTab title="Itens do agendamento" />
                {order.orderFiles && order.orderFiles.length > 0 && (
                  <Tabs.ModalTab title="Documentos" />
                )}
              </Tabs.Controller>
              <Tabs.Outlet ref={tabsOutletRef}>
                <EventsManager onUpdate={onEventsUpdate} order={order} />
                <OrderItems items={order.orderItems} />
                {order.orderFiles && order.orderFiles.length > 0 && (
                  <OrderFiles files={order.orderFiles} />
                )}
              </Tabs.Outlet>
            </S.TabsContainer>
          )}

          {userBelongsToAnyOf('admin', 'warehouseMember', 'carrierMember', 'companyMember') && (
            <S.FormActions>
              <S.Button
                mood="primary"
                disabled={isEditLocked}
                onClick={() =>
                  updateDriverAndVehicleModalRef.current?.openModal()
                }
              >
                Editar
              </S.Button>

              {userBelongsToAnyOf('admin', 'warehouseMember') && (
                <>
                  <S.Button
                    mood="warning"
                    disabled={isNoShowLocked}
                    onClick={onNoShow}
                  >
                    {updatingNoShow ? <S.ActivityIndicator /> : 'No show'}
                  </S.Button>

                  <S.Button
                    mood="danger"
                    disabled={isCancelationLocked}
                    onClick={() => cancelationModalRef.current?.openModal()}
                  >
                    Cancelar ordem
                  </S.Button>
                </>
              )}
            </S.FormActions>
          )}
        </S.ModalBody>
      </S.ModalContent>
    </Modal>
  );
});

export default OrderModal;
