import React from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { useAuth } from 'hooks';
import routes from './routes';

const AppRoutes: React.FC = () => {
  const { isLoggedIn, userBelongsToAnyOf } = useAuth();
  const location = useLocation();
  const myRoutes = useRoutes(
    routes({ isLoggedIn, userBelongsToAnyOf, location })
  );

  return <>{myRoutes}</>;
};

export default AppRoutes;
