import * as S from './styles';

interface Download {
  loading?: boolean;
  onExport: () => void;
}

export const DownloadXLS = ({
  loading = false,
  onExport
}: Download) => {

  return (
    <S.DownloadXLS onClick={onExport}>
      {loading ? <S.ActivityIndicator /> : <span>Exportar XLSX</span>}
    </S.DownloadXLS>
  );
};
