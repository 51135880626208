import styled, { css } from 'styled-components';
import { Colors, ColorScheme, Fonts } from 'styles/constants';
import { FieldContainer } from 'styles/components';
import { FieldDirection } from 'contracts/Common';
export {
  FieldError,
  FieldLabel,
  EyeOffIcon,
  EyeIcon,
  ActivityIndicator,
} from 'styles/components';

interface IContainer {
  direction?: FieldDirection;
}

const appyDirection = (direction?: FieldDirection) => {
  switch (direction) {
    case 'column':
      return css`
        flex-direction: column;
        div.main-wrapper {
          flex-direction: column;
        }
      `;
    case 'row':
      return css`
        flex-direction: row;
        gap: 0 8px;
        label.field-label {
          margin-bottom: 0px !important;
        }
        div.main-wrapper {
          display: flex;
          flex-direction: row;
          flex: 1;
        }
        span.field-error {
          padding: 0px 4px;
        }
      `;
    case 'mixed':
    default:
      return css`
        flex-direction: row;
        gap: 0 8px;
        label.field-label {
          margin-top: 8px;
          margin-bottom: 0px !important;
        }
        div.main-wrapper {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
      `;
  }
};

export const Container = styled(FieldContainer)<IContainer>`
  input {
    width: 100%;
    font-size: inherit;
    border-radius: 6px;
    background: ${Colors.White};
    border: 1px solid ${Colors.Gray60};
    color: ${ColorScheme.Text};
    height: 32px;
    padding: 0 16px;
    ::placeholder {
      color: inherit;
      font-family: ${Fonts.GilroyBold};
    }
  }
  ${({ direction }) => appyDirection(direction)}
`;

export const MainWrapper = styled.div.attrs({ className: 'main-wrapper' })`
  display: flex;
  flex: 1;
`;

export const RelativeWrapper = styled.div`
  display: flex;
  flex: 0;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const ActivityIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  width: 44px;
`;

export const PasswdToggler = styled.button.attrs({ type: 'button' })`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: currentColor;
  opacity: 0.6;
  position: absolute;
  right: 0;
  width: 44px;
  svg {
    width: 24px;
  }
`;
