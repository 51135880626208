import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler } from 'services';
import {
  CreateVehicleTypeActions as Actions,
  CreateVehicleTypeRequestAction as RequestAction,
} from 'store/ducks/vehicleTypes';

export function* createVehicleTypeRequest(action: any) {
  const { postData, onSuccess, onFailure }: RequestAction = action;
  try {
    yield call(api.post, `vehicle-types`, postData);
    onSuccess && onSuccess();
    notify('success', 'Veículo criado com sucesso');
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
