import { Formatter } from "utils"
import * as S from "./styles"

interface FileListProps {
  files: File[]
  onFileDelete: (name: string) => void
}

const FilesList = ({ files, onFileDelete }: FileListProps) => {
  return (
    <S.Container>
      {files.map((file) => (
        <S.File key={file.name}>
          {file.name} - {Formatter.bytesToSize(file.size)}
          <S.ButtonIcon type="button" onClick={() => onFileDelete(file.name)}>
            <S.TrashIcon />
          </S.ButtonIcon>
        </S.File>
      ))}
    </S.Container>
  )
}

export { FilesList }
