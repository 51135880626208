import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  FormPageHeader,
  Input,
  MoneyInput,
  NumberInput,
} from 'components/Shared';
import type { OrderItem } from 'contracts/OrderItems';
import { useValidation } from 'hooks';
import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { notify } from 'services';
import { Formatter } from 'utils';
import { v4 as uuidV4 } from 'uuid';
import { CreateItemValidator } from 'validators/OrderItems';
import * as S from './styles';

interface OrderItemsEntry
  extends Pick<OrderItem, 'document' | 'weight' | 'value'> {
  uuid: string;
}
export interface Ref {
  getItems: () => OrderItemsEntry[];
}

interface Props {
  weightCapacity?: number;
  duration?: number;
}

interface IOrderItems
  extends ForwardRefExoticComponent<Props & RefAttributes<Ref>> {}

const OrderItems: IOrderItems = forwardRef<Ref, Props>((props, ref) => {
  const { weightCapacity, duration } = props;
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();

  const [items, setItems] = useState<OrderItemsEntry[]>([]);

  const totalWeight = useMemo((): number => {
    return items.reduce((acc, item) => acc + item.weight, 0);
  }, [items]);

  const onDelete = useCallback((uuid: string): void => {
    setItems((items) => items.filter((item) => item.uuid !== uuid));
  }, []);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef.current?.setErrors({});

        if (!weightCapacity) {
          return notify(
            'error',
            'É necessário selecionar ambos Tipo de veículo e Tipo de carga'
          );
        }

        const { schema } = new CreateItemValidator({
          weightCapacity,
          totalWeight,
        });

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        const newItem: OrderItemsEntry = {
          ...validData,
          uuid: uuidV4(),
        };

        setItems((items) => [...items, newItem]);
        formRef.current?.reset();
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [handleFormErrors, totalWeight, weightCapacity]
  );

  const WeightAndDurationComponent = useCallback((): JSX.Element => {
    return (
      <S.WeightAndDuration>
        {!!weightCapacity && (
          <div title="Limite de peso">
            <S.WeightIcon />
            {Formatter.weight(weightCapacity)}
          </div>
        )}
        {!!duration && (
          <div title="Limite de tempo">
            <S.TimerIcon /> {duration} minutos
          </div>
        )}
      </S.WeightAndDuration>
    );
  }, [duration, weightCapacity]);

  const Item = useCallback(
    (item: OrderItemsEntry): JSX.Element => {
      const { uuid, document, weight, value } = item;

      return (
        <S.ListItem>
          <S.Column>{document}</S.Column>
          <S.Column>{Formatter.weight(weight)}</S.Column>
          <S.Column>{Formatter.currency(value)}</S.Column>
          <S.ActionsColumn>
            <S.ActionButton mood="danger" onClick={() => onDelete(uuid)}>
              <S.TrashIcon />
            </S.ActionButton>
          </S.ActionsColumn>
        </S.ListItem>
      );
    },
    [onDelete]
  );

  useImperativeHandle(
    ref,
    () => ({
      getItems: () => items,
    }),
    [items]
  );

  return (
    <S.Panel>
      <FormPageHeader
        title="Itens do agendamento"
        icon={<S.PackageIcon />}
        actions={<WeightAndDurationComponent />}
      />
      <Form ref={formRef} onSubmit={onSubmit}>
        <S.FormRow>
          <Input name="document" label="Documento" />
          <NumberInput name="weight" label="Peso" suffix=" Kg" />
          <MoneyInput name="value" label="Valor" />
          <S.Button disabled={!weightCapacity} mood="primary" type="submit">
            Adicionar
          </S.Button>
        </S.FormRow>
      </Form>
      <S.List>
        {items.length > 0 && (
          <S.ListHeader>
            <div>Documento</div>
            <div>Peso</div>
            <div>Valor</div>
            <div></div>
          </S.ListHeader>
        )}
        {items.map((item, i) => (
          <Item key={i} {...item} />
        ))}
      </S.List>
    </S.Panel>
  );
});

export default OrderItems;
