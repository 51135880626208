import { FormPageHeader, Paginator } from 'components/Shared';
import { useCarriers, useCompanies, useOperationTypes } from 'hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { PaginateDocksActions as PaginateActions } from 'store/ducks/docks';
import DockCreationModal, { Ref as CreationModalRef } from './CreationModal';
import DocksList from './DocksList';
import * as S from './styles';
import DockUpdateModal, { Ref as UpdateModalRef } from './UpdateModal';

interface Props {
  warehouseId: number | string;
}

export const DocksManager: React.FC<Props> = ({ warehouseId }) => {
  const dispatch: AppDispatch = useDispatch();
  const dockCreationModalRef = useRef<CreationModalRef>(null);
  const dockUpdateModalRef = useRef<UpdateModalRef>(null);

  const { fetchCarriers } = useCarriers();
  const { fetchCompanies } = useCompanies();
  const { fetchOperationTypes } = useOperationTypes();

  const {
    data: docks,
    pagination,
    loading: loadingDocks,
  } = useSelector((state: RootState) => state.paginateDocks);

  const [query, setQuery] = useState({
    search: '',
    page: 1,
    limit: 99,
    warehouseId,
    orderBy: 'id',
    direction: 'asc',
  });

  // We will dispatch this action here on top level
  // to avoid dispatching multiple times down the tree
  const fetchLists = useCallback((): void => {
    fetchCarriers();
    fetchCompanies();
    fetchOperationTypes();
  }, [fetchCarriers, fetchCompanies, fetchOperationTypes]);

  const onPageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const onQueryChange = useCallback((): void => {
    dispatch(PaginateActions.request(query));
  }, [dispatch, query]);

  useEffect(() => {
    fetchLists();
  }, [fetchLists]);

  useEffect(() => {
    onQueryChange();
  }, [onQueryChange]);

  useEffect(() => {
    return () => {
      dispatch(PaginateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.MainPanel>
      <FormPageHeader
        title="Docas"
        icon={<S.DockIcon />}
        isLoading={loadingDocks}
        actions={
          <S.Button
            size="small"
            onClick={dockCreationModalRef?.current?.openModal}
          >
            <S.PlusIcon /> Nova doca
          </S.Button>
        }
      />
      <DockCreationModal
        ref={dockCreationModalRef}
        warehouseId={warehouseId}
        onCreate={onQueryChange}
      />
      <DockUpdateModal ref={dockUpdateModalRef} onUpdate={onQueryChange} />
      <DocksList
        docks={docks}
        onSelectDock={dockUpdateModalRef?.current?.selectDock}
      />
      <Paginator onPageChange={onPageChange} pagination={pagination} />
    </S.MainPanel>
  );
};
