import React, { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Scaffold } from 'layouts';
import { OrderCreationForm } from 'components/Pages/Scheduling';
import * as S from './styles';

export const OrderCreationPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const fromPathname = useMemo((): string => {
    return (location.state as { from?: string })?.from || '/agendamento';
  }, [location]);

  const onCreate = useCallback((): void => {
    navigate(fromPathname);
  }, [fromPathname, navigate]);

  return (
    <Scaffold>
      <S.PageContainer>
        <OrderCreationForm
          onCreate={onCreate}
          backActionPathname={fromPathname}
        />
      </S.PageContainer>
    </Scaffold>
  );
};
