import {
  WarehouseScheduleEntry,
  ScheduleOrder,
  ScheduleReservedInterval,
} from 'contracts/Scheduling';
import { addHours, addMinutes, parseISO, format } from 'date-fns';

const HOUR_IN_MILISECONDS = 60 * 60 * 1000;

interface Config {
  pixelsPerHour: number;
  paddingInMinutes: number;
  segmentsPerHour: number;
  onHoverScale: number;
  onHoverMinWidth: number;
}

interface MainMetadata {
  paddedStartTime: string;
  paddedEndTime: string;
  paddedStartHour: number;
  paddedEndHour: number;
  paddedStartMinute: number;
  paddedEndMinute: number;
  widthInPixels: number;
  startDate: Date;
  endDate: Date;
}

interface TimeBoundaries {
  startTime: string;
  endTime: string;
}

export interface TimeTrackMetadata {
  widthPerSlot: number;
  minutesPerSlot: number;
}

export interface TimeTrackSlot {
  /** represents the time in the format HH:mm */
  time: string;
  /** is a quarter hour like 09:15, 09:30 or 09:45 */
  isQuarterHour: boolean;
  /** is a full hour like 09:00, 10:00 and so on */
  isFullHour: boolean;
  /** belongs to a padding hour on the start or and of the track */
  isOutOfRange: boolean;
  /** the with of each slot */
  widthInPixels: number;
}

interface OrderMetadata {
  /** the content width  */
  containerWidth: number;
  /** how many pixels from the left */
  leftPosition: number;
  /** if the width is to small to contain all content */
  hasCutContent: boolean;
  /** how many pixels we need set back left on hover */
  overflowingPixelsOnHover: number;
}

interface IntervalMetadata {
  /** the content width  */
  containerWidth: number;
  /** how many pixels from the left */
  leftPosition: number;
}

export default class SchedulingHelper {
  public metadata: MainMetadata;

  constructor(
    private readonly entry: WarehouseScheduleEntry,
    private readonly config: Config
  ) {
    this.metadata = this.getMainMetadata();
  }

  /**
   * Returns the time boundaries for the track
   *
   * This is important becouse a dock can change its start and end times
   * causing some orders or reserved intervals to become out of range
   */
  public get timeboundaries(): TimeBoundaries {
    const { dock, orders, dockReservedIntervals } = this.entry;

    const allStartTimes: string[] = [dock.startTime];
    const allEndTimes: string[] = [dock.endTime];

    // adding all order times
    orders.forEach(({ scheduledAt, duration }) => {
      const date = parseISO(scheduledAt);
      allStartTimes.push(format(date, 'HH:mm'));
      allEndTimes.push(format(addMinutes(date, duration), 'HH:mm'));
    });

    // adding all reserved intervals times
    dockReservedIntervals.forEach(({ startTime, endTime }) => {
      allStartTimes.push(startTime);
      allEndTimes.push(endTime);
    });

    const startTime = allStartTimes.reduce(
      (min, time) => (time < min ? time : min),
      dock.startTime
    );

    const endTime = allEndTimes.reduce((max, time, _, arr) => {
      if (time === '00:00:00') {
        max = '23:59:99';
        arr.splice(1);
      }
      return time > max ? time : max;
    }, dock.endTime);

    return { startTime, endTime };
  }

  private getMainMetadata(): MainMetadata {
    const { startTime, endTime } = this.timeboundaries;

    const refDate = new Date().setTime(0);

    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);

    const paddedStart = addMinutes(
      addHours(addMinutes(refDate, startMinute), startHour),
      -this.config.paddingInMinutes
    );

    const paddedEnd = addMinutes(
      addHours(addMinutes(refDate, endMinute), endHour),
      this.config.paddingInMinutes
    );

    const [paddedStartHour, paddedStartMinute] = paddedStart
      .toISOString()
      .substring(11, 16)
      .split(':')
      .map(Number);

    const [paddedEndHour, paddedEndMinute] = paddedEnd
      .toISOString()
      .substring(11, 16)
      .split(':')
      .map(Number);

    const totalHours = Math.abs(
      (paddedEnd.getTime() - paddedStart.getTime()) / HOUR_IN_MILISECONDS
    );

    return {
      paddedStartTime: paddedStart.toISOString().substring(11, 16),
      paddedEndTime: paddedEnd.toISOString().substring(11, 16),
      paddedStartHour,
      paddedEndHour,
      paddedStartMinute,
      paddedEndMinute,
      widthInPixels: totalHours * this.config.pixelsPerHour,
      startDate: addHours(addMinutes(refDate, startMinute), startHour),
      endDate: addHours(addMinutes(refDate, endMinute), endHour),
    };
  }

  public get mainMetadata(): MainMetadata {
    return this.metadata;
  }

  public get trackWidth(): number {
    return this.metadata.widthInPixels;
  }

  public get timeTrackMetadata(): TimeTrackMetadata {
    const { pixelsPerHour, segmentsPerHour } = this.config;

    const widthPerSlot = pixelsPerHour / segmentsPerHour;
    const minutesPerSlot = 60 / segmentsPerHour;

    return {
      widthPerSlot,
      minutesPerSlot,
    };
  }

  public get timeTrackSlots(): TimeTrackSlot[] {
    // we will have a mark for each fifteen minutes

    const { widthPerSlot, minutesPerSlot } = this.timeTrackMetadata;

    const nunberOfMarks = Math.ceil(this.trackWidth / widthPerSlot);

    const slots: TimeTrackSlot[] = [];

    const refDate = new Date().setTime(0);

    const { startDate, endDate, paddedStartMinute, paddedStartHour } =
      this.metadata;

    const start = addHours(
      addMinutes(refDate, paddedStartMinute),
      paddedStartHour
    );

    for (let i = 0; i < nunberOfMarks; i++) {
      const time = addMinutes(start, i * minutesPerSlot);
      const timeString = time.toISOString().substring(11, 16);
      const isFullHour = time.getMinutes() === 0;
      const isQuarterHour = time.getMinutes() % 15 === 0;

      const isOutOfRange =
        time.getTime() < startDate.getTime() ||
        time.getTime() > endDate.getTime();

      slots.push({
        time: timeString,
        isFullHour,
        isQuarterHour,
        isOutOfRange,
        widthInPixels: widthPerSlot,
      });
    }

    return slots;
  }

  public getOrderMetadata(order: ScheduleOrder): OrderMetadata {
    const { scheduledAt, duration } = order;

    const refDate = new Date().setTime(0);
    const [orderStartHour, orderStartMinute] = format(
      parseISO(scheduledAt),
      'HH:mm'
    ).split(':');

    const { paddedStartHour, paddedStartMinute } = this.metadata;

    const orderStartIntHour = parseInt(orderStartHour, 10);
    const orderStartIntMinute = parseInt(orderStartMinute, 10);

    const referenceStart = addHours(
      addMinutes(refDate, paddedStartMinute),
      paddedStartHour
    );

    const orderStart = addHours(
      addMinutes(refDate, orderStartIntMinute),
      orderStartIntHour
    );

    const orderEnd = addHours(
      addMinutes(refDate, orderStartIntMinute + duration),
      orderStartIntHour
    );

    const orderTotalHours =
      (orderEnd.getTime() - orderStart.getTime()) / HOUR_IN_MILISECONDS;

    const orderHoursAfterDockStart =
      (orderStart.getTime() - referenceStart.getTime()) / HOUR_IN_MILISECONDS;

    const width = orderTotalHours * this.config.pixelsPerHour;
    const leftPosition = orderHoursAfterDockStart * this.config.pixelsPerHour;
    const hasCutContent = width < this.config.onHoverMinWidth;

    const minWidthOnHover =
      this.config.onHoverMinWidth * this.config.onHoverScale;
    const willOverflow =
      hasCutContent && leftPosition + minWidthOnHover > this.trackWidth;
    const overflowingPixelsOnHover = willOverflow
      ? leftPosition + minWidthOnHover - this.trackWidth
      : 0;

    return {
      containerWidth: width,
      leftPosition,
      hasCutContent,
      overflowingPixelsOnHover,
    };
  }

  public getIntervalMetadata(
    interval: ScheduleReservedInterval
  ): IntervalMetadata {
    const { startTime, endTime } = interval;
    const { paddedStartHour, paddedStartMinute } = this.metadata;

    const refDate = new Date().setTime(0);
    const [intervalStartHour, intervalStartMinute] = startTime
      .split(':')
      .map(Number);
    const [intervalEndHour, intervalEndMinute] = (
      endTime === '00:00:00' ? '23:59:99' : endTime
    )
      .split(':')
      .map(Number);

    const referenceStart = addHours(
      addMinutes(refDate, paddedStartMinute),
      paddedStartHour
    );

    const intervalStart = addHours(
      addMinutes(refDate, intervalStartMinute),
      intervalStartHour
    );

    const intervalEnd = addHours(
      addMinutes(refDate, intervalEndMinute),
      intervalEndHour
    );

    const intervalTotalHours =
      (intervalEnd.getTime() - intervalStart.getTime()) / HOUR_IN_MILISECONDS;

    const intervalHoursAfterDockStart =
      (intervalStart.getTime() - referenceStart.getTime()) /
      HOUR_IN_MILISECONDS;

    return {
      containerWidth: Math.abs(intervalTotalHours * this.config.pixelsPerHour),
      leftPosition: intervalHoursAfterDockStart * this.config.pixelsPerHour,
    };
  }
}
