import styled from 'styled-components';
import { BasePanel } from 'styles/components';
import { Colors } from 'styles/constants';
export {
  BookContentIcon,
  PlusIcon,
  ModalHeader,
  ModalContent,
  ModalBody,
  FormRow,
  FormActions,
  Button,
  ActivityIndicator,
  TruckIcon,
  OperationIcon,
  DockIcon,
} from 'styles/components';

export const TabedContainer = styled.div``;

export const MainPanel = styled(BasePanel)`
  flex: 0 1 100%;
  flex-direction: column;
  background: ${Colors.White};
  padding: 24px;
`;
