import * as Yup from 'yup';
import { isBefore } from 'date-fns';

export const getIntervalShape = () => ({
  warehouseId: Yup.number()
    .typeError('Selecione um armazém')
    .required('Selecione um armazém'),
  dockId: Yup.number()
    .typeError('Selecione uma doca')
    .required('Selecione uma doca'),
  startDate: Yup.date()
    .typeError('Data inválida')
    .required('Informe a data de início'),
  endDate: Yup.date()
    .typeError('Data inválida')
    .required('Informe a data de término')
    .test({
      name: 'testEndDate',
      test: function (value) {
        try {
          const startDate = this.parent.startDate;

          if (!startDate || !value) return true;

          if (isBefore(value, startDate)) {
            throw new Error(
              'A data de término não pode ser anterior à data de início'
            );
          }

          return true;
        } catch (error: any) {
          return this.createError({
            message: error?.message,
          });
        }
      },
    }),
  startTime: Yup.string().required('Informe a hora de início'),
  endTime: Yup.string().required('Informe a hora de término'),
});
