import { SelectOption } from 'contracts/Common';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { ListCountriesActions as MainActions } from 'store/ducks/countries';

interface FindCountries {
  excludeBlocked?: boolean;
}

export const useCountries = () => {
  const dispatch: AppDispatch = useDispatch();

  const { data: countries, loading: loadingCountries } = useSelector(
    (state: RootState) => state.listCountries
  );

  const [ignoredBlockedId, enableCountryIfBlocked] = useState<number | null>(
    null
  );

  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchCountries = useCallback(
    (query?: FindCountries): void => {
      dispatch(MainActions.request({ ...query }));
    },
    [dispatch]
  );

  const onCountriesLoad = useCallback((): void => {
    setOptions(
      countries.map(({ id, name, blockedAt }) => ({
        value: id,
        label: name,
        isDisabled: ignoredBlockedId !== id && blockedAt !== null,
      }))
    );
  }, [countries, ignoredBlockedId]);

  useEffect(() => {
    onCountriesLoad();
  }, [onCountriesLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    countries,
    countryOptions: options,
    loadingCountries,
    fetchCountries,
    enableCountryIfBlocked,
  };
};

export type CountriesHook = ReturnType<typeof useCountries>;
