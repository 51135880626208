import styled, { css } from 'styled-components';

import { ReactComponent as Alert } from 'assets/icons/alert-icon.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as BarChart } from 'assets/icons/bar-chart.svg';
import { ReactComponent as Block } from 'assets/icons/block.svg';
import { ReactComponent as BookContent } from 'assets/icons/book-content.svg';
import { ReactComponent as Buildings } from 'assets/icons/buildings.svg';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as Cancelation } from 'assets/icons/cancelation.svg';
import { ReactComponent as CheckShield } from 'assets/icons/check-shield.svg';
import { ReactComponent as ChevronRightCircle } from 'assets/icons/chevron-right-circle.svg';
import { ReactComponent as Cog } from 'assets/icons/cog.svg';
import { ReactComponent as Dock } from 'assets/icons/dock.svg';
import { ReactComponent as DownArrowSquare } from 'assets/icons/down-arrow-square.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as EyeFull } from "assets/icons/eye.svg";
import { ReactComponent as Eye } from 'assets/icons/eye-outline.svg';
import { ReactComponent as EyeOff } from 'assets/icons/eye-off-outline.svg';
import { ReactComponent as List } from 'assets/icons/list.svg';
import { ReactComponent as Lock } from 'assets/icons/lock.svg';
import { ReactComponent as Login } from 'assets/icons/login.svg';
import { ReactComponent as Operation } from 'assets/icons/operation.svg';
import { ReactComponent as Package } from 'assets/icons/package.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Printer } from 'assets/icons/printer.svg';
import { ReactComponent as ReservedInterval } from 'assets/icons/reserved-interval.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as Timer } from 'assets/icons/timer.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { ReactComponent as Truck } from 'assets/icons/truck.svg';
import { ReactComponent as UpArrowSquare } from 'assets/icons/up-arrow-square.svg';
import { ReactComponent as UseRectangle } from 'assets/icons/user-rectangle.svg';
import { ReactComponent as UserPin } from 'assets/icons/user-pin.svg';
import { ReactComponent as Warehouse } from 'assets/icons/warehouse.svg';
import { ReactComponent as Weight } from 'assets/icons/weight.svg';
import { Colors } from 'styles/constants';

export {
  LoaderCircle as Loader,
  Checkbox,
  LogOutCircle,
  Upload
} from '@styled-icons/boxicons-regular';
export { CheckboxChecked } from '@styled-icons/boxicons-solid';
export {
  ArrowUnsorted as Sort,
  ArrowSortedUp as SortUp,
  ArrowSortedDown as SortDown,
} from '@styled-icons/typicons';

const baseSvgStyle = css`
  path {
    fill: currentColor;
  }
`;

export const AdminIcon = styled(CheckShield)`
  ${baseSvgStyle};
`;

export const AlertIcon = styled(Alert)`
  ${baseSvgStyle}
`;

export const ArrowLeftIcon = styled(ArrowLeft)`
  ${baseSvgStyle}
`;

export const BarChartIcon = styled(BarChart)`
  ${baseSvgStyle}
`;

export const BlockIcon = styled(Block)`
  ${baseSvgStyle}
`;

export const BookContentIcon = styled(BookContent)`
  ${baseSvgStyle}
`;

export const CalendarIcon = styled(Calendar)`
  ${baseSvgStyle}
`;

export const CancelationIcon = styled(Cancelation)`
  ${baseSvgStyle}
`;

export const CompanyIcon = styled(Buildings)`
  ${baseSvgStyle};
`;

export const DockIcon = styled(Dock)`
  ${baseSvgStyle};
`;

export const DownArrowSquareIcon = styled(DownArrowSquare)`
  ${baseSvgStyle}
`;

export const EditIcon = styled(Edit)`
  ${baseSvgStyle}
`;

export const EyeFullIcon = styled(EyeFull)`
  ${baseSvgStyle}
`;

export const EyeOffIcon = styled(EyeOff)`
  ${baseSvgStyle};
`;

export const EyeIcon = styled(Eye)`
  ${baseSvgStyle};
`;

export const ListIcon = styled(List)`
  ${baseSvgStyle};
`;

export const LockIcon = styled(Lock)`
  ${baseSvgStyle};
  color: ${Colors.Gray50};
  margin-top: 2px;
  margin-bottom: -2px;
`;

export const LoginIcon = styled(Login)`
  ${baseSvgStyle};
`;

export const OperationIcon = styled(Operation)`
  ${baseSvgStyle};
`;

export const PackageIcon = styled(Package)`
  ${baseSvgStyle};
`;

export const PlusIcon = styled(Plus)`
  ${baseSvgStyle}
`;

export const PrinterIcon = styled(Printer)`
  ${baseSvgStyle};
`;

export const ReservedIntervalIcon = styled(ReservedInterval)`
  ${baseSvgStyle};
`;

export const SearchIcon = styled(Search)`
  ${baseSvgStyle}
`;

export const TimerIcon = styled(Timer)`
  ${baseSvgStyle}
`;

export const ChevronRightCircleIcon = styled(ChevronRightCircle)`
  ${baseSvgStyle}
`;

export const SettingsIcon = styled(Cog)`
  ${baseSvgStyle}
`;

export const TrashIcon = styled(Trash)`
  ${baseSvgStyle}
`;

export const UserPinIcon = styled(UserPin)`
  ${baseSvgStyle}
`;

export const UserRectangleIcon = styled(UseRectangle)`
  ${baseSvgStyle}
`;

export const WarehouseIcon = styled(Warehouse)`
  ${baseSvgStyle}
`;

export const TruckIcon = styled(Truck)`
  ${baseSvgStyle}
`;

export const UpArrowSquareIcon = styled(UpArrowSquare)`
  ${baseSvgStyle}
`;

export const WeightIcon = styled(Weight)`
  ${baseSvgStyle}
`;
