import { BaseButtonStyle } from './../../../styles/components/buttons';
import { Colors, Fonts } from './../../../styles/constants';
import styled from "styled-components";
export { ActivityIndicator } from 'styles/components';
//import { DownloadIcon } from 'styles/components';

export const DownloadXLS = styled.button`
  ${BaseButtonStyle}
  gap: 1px;

  font-family: ${Fonts.GilroySemiBold};
  background: ${Colors.Green};
  
  color: ${Colors.White};
  width: 130px;
  height: 30px;
  font-size: 14px;
  cursor: pointer;
  transition: width 2s;
  align-items: center;
  position: relative;
  transition: width 500ms;
  
`;

// export const ArrowIcon = styled(DownloadIcon)`
//     background-color: ${Colors.Orange};
//     border-radius: 100px;
//     width: 30px;
//     height: 30px;
//     padding: 3px;
//     position: absolute;
//     right: 0;
// `;