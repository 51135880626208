import styled from 'styled-components';
import { BaseFiltersContainer } from 'styles/components';
export { FormRow } from 'styles/components';

export const Container = styled(BaseFiltersContainer)`
  form {
    grid-template-columns: 360px auto;
  }
`;

export const ButtonWrapper = styled.div`
  margin-left: auto;
`
