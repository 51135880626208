import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  HiddenInput,
  Input,
  MaskedInput,
  Select,
  FormPageHeader,
} from 'components/Shared';
import { FORM_BACK_ACTION, SELECT_OPTIONS } from 'constants/Common';
import type { Carrier } from 'contracts/Carriers';
import type { CompanyDocumentType, SelectOption } from 'contracts/Common';
import { useAddressLookup, useCountries, useValidation } from 'hooks';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import {
  FetchCarrierActions as FetchActions,
  UpdateCarrierActions as UpdateActions,
} from 'store/ducks/carriers';
import { Formatter } from 'utils';
import { UpdateCarrierValidator } from 'validators/Carriers';
import * as S from './styles';

interface Props {
  carrierId: string | number;
  onUpdate?: () => void;
}

export const CarrierUpdateForm: React.FC<Props> = ({ carrierId, onUpdate }) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors, handleApiErrors } = useValidation();
  const { onZipcodeChange, fetchingAddress } = useAddressLookup(formRef);
  const { countryOptions, loadingCountries, fetchCountries } = useCountries();

  const [selectedType, setSelectedType] = useState<CompanyDocumentType>('cnpj');
  const [selectedCountryId, setSelectedCountryId] = useState<number>(1);

  const { data: carrier, loading: fetchingCarrier } = useSelector(
    (state: RootState) => state.fetchCarrier
  );

  const { loading: updatingCarrier, validationErrors } = useSelector(
    (state: RootState) => state.updateCarrier
  );

  const documentLabel = useMemo(() => {
    if (selectedType === 'cnpj') return 'CNPJ';
    if (selectedType === 'cpf') return 'CPF';
    if (selectedType === 'other') return 'Documento';
  }, [selectedType]);

  const documentMask = useMemo(() => {
    if (selectedType === 'cnpj') return '99.999.999/9999-99';
    if (selectedType === 'cpf') return '999.999.999-99';
    return [];
  }, [selectedType]);

  const fetchCarrier = useCallback((): void => {
    dispatch(FetchActions.request(carrierId));
  }, [dispatch, carrierId]);

  const onCarrierLoad = useCallback((): void => {
    if (!carrier) return;
    const { documentType, document, countryId, addressUf } = carrier;

    const autoSet: Array<keyof Carrier> = [
      'tradeName',
      'ibge',
      'addressStreet',
      'addressNumber',
      'addressComplement',
      'addressNeighborhood',
      'addressCity',
      'addressState',
      'addressZipcode',
      'addressLatitude',
      'addressLongitude',
    ];

    for (const [field, value] of Object.entries(carrier)) {
      if (autoSet.includes(field as keyof Carrier)) {
        formRef.current?.setFieldValue(field, value);
      }
    }

    // manually set fields

    const typeOption = SELECT_OPTIONS.DOCUMENT_TYPES.find(
      (o) => o.value === documentType
    );

    if (typeOption) {
      formRef.current?.setFieldValue('documentType', typeOption);
    }

    setSelectedType(documentType);
    formRef.current?.setFieldValue(
      'document',
      Formatter.document(document, documentType)
    );

    const countryOption = countryOptions.find((o) => o.value === countryId);

    setSelectedCountryId(countryId);
    if (countryOption) {
      formRef.current?.setFieldValue('countryId', countryOption);
    }

    const ufOption = SELECT_OPTIONS.STATES.find((o) => o.value === addressUf);

    if (ufOption) {
      formRef.current?.setFieldValue('addressUf', ufOption);
    }
  }, [carrier, countryOptions]);

  const onTypeChange = useCallback((option: SelectOption | null): void => {
    if (!option) return;
    setSelectedType(option.value as CompanyDocumentType);
  }, []);

  const onCountryChange = useCallback((option: SelectOption | null): void => {
    if (!option) return;
    setSelectedCountryId(option.value as number);
  }, []);

  const onUfChange = useCallback((option: SelectOption | null): void => {
    if (!option) {
      formRef.current?.setFieldValue('addressState', '');
      return;
    }
    formRef.current?.setFieldValue('addressState', option.label);
  }, []);

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
    onUpdate && onUpdate();
  }, [onUpdate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef?.current?.setErrors({});

        const { schema } = new UpdateCarrierValidator({
          selectedType,
          selectedCountryId,
        });

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(UpdateActions.request(carrierId, validData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [
      carrierId,
      dispatch,
      handleFormErrors,
      onSuccess,
      selectedCountryId,
      selectedType,
    ]
  );

  const Header = useCallback((): JSX.Element => {
    return (
      <FormPageHeader
        title="Editar transportadora"
        icon={<S.TruckIcon />}
        isLoading={fetchingCarrier}
        actions={
          <S.LinkButton size="small" to="/configuracoes/transportadoras">
            <S.ArrowLeftIcon /> Voltar
          </S.LinkButton>
        }
      />
    );
  }, [fetchingCarrier]);

  useEffect(() => {
    fetchCountries({ excludeBlocked: true });
  }, [fetchCountries]);

  useEffect(() => {
    fetchCarrier();
  }, [fetchCarrier]);

  useEffect(() => {
    onCarrierLoad();
  }, [onCarrierLoad]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(FetchActions.reset());
      dispatch(UpdateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.MainPanel>
      <Header />
      <Form ref={formRef} onSubmit={onSubmit}>
        <HiddenInput name="ibge" readOnly />
        <S.FormRow>
          <Select
            name="documentType"
            label="Tipo de documento"
            options={SELECT_OPTIONS.DOCUMENT_TYPES}
            onChange={onTypeChange}
          />
          <Input
            name="tradeName"
            label={selectedType === 'cnpj' ? 'Nome fantasia' : 'Nome completo'}
          />
          {!!carrier?.documentType && (
            <>
              {(['cnpj', 'cpf'].includes(carrier.documentType) && (
                <MaskedInput
                  name="document"
                  label={documentLabel}
                  mask={documentMask}
                />
              )) || <Input name="document" label={documentLabel} />}
            </>
          )}
        </S.FormRow>

        <S.FormRow>
          <S.FormRow>
            <Select
              name="countryId"
              label="País"
              options={countryOptions}
              isLoading={loadingCountries}
              onChange={onCountryChange}
            />
            <Input
              name="addressZipcode"
              label="Código postal"
              onChange={onZipcodeChange}
              isLoading={fetchingAddress}
            />
          </S.FormRow>
          <Input name="addressStreet" label="Logradouro" />
          <Input name="addressNumber" label="Número" />
        </S.FormRow>

        <S.FormRow>
          <Input name="addressComplement" label="Complemento" />
          <Input name="addressNeighborhood" label="Bairro" />
          <Input name="addressCity" label="Cidade" />
        </S.FormRow>

        <S.FormRow>
          {(selectedCountryId === 1 && (
            <>
              <HiddenInput name="addressState" readOnly />
              <Select
                name="addressUf"
                label="Estado"
                options={SELECT_OPTIONS.STATES}
                onChange={onUfChange}
              />
            </>
          )) || <Input name="addressState" label="Estado" />}
          <Input name="addressLatitude" label="Latitude" />
          <Input name="addressLongitude" label="Longitude" />
        </S.FormRow>

        <S.FormActions>
          <S.LinkButton mood="light" to="/configuracoes/transportadoras">
            {FORM_BACK_ACTION}
          </S.LinkButton>
          <S.Button type="submit">
            {updatingCarrier ? <S.ActivityIndicator /> : 'Salvar'}
          </S.Button>
        </S.FormActions>
      </Form>
    </S.MainPanel>
  );
};
