import styled from 'styled-components';

export const ModalTab = styled.div.attrs({ className: 'modal-tab' })`
  display: flex;
  justify-content: center;
  position: relative;
  height: 64px;
  padding: 0 16px;
  ::after {
    position: absolute;
    display: flex;
    content: '';
    width: 4px;
    height: 4px;
    background: currentColor;
    bottom: -2px;
    transition: all 200ms ease;
    border-radius: 4px;
  }
`;

export const TitleSlot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const Title = styled.div``;
