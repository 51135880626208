import { SelectOption } from 'contracts/Common';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { ListCarriersActions as MainActions } from 'store/ducks/carriers';
import { Formatter } from 'utils';

export const useCarriers = () => {
  const dispatch: AppDispatch = useDispatch();

  const { data: carriers, loading: loadingCarriers } = useSelector(
    (state: RootState) => state.listCarriers
  );

  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchCarriers = useCallback((): void => {
    dispatch(MainActions.request());
  }, [dispatch]);

  const onCarriersLoad = useCallback((): void => {
    setOptions(
      carriers.map(({ id, tradeName, documentType, document }) => ({
        value: id,
        label: `${tradeName} - ${Formatter.document(document, documentType)}`,
      }))
    );
  }, [carriers]);

  useEffect(() => {
    onCarriersLoad();
  }, [onCarriersLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    carriers,
    carrierOptions: options,
    loadingCarriers,
    fetchCarriers,
  };
};

export type CarriersHook = ReturnType<typeof useCarriers>;
