import * as Yup from 'yup';

export class UpdateCancelationReasonValidator {
  public get schema() {
    return Yup.object().shape({
      name: Yup.string().required('Informe o nome da justificativa'),
      blockedAt: Yup.string()
        .nullable()
        .transform((value) => {
          if (value === '1') return new Date().toISOString();
          return null;
        }),
    });
  }
}
