import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Modal, ModalCloseButton, Select } from 'components/Shared';
import { MODAL_DISMISS_ACTION } from 'constants/Common';
import { useValidation } from 'hooks';
import React, {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { useWarehouses } from 'hooks';
import { CreateWarehouseMemberAccessActions as CreateActions } from 'store/ducks/warehouseMemberAccesses';
import { CreateWarehouseMemberAccess } from 'validators/WarehouseMemberAccesses';
import * as S from './styles';

export interface Ref {
  openModal: () => void;
}

interface Props {
  warehouseMemberId: string | number;
  onCreate?: () => void;
}

interface IWarehouseMemberAccessCreationModal
  extends ForwardRefExoticComponent<Props & RefAttributes<Ref>> {}

const WarehouseMemberAccessCreationModal: IWarehouseMemberAccessCreationModal =
  forwardRef<Ref, Props>((props, ref) => {
    const { warehouseMemberId, onCreate } = props;
    const dispatch: AppDispatch = useDispatch();
    const formRef = useRef<FormHandles>(null);
    const { handleFormErrors, handleApiErrors } = useValidation();
    const { fetchWarehouses, loadingWarehouses, warehouseOptions } =
      useWarehouses();
    const { loading: loadingCreating, validationErrors } = useSelector(
      (state: RootState) => state.createWarehouseMemberAccess
    );

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onModalClose = useCallback(() => {
      setIsOpen(false);
      formRef.current?.setErrors({});
      dispatch(CreateActions.reset());
    }, [dispatch]);

    const onSuccess = useCallback((): void => {
      formRef.current?.reset();
      onModalClose();
      onCreate && onCreate();
    }, [onCreate, onModalClose]);

    const onSubmit = useCallback(
      async (data: any): Promise<void> => {
        try {
          formRef?.current?.setErrors({});
          Object.assign(data, { warehouseMemberId });
          const { schema } = new CreateWarehouseMemberAccess();
          const validData = await schema.validate(data, {
            abortEarly: false,
          });

          dispatch(CreateActions.request(validData, onSuccess));
        } catch (error) {
          handleFormErrors(error, formRef);
        }
      },
      [dispatch, handleFormErrors, onSuccess, warehouseMemberId]
    );

    useEffect(() => {
      fetchWarehouses();
    }, [fetchWarehouses]);

    useEffect(() => {
      handleApiErrors(validationErrors, formRef);
    }, [handleApiErrors, validationErrors]);

    useEffect(() => {
      return () => {
        dispatch(CreateActions.reset());
      };
    }, [dispatch]);

    useImperativeHandle(
      ref,
      () => ({
        openModal: () => setIsOpen(true),
      }),
      []
    );

    return (
      <Modal isOpen={isOpen} onClickOutside={onModalClose}>
        <S.ModalContent style={{ maxWidth: '840px' }}>
          <S.ModalHeader>
            <h4>Associar novo armazém</h4>
            <ModalCloseButton onClick={onModalClose} />
          </S.ModalHeader>
          <S.ModalBody>
            <Form ref={formRef} onSubmit={onSubmit}>
              <S.FormRow>
                <Select
                  name="warehouseId"
                  label="Armazém"
                  isLoading={loadingWarehouses}
                  options={warehouseOptions}
                />
              </S.FormRow>
              <S.FormActions>
                <S.Button type="button" mood="light" onClick={onModalClose}>
                  {MODAL_DISMISS_ACTION}
                </S.Button>
                <S.Button type="submit">
                  {loadingCreating ? <S.ActivityIndicator /> : 'Salvar'}
                </S.Button>
              </S.FormActions>
            </Form>
          </S.ModalBody>
        </S.ModalContent>
      </Modal>
    );
  });

export default WarehouseMemberAccessCreationModal;
