import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from 'contracts/Router';
import {
  CargoTypesListPage,
  CargoTypeCreationPage,
  CargoTypeUpdatePage,
} from 'pages/CargoTypes';

const cargoTypesRoutes = ({
  location,
  userBelongsToAnyOf,
}: RouterProps): RouteObject => ({
  path: 'configuracoes/tipos-cargas',
  element: userBelongsToAnyOf('admin') ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: '',
      element: <CargoTypesListPage />,
    },
    {
      path: 'criar',
      element: <CargoTypeCreationPage />,
    },
    {
      path: ':id/editar',
      element: <CargoTypeUpdatePage />,
    },
  ],
});

export default cargoTypesRoutes;
