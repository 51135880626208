import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'store';
import { Scaffold } from 'layouts';
import { FormPageHeader } from 'components/Shared';
import { IntervalUpdateForm } from 'components/Pages/DockReservedIntervals';
import * as S from './styles';

export const IntervalUpdatePage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();

  const { loading } = useSelector(
    (state: RootState) => state.fetchDockReservedInterval
  );

  const onUpdateOrDelete = useCallback((): void => {
    navigate('/configuracoes/bloqueio-de-horarios');
  }, [navigate]);

  return (
    <Scaffold>
      <S.PageContainer>
        <S.MainPanel>
          <FormPageHeader
            title="Editar bloqueio de horário"
            icon={<S.ReservedIntervalIcon />}
            isLoading={loading}
            actions={
              <S.LinkButton
                size="small"
                to="/configuracoes/bloqueio-de-horarios"
              >
                <S.ArrowLeftIcon /> Voltar
              </S.LinkButton>
            }
          />
          <IntervalUpdateForm
            intervalId={id}
            onUpdate={onUpdateOrDelete}
            onDelete={onUpdateOrDelete}
          />
        </S.MainPanel>
      </S.PageContainer>
    </Scaffold>
  );
};
