import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from 'contracts/Router';
import {
  CancelationReasonsListPage,
  CancelationReasonCreationPage,
  CancelationReasonUpdatePage,
} from 'pages/CancelationReasons';

const cancelationReasonsRoutes = ({
  location,
  userBelongsToAnyOf,
}: RouterProps): RouteObject => ({
  path: 'configuracoes/justificativas-cancelamento',
  element: userBelongsToAnyOf('admin') ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: '',
      element: <CancelationReasonsListPage />,
    },
    {
      path: 'criar',
      element: <CancelationReasonCreationPage />,
    },
    {
      path: ':id/editar',
      element: <CancelationReasonUpdatePage />,
    },
  ],
});

export default cancelationReasonsRoutes;
