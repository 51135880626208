import { FormPageHeader, Paginator } from 'components/Shared';
import { useAuth, usePaginationCache } from 'hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { useLocation } from 'react-router-dom';
import { ExportOrdersActions, PaginateOrdersActions as PaginateActions } from 'store/ducks/orders';
import Filters, { FindOrders } from './Filters';
import OrdersList from './OrdersList';
import OrderModal, { Ref as OrderModalRef } from '../OrderModal';
import * as S from './styles';
import { Order } from 'contracts/Orders';
import { Formatter } from 'utils';
import { DownloadXLS } from 'components/Shared/DownloadXLS';
import { exportXLSX } from 'utils';

export const PaginatedOrders: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const { userBelongsToAnyOf } = useAuth();
  const orderModalRef = useRef<OrderModalRef>(null);

  const {
    data: orders,
    loading: loadingOrders,
    pagination,
  } = useSelector((state: RootState) => state.paginateOrders);
  
  const {
    loading: loadingExportOrders,
  } = useSelector((state: RootState) => state.exportOrders);
  
  const generateXLSX = useCallback((ordersToExport: Order[]) => {
    if (ordersToExport.length) {
      const fileName = 'AGENDAMENTO-PAINEL-GERENCIAL';
      const data = ordersToExport.map((item: Order) => {
        const {
          id,
          scheduledAt,
          dock,
          vehicleType,
          vehiclePlate,
          company,
          carrier,
          status,
          orderType,
          operationType,
          cargoType,
          warehouse,
          weightCapacity,
          duration,
          driverName,
          driverDocument,
          devolutionNumber,
          exportationSecuritySeal,
          exportationContainer,
          importationOrder,
          importationSequential,
          importationDeclaration,
          observation,
          material,
          vehicleArrivedAt,
          vehicleDepartedAt,
          startedAt,
          finishedAt,
          noShowAt,
          canceledAt,
          createdByUser,
        } = item;

        return {
          'Coleta': id ? id : '',
          'Status': status ? status : '',
          'Data do Agendamento': scheduledAt
            ? Formatter.date(scheduledAt, { format: 'dd/MM/yy HH:mm' })
            : '',
          'Tipo de Ordem': orderType.name ? orderType.name : '',
          'Tipo de Operacao': operationType.name ? operationType.name : '',
          'Tipo de Veiculo': vehicleType.name ? vehicleType.name : '',
          'Carga': cargoType.name ? cargoType.name : '',
          'Cliente': company.tradeName ? company.tradeName : '',
          'Transportadora': carrier.tradeName ? carrier.tradeName : '',
          'Armazem': warehouse.tradeName ? warehouse.tradeName : '',
          'Doca': dock.name ? dock.name : '',
          'Capacidade': weightCapacity ? weightCapacity : '',
          'Duracao': duration ? duration : '',
          'Placa': vehiclePlate ? vehiclePlate : '',
          'Documento do  motorista': driverDocument ? driverDocument : '',
          'Nome do Motorista': driverName ? driverName : '',
          'Numero_devolucao': devolutionNumber ? devolutionNumber : '',
          'Exportacao_Lacre': exportationSecuritySeal
            ? exportationSecuritySeal
            : '',
          'Exportacao_Container': exportationContainer
            ? exportationContainer
            : '',
          'Ordem_importacao': importationOrder ? importationOrder : '',
          'Ordem_Sequencial': importationSequential
            ? importationSequential
            : '',
          'Importacao_Sequencial': importationSequential
            ? importationSequential
            : '',
          'Observacao': observation ? observation : '',
          'Produto': material ? material : '',
          'Data/Hora Chegada Veiculo': vehicleArrivedAt
            ? Formatter.date(vehicleArrivedAt, { format: 'dd/MM/yy HH:mm' })
            : '',
          'Data/Hora Saida Veiculo': vehicleDepartedAt
            ? Formatter.date(vehicleDepartedAt, { format: 'dd/MM/yy HH:mm' })
            : '',
          'Data/Hora Inicio': startedAt
            ? Formatter.date(startedAt, { format: 'dd/MM/yy HH:mm' })
            : '',
          'Data/Hora Fim': finishedAt
            ? Formatter.date(finishedAt, { format: 'dd/MM/yy HH:mm' })
            : '',
          'No Show': noShowAt ? noShowAt : '',
          'Criado por': createdByUser.name ? createdByUser.name : '',
          'Importacao_Declaracao': importationDeclaration
            ? importationDeclaration
            : '',
          ' Data/Hora Cancelamento': canceledAt
            ? Formatter.date(canceledAt, { format: 'dd/MM/yy HH:mm' })
            : '',
        };
      });
      
      exportXLSX(data, fileName);
    }
  }, []);

  const { paginationCache, updatePaginationCache, handleFilter, handleSort } =
    usePaginationCache<FindOrders>('orders');

  const [query, setQuery] = useState<FindOrders>({
    page: 1,
    limit: 10,
    status: [
      'aguardando',
      'chegada',
      'iniciado',
      'finalizado',
      'liberado',
      'noShow',
      'cancelado',
      'todos',
    ],
    ...paginationCache,
  });
  
  const exportOrders = useCallback(() => {
    dispatch(ExportOrdersActions.request(query, generateXLSX))
  }, [query, dispatch, generateXLSX])

  const onPageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const onQueryChange = useCallback((): void => {
    dispatch(PaginateActions.request(query));
  }, [dispatch, query]);

  const CreateOrderButton = useCallback((): JSX.Element => {
    if (
      !userBelongsToAnyOf(
        'admin',
        'carrierMember',
        'warehouseMember',
        'companyMember'
      )
    ) {
      return <></>;
    }

    return (
      <S.LinkButton
        size="small"
        to="/agendamento/criar"
        state={{ from: location.pathname }}
      >
        <S.PlusIcon /> Novo agendamento
      </S.LinkButton>
    );
  }, [location.pathname, userBelongsToAnyOf]);

  const CreateOrderButtonDownloand = useCallback((): JSX.Element => {
    if (
      !userBelongsToAnyOf(
        'admin',
        'carrierMember',
        'warehouseMember',
        'companyMember'
      )
    ) {
      return <></>;
    }

    return (
      <DownloadXLS
        loading={loadingExportOrders}
        onExport={exportOrders}
      />
    );
  }, [userBelongsToAnyOf, loadingExportOrders, exportOrders]);

  useEffect(() => {
    onQueryChange();
  }, [onQueryChange]);

  useEffect(() => {
    return () => {
      dispatch(PaginateActions.reset());
      updatePaginationCache(query);
    };
  }, [dispatch, query, updatePaginationCache]);

  return (
    <S.Container>
      <S.Panel>
        <OrderModal onUpdate={onQueryChange} ref={orderModalRef} />
        <FormPageHeader
          title="Painel gerencial"
          icon={<S.ListIcon />}
          isLoading={loadingOrders}
          actions={<CreateOrderButton />}
          downloand={<CreateOrderButtonDownloand />}
        />
        <Filters
          currentFilter={query}
          onFilter={(filter) => handleFilter(query, filter, setQuery)}
        />
        <OrdersList
          orders={orders}
          currentSort={query}
          onSort={(sort) => handleSort(query, sort, setQuery)}
          onSelectOrder={(id) => orderModalRef.current?.selectOrder(id)}
        />
        <Paginator onPageChange={onPageChange} pagination={pagination} />
      </S.Panel>
    </S.Container>
  );
};
