import { SelectOption } from 'contracts/Common';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { ListOrderTypesActions as MainActions } from 'store/ducks/orderTypes';

export const useOrderTypes = () => {
  const dispatch: AppDispatch = useDispatch();

  const { data: orderTypes, loading: loadingOrderTypes } = useSelector(
    (state: RootState) => state.listOrderTypes
  );

  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchOrderTypes = useCallback((): void => {
    dispatch(MainActions.request());
  }, [dispatch]);

  const onOrderTypesLoad = useCallback((): void => {
    setOptions(
      orderTypes.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    );
  }, [orderTypes]);

  useEffect(() => {
    onOrderTypesLoad();
  }, [onOrderTypesLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    orderTypes,
    orderTypeOptions: options,
    loadingOrderTypes,
    fetchOrderTypes,
  };
};

export type OrderTypesHook = ReturnType<typeof useOrderTypes>;
