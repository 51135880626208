import { Formatter } from 'utils/Formatter';
import * as Yup from 'yup';

export const getVehicleSetupShape = () => ({
  vehicleTypeId: Yup.number()
    .typeError('Selecione o tipo de veículo')
    .required('Selecione o tipo de veículo'),
  cargoTypeId: Yup.number()
    .typeError('Selecione o tipo de carga')
    .required('Selecione o tipo de carga'),
  weightCapacity: Yup.number()
    .typeError('Informe a capacidade de carga')
    .required('Informe a capacidade de carga'),
  loadDuration: Yup.mixed()
    .transform((value) => {
      return Formatter.timeToMinutes(value);
    })
    .test({
      name: 'test-load-duration',
      test: function (value) {
        try {
          if (!value || Number.isNaN(value)) {
            throw new Error('Informe a duração de carregamento');
          }
          return true;
        } catch (error: any) {
          return this.createError({
            message: error?.message,
          });
        }
      },
    }),
  unloadDuration: Yup.mixed()
    .transform((value) => {
      return Formatter.timeToMinutes(value);
    })
    .test({
      name: 'test-unload-duration',
      test: function (value) {
        try {
          if (!value || Number.isNaN(value)) {
            throw new Error('Informe a duração de descarregamento');
          }
          return true;
        } catch (error: any) {
          return this.createError({
            message: error?.message,
          });
        }
      },
    }),
  blockedAt: Yup.string()
    .nullable()
    .transform((value) => {
      if (value === '1') return new Date().toISOString();
      return null;
    }),
});
