import React from 'react';
import * as S from './styles';

interface IAlertProps {
  title?: string;
  text?: string;
  close: () => void;
  action: () => void;
  labelAction?: string;
  icon?: JSX.Element;
  isLoading?: boolean;
}

export const Alert: React.FC<IAlertProps> = ({
  title,
  text,
  close,
  action,
  labelAction = 'Ok',
  icon,
  isLoading = false,
}) => {
  return (
    <S.Container className='modal-content'>
      <S.Header>
        {icon || <S.IconWarning />}
        <S.Title>{title}</S.Title>
      </S.Header>
      <S.Text>{text}</S.Text>
      <S.ButtonsWrapper>
        <S.Button mood="light" onClick={close} type="button">
          Cancelar
        </S.Button>
        <S.Button mood="danger" onClick={action} type="button">
          {isLoading ? <S.ActivityIndicator /> : labelAction}
        </S.Button>
      </S.ButtonsWrapper>
    </S.Container>
  );
};
