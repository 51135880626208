import * as Yup from 'yup';
import { getUserShape } from 'validators/Common/baseUserValidator';

export class CreateMemberValidator {
  public get schema() {
    return Yup.object().shape({
      companyId: Yup.number()
        .typeError('Selecione o cliente')
        .required('Selecione o cliente'),
      allowToday: Yup.boolean().nullable(),
      user: Yup.object().shape({
        ...getUserShape(),
      }),
    });
  }
}
