import { CarrierCreationForm } from 'components/Pages/Carriers';
import { Scaffold } from 'layouts';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const CarrierCreationPage: React.FC = () => {
  const navigate = useNavigate();

  const onCreate = useCallback((): void => {
    navigate('/configuracoes/transportadoras');
  }, [navigate]);

  return (
    <Scaffold>
      <CarrierCreationForm onCreate={onCreate} />
    </Scaffold>
  );
};
