import styled from 'styled-components';

export const TabsContent = styled.div``;

export const TabContent = styled.div`
  display: none;
  &.tab-content--active {
    display: initial;
  }
`;
