import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';
import type { WarehouseVehicleSetup } from 'contracts/WarehouseVehicleSetups';

const { Types, Creators } = createActions(
  {
    request: ['id', 'onSuccess', 'onFailure'],
    success: ['data'],
    failure: ['errorMessage'],
    softReset: [],
    reset: [],
  },
  {
    prefix: 'FETCH_WAREHOUSE_VEHICLE_SETUP_',
  }
);

export interface FetchWarehouseVehicleSetupState {
  data: WarehouseVehicleSetup | null;
  loading: boolean;
  errorMessage: string | null;
}

export interface FetchWarehouseVehicleSetupRequestAction {
  id: number;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface SuccessAction {
  data: WarehouseVehicleSetup;
}

interface FailureAction {
  errorMessage: string;
}

const INITIAL_STATE: FetchWarehouseVehicleSetupState = {
  data: null,
  loading: false,
  errorMessage: null,
};

const request = (state: FetchWarehouseVehicleSetupState) =>
  update(state, {
    loading: { $set: true },
    errorMessage: { $set: null },
  });

const success = (
  state: FetchWarehouseVehicleSetupState,
  action: SuccessAction
) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

const failure = (
  state: FetchWarehouseVehicleSetupState,
  action: FailureAction
) =>
  update(state, {
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
  });

const softReset = (state: FetchWarehouseVehicleSetupState) =>
  update(state, {
    loading: { $set: false },
  });

const reset = () => INITIAL_STATE;

export const fetchWarehouseVehicleSetup = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.SOFT_RESET]: softReset,
  [Types.RESET]: reset,
});

export const FetchWarehouseVehicleSetupTypes = Types;
export const FetchWarehouseVehicleSetupActions = Creators;
