import React, { useCallback, useMemo, useState, memo } from 'react';
import { setRecall } from 'store/ducks/leftPanel/recall';
import { useLocation } from 'react-router';
import { useAuth } from 'hooks';
import * as S from './styles';
import { useDispatch } from 'react-redux';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

interface MenuItem {
  icon: React.ReactNode;
  title: string;
  info?: string;
  to: string;
  isAllowed: boolean;
}

const LeftPanel: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { userBelongsToAnyOf } = useAuth();
  const { data: recall } = useSelector((state: RootState) => state.recallSlice);

  const handleCollapse = useCallback(
    () => dispatch(setRecall(!recall)),
    [recall]
  );

  const menuItems = useMemo((): MenuItem[] => {
    return [
      {
        icon: <S.ListIcon />,
        title: 'Painel Gerencial',
        info: 'Acompanhe/busque os agendamento em formato de lista.',
        to: '/',
        isAllowed: true,
      },
      {
        icon: <S.CalendarIcon />,
        title: 'Agendamento',
        info: 'Acompanhe os agendamento em formato de calendário e por doca.',
        to: '/agendamento',
        isAllowed: true,
      },
      {
        icon: <S.UserPinIcon />,
        title: 'Meus dados',
        info: 'Altere seus dados de acesso ao sistema.',
        to: '/meus-dados',
        isAllowed: true,
      },
      {
        icon: <S.SettingsIcon />,
        title: 'Configurações',
        info: 'Acesso exclusivo para administradores do sistema.',
        to: '/configuracoes',
        isAllowed: userBelongsToAnyOf('admin'),
      },
      {
        icon: <S.ReservedIntervalIcon />,
        title: 'Bloqueio de Horários',
        info: 'Crie intervalos de bloqueio para docas',
        to: '/configuracoes/bloqueio-de-horarios',
        isAllowed: userBelongsToAnyOf('warehouseMember'),
      },
    ];
  }, [userBelongsToAnyOf]);

  const isMenuActive = useCallback(
    (item: MenuItem): boolean => {
      return item.to.split('/')[1] === location.pathname.split('/')[1];
    },
    [location]
  );

  return (
    <S.Container>
      <S.CollapseButton onClick={handleCollapse} recall={recall}>
        <S.ArrowIcon />
      </S.CollapseButton>
      <S.LeftPanel recall={recall}>
        <S.Header>
          <S.Logo>
            <S.LogoImage src={require('assets/images/light-logo.png')} />
          </S.Logo>
        </S.Header>
        <S.NavigationMenu>
          {menuItems
            .filter(({ isAllowed }) => {
              return isAllowed;
            })
            .map((item, index) => (
              <S.MenuItem to={item.to} key={index} active={isMenuActive(item)}>
                <S.MenuItemHeader>
                  {item.icon} <p>{item.title}</p>
                </S.MenuItemHeader>
                {item?.info && <S.MenuItemBody>{item.info}</S.MenuItemBody>}
              </S.MenuItem>
            ))}
        </S.NavigationMenu>
        <S.Footer>
          {!recall && (
            <p>{new Date().getFullYear()} © Todos os direitos reservados</p>
          )}
        </S.Footer>
      </S.LeftPanel>
    </S.Container>
  );
};

export default memo(LeftPanel);
