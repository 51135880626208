import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { PaginateVehicleSetupsActions as PaginateActions } from 'store/ducks/vehicleSetups';
import { usePaginationCache } from 'hooks';
import { Scaffold } from 'layouts';
import {
  SetupsList,
  SetupFilters,
  FindSetups,
} from 'components/Pages/VehicleSetups';
import { Paginator, ListingPageHeader, SettingsMenu } from 'components/Shared';
import * as S from './styles';

export const VehicleSetupsListPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const { paginationCache, updatePaginationCache, handleSort, handleFilter } =
    usePaginationCache('vehicleSetups');

  const {
    data: setups,
    pagination,
    loading,
  } = useSelector((state: RootState) => state.paginateVehicleSetups);

  const [query, setQuery] = useState<FindSetups>({
    search: '',
    page: 1,
    limit: 10,
    ...paginationCache,
  });

  const onPageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const onQueryChange = useCallback((): void => {
    dispatch(PaginateActions.request(query));
  }, [dispatch, query]);

  useEffect(() => {
    onQueryChange();
  }, [onQueryChange]);

  useEffect(() => {
    return () => {
      dispatch(PaginateActions.reset());
      updatePaginationCache(query);
    };
  }, [dispatch, query, updatePaginationCache]);

  return (
    <Scaffold>
      <SettingsMenu />
      <S.MainPanel>
        <ListingPageHeader
          icon={<S.TruckIcon />}
          title="Setups de veículos"
          isLoading={loading}
          actions={
            <S.LinkButton
              to={'/configuracoes/setups-veiculos/criar'}
              size="small"
            >
              <S.PlusIcon /> Novo setup de veículo
            </S.LinkButton>
          }
        />
        <SetupFilters
          currentFilter={query}
          onFilter={(filter) => handleFilter(query, filter, setQuery)}
        />
        <SetupsList
          setups={setups}
          currentSort={query}
          onSort={(sort) => handleSort(query, sort, setQuery)}
        />
        <Paginator onPageChange={onPageChange} pagination={pagination} />
      </S.MainPanel>
    </Scaffold>
  );
};
