import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Select } from 'components/Shared';
import type { SelectOption } from 'contracts/Common';
import type { DockCompany } from 'contracts/Docks';
import { useValidation } from 'hooks';
import { Formatter } from 'utils';
import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import {
  CreateDockCompanyActions as CreateActions,
  DeleteDockCompanyActions as DeleteActions,
} from 'store/ducks/dockCompanies';
import * as Yup from 'yup';
import * as S from './styles';

export interface Ref {
  resetForm: () => void;
}

interface Props {
  dockId?: number;
  dockCompanies: DockCompany[];
  onUpdate?: () => void;
}

interface ICompaniesManager
  extends ForwardRefExoticComponent<Props & RefAttributes<Ref>> {}

interface ItemProps {
  dockCompany: DockCompany;
}

const CompaniesManager: ICompaniesManager = forwardRef<Ref, Props>(
  (props, ref) => {
    const { dockId, dockCompanies, onUpdate } = props;

    const dispatch: AppDispatch = useDispatch();
    const formRef = useRef<FormHandles>(null);

    const { handleFormErrors, handleApiErrors } = useValidation();
    const [companyOptions, setCompanyOptions] = useState<SelectOption[]>([]);

    /**
     * this component will not fetch carriers here,
     * we lifted it to a upper level DocksManager component)
     * to avoid dispatching multiple times
     * */
    const { loading: loadingCompanies, data: companies } = useSelector(
      (state: RootState) => state.listCompanies
    );
    const { loading: creating, validationErrors } = useSelector(
      (state: RootState) => state.createDockCompany
    );

    const { id: deletingId } = useSelector(
      (state: RootState) => state.deleteDockCompany
    );

    /**
     * we will also filter the list of companies to only show
     * the ones that are not already bound to the dock
     * */
    const onCompaniesLoad = useCallback((): void => {
      setCompanyOptions(
        companies
          .filter((company) => {
            return !dockCompanies.find(
              (dockCompany) => dockCompany.company.id === company.id
            );
          })
          .map((company) => ({
            value: company.id,
            label: `${company.tradeName} - ${Formatter.document(
              company.document,
              company.documentType
            )}`,
          }))
      );
    }, [companies, dockCompanies]);

    const onDelete = useCallback(
      (id: number): void => {
        dispatch(DeleteActions.request(id, onUpdate));
      },
      [dispatch, onUpdate]
    );

    const onSuccess = useCallback((): void => {
      onUpdate && onUpdate();
      formRef.current?.reset();
    }, [onUpdate]);

    const onSubmit = useCallback(
      async (data: any): Promise<void> => {
        try {
          formRef.current?.setErrors({});

          const schema = Yup.object().shape({
            companyId: Yup.number()
              .typeError('Selecione o cliente')
              .required('Selecione o cliente'),
          });

          const validData = await schema.validate(data, {
            abortEarly: false,
          });

          dispatch(CreateActions.request({ ...validData, dockId }, onSuccess));
        } catch (error) {
          handleFormErrors(error, formRef);
        }
      },
      [dispatch, dockId, handleFormErrors, onSuccess]
    );

    const Item = useCallback(
      (props: ItemProps): JSX.Element => {
        const { id, company } = props.dockCompany;

        return (
          <S.ListItem>
            <S.Column>
              {company.tradeName} -{' '}
              {Formatter.document(company.document, company.documentType)}
            </S.Column>
            <S.ActionsColumn>
              <S.ActionButton
                disabled={deletingId === id}
                onClick={() => onDelete(id)}
                mood="danger"
              >
                {deletingId === id ? <S.ActivityIndicator /> : <S.TrashIcon />}
              </S.ActionButton>
            </S.ActionsColumn>
          </S.ListItem>
        );
      },
      [deletingId, onDelete]
    );

    useEffect(() => {
      onCompaniesLoad();
    }, [onCompaniesLoad]);

    useEffect(() => {
      handleApiErrors(validationErrors, formRef);
    }, [handleApiErrors, validationErrors]);

    useImperativeHandle(
      ref,
      () => ({
        resetForm: () => {
          formRef.current?.reset();
          formRef.current?.setErrors({});
        },
      }),
      []
    );

    return (
      <S.Container>
        <Form ref={formRef} onSubmit={onSubmit}>
          <S.FormRow>
            <Select
              name="companyId"
              label="Cliente"
              options={companyOptions}
              isLoading={loadingCompanies}
              menuPortalTarget={document.body}
            />
            <S.Button type="submit">
              {creating ? <S.ActivityIndicator /> : 'Adicionar'}
            </S.Button>
          </S.FormRow>
        </Form>
        {dockCompanies.map((dockCompany) => (
          <Item dockCompany={dockCompany} key={dockCompany.id} />
        ))}
      </S.Container>
    );
  }
);

export default CompaniesManager;
