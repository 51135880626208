import React from 'react';
import * as S from './styles';

interface Props {
  title?: string | React.ReactNode;
  icon?: React.ReactNode;
}

export const ModalTab: React.FC<Props> = ({ title, icon }) => {
  return (
    <S.ModalTab>
      <S.TitleSlot>
        {icon} <S.Title>{title}</S.Title>
      </S.TitleSlot>
    </S.ModalTab>
  );
};
