import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Select, Modal, ModalCloseButton } from 'components/Shared';
import { MODAL_DISMISS_ACTION } from 'constants/Common';
import { useCancelationReasons, useValidation } from 'hooks';
import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { UpdateOrderCancelationReasonActions as MainActions } from 'store/ducks/orders';
import { UpdateOrderCancelationReasonValidator as Validator } from 'validators/Orders';
import * as S from './styles';

export interface Ref {
  openModal: () => void;
}

interface Props {
  orderId: string | number;
  onUpdate?: () => void;
}

interface ICancelationModal
  extends ForwardRefExoticComponent<Props & RefAttributes<Ref>> {}

const CancelationModal: ICancelationModal = forwardRef((props, ref) => {
  const { orderId, onUpdate } = props;
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors, handleApiErrors } = useValidation();
  const [isOpen, setIsOpen] = useState(false);
  const {
    cancelationReasonOptions,
    fetchCancelationReasons,
    loadingCancelationReasons,
  } = useCancelationReasons();

  const { loading: cancelingOrder, validationErrors } = useSelector(
    (state: RootState) => state.updateOrderCancelationReason
  );

  const onModalClose = useCallback((): void => {
    setIsOpen(false);
    dispatch(MainActions.reset());
    formRef.current?.setErrors({});
    formRef.current?.reset();
  }, [dispatch]);

  const onSuccess = useCallback((): void => {
    formRef.current?.reset();
    onModalClose();
    onUpdate?.();
  }, [onModalClose, onUpdate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef.current?.setErrors({});

        const { schema } = new Validator();

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(MainActions.request(orderId, validData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess, orderId]
  );

  useEffect(() => {
    fetchCancelationReasons({ excludeBlocked: true });
  }, [fetchCancelationReasons]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  useImperativeHandle(
    ref,
    () => ({
      openModal: (): void => {
        setIsOpen(true);
      },
    }),
    []
  );

  return (
    <Modal isOpen={isOpen} onClickOutside={onModalClose}>
      <S.ModalContent style={{ maxWidth: '720px' }}>
        <S.ModalHeader>
          <h4>Cancelar ordem</h4>
          <ModalCloseButton onClick={onModalClose} />
        </S.ModalHeader>
        <S.ModalBody>
          <Form ref={formRef} onSubmit={onSubmit}>
            <Select
              name="cancelationReasonId"
              label="Justificativa"
              options={cancelationReasonOptions}
              isLoading={loadingCancelationReasons}
              menuPortalTarget={document.body}
            />
            <S.FormActions>
              <S.Button type="button" mood="light" onClick={onModalClose}>
                {MODAL_DISMISS_ACTION}
              </S.Button>
              <S.Button type="submit">
                {cancelingOrder ? (
                  <S.ActivityIndicator />
                ) : (
                  'Confirmar cancelamento'
                )}
              </S.Button>
            </S.FormActions>
          </Form>
        </S.ModalBody>
      </S.ModalContent>
    </Modal>
  );
});

export default CancelationModal;
