import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';
import type { ListedCompanyWarehouseAccesses } from 'contracts/CompanyWarehouseAccesses';

const { Types, Creators } = createActions(
  {
    request: ['query', 'onSuccess', 'onFailure'],
    success: ['data'],
    failure: ['errorMessage'],
    softReset: [],
    reset: [],
  },
  {
    prefix: 'LIST_COMPANY_WAREHOUSE_ACCESSES_',
  }
);

export interface ListCompanyWarehouseAccessesState {
  data: ListedCompanyWarehouseAccesses[];
  loading: boolean;
  errorMessage: string | null;
}

export interface ListCompanyWarehouseAccessesRequestAction {
  query?: Record<string, any>;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface SuccessAction {
  data: ListedCompanyWarehouseAccesses[];
}

interface FailureAction {
  errorMessage: string;
}

const INITIAL_STATE: ListCompanyWarehouseAccessesState = {
  data: [],
  loading: false,
  errorMessage: null,
};

const request = (state: ListCompanyWarehouseAccessesState) =>
  update(state, {
    loading: { $set: true },
    errorMessage: { $set: null },
  });

const success = (
  state: ListCompanyWarehouseAccessesState,
  action: SuccessAction
) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

const failure = (
  state: ListCompanyWarehouseAccessesState,
  action: FailureAction
) =>
  update(state, {
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
  });

const softReset = (state: ListCompanyWarehouseAccessesState) =>
  update(state, {
    loading: { $set: false },
  });

const reset = () => INITIAL_STATE;

export const listCompanyWarehouseAccesses = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.SOFT_RESET]: softReset,
  [Types.RESET]: reset,
});

export const ListCompanyWarehouseAccessesTypes = Types;
export const ListCompanyWarehouseAccessesActions = Creators;
