import { SortableHeader, SortableHeadersGroup } from 'components/Shared';
import { SortingParams } from 'contracts/Common';
import type { PaginatedOperationType } from 'contracts/OperationTypes';
import React, { useCallback } from 'react';
import * as S from './styles';

interface Props {
  operationTypes: PaginatedOperationType[];
  onSort?: (sort: SortingParams) => void;
  currentSort: SortingParams;
}

interface ItemProps {
  operationType: PaginatedOperationType;
}

export const TypesList: React.FC<Props> = ({
  operationTypes,
  onSort,
  currentSort,
}) => {
  const Item = useCallback(({ operationType }: ItemProps): JSX.Element => {
    const { id, name, blockedAt } = operationType;

    return (
      <S.ListItem>
        <S.Column>{id}</S.Column>
        <S.Column>{name}</S.Column>
        <S.Column>{blockedAt && <S.LockIcon />}</S.Column>
        <S.ActionsColumn>
          <S.LinkActionButton
            to={`/configuracoes/tipos-operacoes/${id}/editar`}
          >
            <S.EditIcon />
          </S.LinkActionButton>
        </S.ActionsColumn>
      </S.ListItem>
    );
  }, []);

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={currentSort}>
          <SortableHeader column="id" label="ID" />
          <SortableHeader column="name" label="NOME" />
        </SortableHeadersGroup>
      </S.ListHeader>
      {operationTypes.map((setup) => (
        <Item key={setup.id} operationType={setup} />
      ))}
    </S.List>
  );
};
