import * as Yup from 'yup';
import { getVehicleSetupShape } from 'validators/Common/baseVehicleSetupValidator';

export class UpdateVehicleSetupValidator {
  public get schema() {
    return Yup.object().shape({
      ...getVehicleSetupShape(),
    });
  }
}
