import { all, takeLatest } from 'redux-saga/effects';
// ducks
import {
  LoginTypes,
  ImpersonateTypes,
  LogoutTypes,
  UpdateAccountTypes,
  GeneratePasswordResetTokenTypes,
  ResetPasswordTypes,
} from '../ducks/auth';
import { FetchAddressByZipcodeTypes } from '../ducks/addressLookup';
import {
  CreateAdminTypes,
  DeleteAdminTypes,
  FetchAdminTypes,
  PaginateAdminsTypes,
  UpdateAdminTypes,
} from '../ducks/admins';
import {
  CreateCancelationReasonTypes,
  FetchCancelationReasonTypes,
  ListCancelationReasonsTypes,
  PaginateCancelationReasonsTypes,
  UpdateCancelationReasonTypes,
} from '../ducks/cancelationReasons';
import {
  CreateCargoTypeTypes,
  FetchCargoTypeTypes,
  ListCargoTypesTypes,
  PaginateCargoTypesTypes,
  UpdateCargoTypeTypes,
} from '../ducks/cargoTypes';
import {
  CreateCarrierTypes,
  FetchCarrierTypes,
  ListCarriersTypes,
  PaginateCarriersTypes,
  UpdateCarrierTypes,
} from '../ducks/carriers';
import {
  CreateCarrierMemberTypes,
  DeleteCarrierMemberTypes,
  FetchCarrierMemberTypes,
  PaginateCarrierMembersTypes,
  UpdateCarrierMemberTypes,
} from '../ducks/carrierMembers';
import {
  CreateCompanyTypes,
  FetchCompanyTypes,
  ListCompaniesTypes,
  PaginateCompaniesTypes,
  UpdateCompanyTypes,
} from '../ducks/companies';
import {
  CreateCompanyMemberTypes,
  DeleteCompanyMemberTypes,
  FetchCompanyMemberTypes,
  PaginateCompanyMembersTypes,
  UpdateCompanyMemberTypes,
} from '../ducks/companyMembers';
import { ListCountriesTypes } from '../ducks/countries';
import {
  CreateDockTypes,
  FetchDockTypes,
  ListDocksTypes,
  PaginateDocksTypes,
  UpdateDockTypes,
} from '../ducks/docks';
import {
  CreateDockCarrierTypes,
  DeleteDockCarrierTypes,
} from '../ducks/dockCarriers';
import {
  CreateDockCompanyTypes,
  DeleteDockCompanyTypes,
} from '../ducks/dockCompanies';
import {
  CreateDockOperationTypeTypes,
  DeleteDockOperationTypeTypes,
} from '../ducks/dockOperationTypes';
import {
  CreateDockReservedIntervalTypes,
  DeleteDockReservedIntervalTypes,
  FetchDockReservedIntervalTypes,
  ListDockReservedIntervalsTypes,
  PaginateDockReservedIntervalsTypes,
  UpdateDockReservedIntervalTypes,
} from '../ducks/dockReservedIntervals';
import {
  FetchOperationTypeTypes,
  ListOperationTypesTypes,
  PaginateOperationTypesTypes,
  UpdateOperationTypeTypes,
} from '../ducks/operationTypes';
import {
  CreateOrderTypes,
  FetchOrderTypes,
  PaginateOrdersTypes,
  UpdateOrderCancelationReasonTypes,
  UpdateOrderDriverAndVehicleTypes,
  UpdateOrderEventsTypes,
  UpdateOrderNoShowTypes,
  ExportOrdersTypes,
} from '../ducks/orders';
import { FetchOrderTypeTypes, ListOrderTypesTypes } from '../ducks/orderTypes';
import {
  ListSchedulingDocksTypes,
  ListSchedulingVehicleSetupsTypes,
  ListWarehouseScheduleEntriesTypes,
} from '../ducks/scheduling';
import {
  CreateVehicleSetupTypes,
  FetchVehicleSetupTypes,
  ListVehicleSetupsTypes,
  PaginateVehicleSetupsTypes,
  UpdateVehicleSetupTypes,
} from '../ducks/vehicleSetups';
import {
  CreateVehicleTypeTypes,
  FetchVehicleTypeTypes,
  ListVehicleTypesTypes,
  PaginateVehicleTypesTypes,
  UpdateVehicleTypeTypes,
} from '../ducks/vehicleTypes';
import {
  CreateWarehouseTypes,
  FetchWarehouseTypes,
  ListWarehousesTypes,
  PaginateWarehousesTypes,
  UpdateWarehouseTypes,
} from '../ducks/warehouses';
import {
  CreateWarehouseMemberTypes,
  DeleteWarehouseMemberTypes,
  FetchWarehouseMemberTypes,
  ExportWarehouseMembersTypes,
  PaginateWarehouseMembersTypes,
  UpdateWarehouseMemberTypes,
} from '../ducks/warehouseMembers';
import {
  CreateWarehouseVehicleSetupTypes,
  DeleteWarehouseVehicleSetupTypes,
  FetchWarehouseVehicleSetupTypes,
  ListWarehouseVehicleSetupsTypes,
  PaginateWarehouseVehicleSetupsTypes,
  UpdateWarehouseVehicleSetupTypes,
} from '../ducks/warehouseVehicleSetups';
import {
  CreateWarehouseMemberAccessTypes,
  DeleteWarehouseMemberAccessTypes,
  FetchWarehouseMemberAccessTypes,
  ListWarehouseMemberAccessesTypes,
  PaginateWarehouseMemberAccessesTypes,
} from '../ducks/warehouseMemberAccesses';
import {
  CreateCompanyMemberAccessTypes,
  DeleteCompanyMemberAccessTypes,
  FetchCompanyMemberAccessTypes,
  ListCompanyMemberAccessesTypes,
  PaginateCompanyMemberAccessesTypes,
} from '../ducks/companyMemberAccesses';
import {
  CreateCompanyWarehouseAccessTypes,
  DeleteCompanyWarehouseAccessTypes,
  FetchCompanyWarehouseAccessTypes,
  ListCompanyWarehouseAccessesTypes,
  PaginateCompanyWarehouseAccessesTypes,
} from '../ducks/companyWarehouseAccesses';
// sagas
import { rehydrateToken } from './hydration';
import {
  loginRequest,
  impersonateRequest,
  logoutRequest,
  updateAccountRequest,
  generatePasswordResetTokenRequest,
  resetPasswordRequest,
} from './auth';
import { fetchAddressByZipcodeRequest } from './addressLookup';
import {
  createAdminRequest,
  deleteAdminRequest,
  fetchAdminRequest,
  paginateAdminsRequest,
  updateAdminRequest,
} from './admins';
import {
  createCancelationReasonRequest,
  fetchCancelationReasonRequest,
  listCancelationReasonsRequest,
  paginateCancelationReasonsRequest,
  updateCancelationReasonRequest,
} from './cancelationReasons';
import {
  createCargoTypeRequest,
  fetchCargoTypeRequest,
  listCargoTypesRequest,
  paginateCargoTypesRequest,
  updateCargoTypeRequest,
} from './cargoTypes';
import {
  createCarrierRequest,
  fetchCarrierRequest,
  listCarriersRequest,
  paginateCarriersRequest,
  updateCarrierRequest,
} from './carriers';
import {
  createCarrierMemberRequest,
  deleteCarrierMemberRequest,
  fetchCarrierMemberRequest,
  paginateCarrierMembersRequest,
  updateCarrierMemberRequest,
} from './carrierMembers';
import {
  createCompanyRequest,
  fetchCompanyRequest,
  listCompaniesRequest,
  paginateCompaniesRequest,
  updateCompanyRequest,
} from './companies';
import {
  createCompanyMemberRequest,
  deleteCompanyMemberRequest,
  fetchCompanyMemberRequest,
  paginateCompanyMembersRequest,
  updateCompanyMemberRequest,
} from './companyMembers';
import { listCountriesRequest } from './countries';
import {
  createDockRequest,
  fetchDockRequest,
  listDocksRequest,
  paginateDocksRequest,
  updateDockRequest,
} from './docks';
import {
  createDockCarrierRequest,
  deleteDockCarrierRequest,
} from './dockCarriers';
import {
  createDockCompanyRequest,
  deleteDockCompanyRequest,
} from './dockCompanies';
import {
  createDockOperationTypeRequest,
  deleteDockOperationTypeRequest,
} from './dockOperationTypes';
import {
  createDockReservedIntervalRequest,
  deleteDockReservedIntervalRequest,
  fetchDockReservedIntervalRequest,
  listDockReservedIntervalsRequest,
  paginateDockReservedIntervalsRequest,
  updateDockReservedIntervalRequest,
} from './dockReservedIntervals';
import {
  fetchOperationTypeRequest,
  listOperationTypesRequest,
  paginateOperationTypesRequest,
  updateOperationTypeRequest,
} from './operationTypes';
import {
  createOrderRequest,
  fetchOrderRequest,
  paginateOrdersRequest,
  updateOrderCancelationReasonRequest,
  updateOrderDriverAndVehicleRequest,
  updateOrderEventsRequest,
  updateOrderNoShowRequest,
  exportOrdersRequest,
} from './orders';
import { fetchOrderTypeRequest, listOrderTypesRequest } from './orderTypes';
import {
  listSchedulingDocksRequest,
  listSchedulingVehicleSetupsRequest,
  listWarehouseScheduleEntriesRequest,
} from './scheduling';
import {
  createVehicleSetupRequest,
  fetchVehicleSetupRequest,
  listVehicleSetupsRequest,
  paginateVehicleSetupsRequest,
  updateVehicleSetupRequest,
} from './vehicleSetups';
import {
  createVehicleTypeRequest,
  fetchVehicleTypeRequest,
  listVehicleTypesRequest,
  paginateVehicleTypesRequest,
  updateVehicleTypeRequest,
} from './vehicleTypes';
import {
  createWarehouseRequest,
  fetchWarehouseRequest,
  listWarehousesRequest,
  paginateWarehousesRequest,
  updateWarehouseRequest,
} from './warehouses';
import {
  createWarehouseMemberRequest,
  deleteWarehouseMemberRequest,
  fetchWarehouseMemberRequest,
  exportWarehouseMembersRequest,
  paginateWarehouseMembersRequest,
  updateWarehouseMemberRequest,
} from './warehouseMembers';
import {
  createWarehouseVehicleSetupRequest,
  deleteWarehouseVehicleSetupRequest,
  fetchWarehouseVehicleSetupRequest,
  listWarehouseVehicleSetupsRequest,
  paginateWarehouseVehicleSetupsRequest,
  updateWarehouseVehicleSetupRequest,
} from './warehouseVehicleSetups';
import {
  createWarehouseMemberAccessRequest,
  deleteWarehouseMemberAccessRequest,
  fetchWarehouseMemberAccessRequest,
  listWarehouseMemberAccessesRequest,
  paginateWarehouseMemberAccessesRequest,
} from './warehouseMemberAccesses';
import {
  createCompanyMemberAccessRequest,
  deleteCompanyMemberAccessRequest,
  fetchCompanyMemberAccessRequest,
  listCompanyMemberAccessesRequest,
  paginateCompanyMemberAccessesRequest,
} from './companyMemberAccesses';
import {
  createCompanyWarehouseAccessRequest,
  deleteCompanyWarehouseAccessRequest,
  fetchCompanyWarehouseAccessRequest,
  listCompanyWarehouseAccessesRequest,
  paginateCompanyWarehouseAccessesRequest,
} from './companyWarehouseAccesses';
import {
  CreateWarehouseCarrierTypes,
  DeleteWarehouseCarrierTypes,
} from 'store/ducks/warehouseCarriers';
import {
  createWarehouseCarrierRequest,
  deleteWarehouseCarrierRequest,
} from './warehouseCarriers';
import {
  CreateWarehouseCompanyTypes,
  DeleteWarehouseCompanyTypes,
} from 'store/ducks/warehouseCompanies';
import {
  createWarehouseCompanyRequest,
  deleteWarehouseCompanyRequest,
} from './warehouseCompanies';
import {
  CreateWarehouseOperationTypeTypes,
  DeleteWarehouseOperationTypeTypes,
} from 'store/ducks/warehouseOperationTypes';
import {
  createWarehouseOperationTypeRequest,
  deleteWarehouseOperationTypeRequest,
} from './warehouseOperationTypes';
import {
  CreateWarehouseVehicleTypeTypes,
  DeleteWarehouseVehicleTypeTypes,
} from 'store/ducks/warehouseVehicleTypes';
import {
  createWarehouseVehicleTypeRequest,
  deleteWarehouseVehicleTypeRequest,
} from './warehouseVehicleTypes';
import {
  CreateCompanyWarehouseTypes,
  DeleteCompanyWarehouseTypes,
} from 'store/ducks/companyWarehouses';
import {
  createCompanyWarehouseRequest,
  deleteCompanyWarehouseRequest,
} from './companyWarehouses';

export default function* rootSaga() {
  yield all([
    // hydration
    takeLatest('persist/REHYDRATE', rehydrateToken),
    // auth
    takeLatest(LoginTypes.REQUEST, loginRequest),
    takeLatest(ImpersonateTypes.REQUEST, impersonateRequest),
    takeLatest(LogoutTypes.REQUEST, logoutRequest),
    takeLatest(UpdateAccountTypes.REQUEST, updateAccountRequest),
    takeLatest(
      GeneratePasswordResetTokenTypes.REQUEST,
      generatePasswordResetTokenRequest
    ),
    takeLatest(ResetPasswordTypes.REQUEST, resetPasswordRequest),
    // address lookup
    takeLatest(
      FetchAddressByZipcodeTypes.REQUEST,
      fetchAddressByZipcodeRequest
    ),
    // admins
    takeLatest(CreateAdminTypes.REQUEST, createAdminRequest),
    takeLatest(DeleteAdminTypes.REQUEST, deleteAdminRequest),
    takeLatest(FetchAdminTypes.REQUEST, fetchAdminRequest),
    takeLatest(PaginateAdminsTypes.REQUEST, paginateAdminsRequest),
    takeLatest(UpdateAdminTypes.REQUEST, updateAdminRequest),
    // cancelation reasons
    takeLatest(
      CreateCancelationReasonTypes.REQUEST,
      createCancelationReasonRequest
    ),
    takeLatest(
      FetchCancelationReasonTypes.REQUEST,
      fetchCancelationReasonRequest
    ),
    takeLatest(
      ListCancelationReasonsTypes.REQUEST,
      listCancelationReasonsRequest
    ),
    takeLatest(
      PaginateCancelationReasonsTypes.REQUEST,
      paginateCancelationReasonsRequest
    ),
    takeLatest(
      UpdateCancelationReasonTypes.REQUEST,
      updateCancelationReasonRequest
    ),
    // cargo types
    takeLatest(CreateCargoTypeTypes.REQUEST, createCargoTypeRequest),
    takeLatest(FetchCargoTypeTypes.REQUEST, fetchCargoTypeRequest),
    takeLatest(ListCargoTypesTypes.REQUEST, listCargoTypesRequest),
    takeLatest(PaginateCargoTypesTypes.REQUEST, paginateCargoTypesRequest),
    takeLatest(UpdateCargoTypeTypes.REQUEST, updateCargoTypeRequest),
    // carriers
    takeLatest(CreateCarrierTypes.REQUEST, createCarrierRequest),
    takeLatest(FetchCarrierTypes.REQUEST, fetchCarrierRequest),
    takeLatest(ListCarriersTypes.REQUEST, listCarriersRequest),
    takeLatest(PaginateCarriersTypes.REQUEST, paginateCarriersRequest),
    takeLatest(UpdateCarrierTypes.REQUEST, updateCarrierRequest),
    // carrier members
    takeLatest(CreateCarrierMemberTypes.REQUEST, createCarrierMemberRequest),
    takeLatest(DeleteCarrierMemberTypes.REQUEST, deleteCarrierMemberRequest),
    takeLatest(FetchCarrierMemberTypes.REQUEST, fetchCarrierMemberRequest),
    takeLatest(
      PaginateCarrierMembersTypes.REQUEST,
      paginateCarrierMembersRequest
    ),
    takeLatest(UpdateCarrierMemberTypes.REQUEST, updateCarrierMemberRequest),
    // companies
    takeLatest(CreateCompanyTypes.REQUEST, createCompanyRequest),
    takeLatest(FetchCompanyTypes.REQUEST, fetchCompanyRequest),
    takeLatest(ListCompaniesTypes.REQUEST, listCompaniesRequest),
    takeLatest(PaginateCompaniesTypes.REQUEST, paginateCompaniesRequest),
    takeLatest(UpdateCompanyTypes.REQUEST, updateCompanyRequest),
    // company members
    takeLatest(CreateCompanyMemberTypes.REQUEST, createCompanyMemberRequest),
    takeLatest(DeleteCompanyMemberTypes.REQUEST, deleteCompanyMemberRequest),
    takeLatest(FetchCompanyMemberTypes.REQUEST, fetchCompanyMemberRequest),
    takeLatest(
      PaginateCompanyMembersTypes.REQUEST,
      paginateCompanyMembersRequest
    ),
    takeLatest(UpdateCompanyMemberTypes.REQUEST, updateCompanyMemberRequest),
    // countries
    takeLatest(ListCountriesTypes.REQUEST, listCountriesRequest),
    // docks
    takeLatest(CreateDockTypes.REQUEST, createDockRequest),
    takeLatest(FetchDockTypes.REQUEST, fetchDockRequest),
    takeLatest(ListDocksTypes.REQUEST, listDocksRequest),
    takeLatest(PaginateDocksTypes.REQUEST, paginateDocksRequest),
    takeLatest(UpdateDockTypes.REQUEST, updateDockRequest),
    // dock and carriers
    takeLatest(CreateDockCarrierTypes.REQUEST, createDockCarrierRequest),
    takeLatest(DeleteDockCarrierTypes.REQUEST, deleteDockCarrierRequest),
    // dock and companies
    takeLatest(CreateDockCompanyTypes.REQUEST, createDockCompanyRequest),
    takeLatest(DeleteDockCompanyTypes.REQUEST, deleteDockCompanyRequest),
    // dock and operation types
    takeLatest(
      CreateDockOperationTypeTypes.REQUEST,
      createDockOperationTypeRequest
    ),
    takeLatest(
      DeleteDockOperationTypeTypes.REQUEST,
      deleteDockOperationTypeRequest
    ),
    // dock reserved intervals
    takeLatest(
      CreateDockReservedIntervalTypes.REQUEST,
      createDockReservedIntervalRequest
    ),
    takeLatest(
      DeleteDockReservedIntervalTypes.REQUEST,
      deleteDockReservedIntervalRequest
    ),
    takeLatest(
      FetchDockReservedIntervalTypes.REQUEST,
      fetchDockReservedIntervalRequest
    ),
    takeLatest(
      ListDockReservedIntervalsTypes.REQUEST,
      listDockReservedIntervalsRequest
    ),
    takeLatest(
      PaginateDockReservedIntervalsTypes.REQUEST,
      paginateDockReservedIntervalsRequest
    ),
    takeLatest(
      UpdateDockReservedIntervalTypes.REQUEST,
      updateDockReservedIntervalRequest
    ),
    // operation types
    takeLatest(FetchOperationTypeTypes.REQUEST, fetchOperationTypeRequest),
    takeLatest(ListOperationTypesTypes.REQUEST, listOperationTypesRequest),
    takeLatest(
      PaginateOperationTypesTypes.REQUEST,
      paginateOperationTypesRequest
    ),
    takeLatest(UpdateOperationTypeTypes.REQUEST, updateOperationTypeRequest),
    // orders
    takeLatest(CreateOrderTypes.REQUEST, createOrderRequest),
    takeLatest(FetchOrderTypes.REQUEST, fetchOrderRequest),
    takeLatest(PaginateOrdersTypes.REQUEST, paginateOrdersRequest),
    takeLatest(UpdateOrderEventsTypes.REQUEST, updateOrderEventsRequest),
    takeLatest(UpdateOrderNoShowTypes.REQUEST, updateOrderNoShowRequest),
    takeLatest(
      UpdateOrderCancelationReasonTypes.REQUEST,
      updateOrderCancelationReasonRequest
    ),
    takeLatest(
      UpdateOrderDriverAndVehicleTypes.REQUEST,
      updateOrderDriverAndVehicleRequest
    ),
    takeLatest(ExportOrdersTypes.REQUEST, exportOrdersRequest),
    // order types
    takeLatest(FetchOrderTypeTypes.REQUEST, fetchOrderTypeRequest),
    takeLatest(ListOrderTypesTypes.REQUEST, listOrderTypesRequest),
    // scheduling
    takeLatest(ListSchedulingDocksTypes.REQUEST, listSchedulingDocksRequest),
    takeLatest(
      ListSchedulingVehicleSetupsTypes.REQUEST,
      listSchedulingVehicleSetupsRequest
    ),
    takeLatest(
      ListWarehouseScheduleEntriesTypes.REQUEST,
      listWarehouseScheduleEntriesRequest
    ),
    // vehicle setups
    takeLatest(CreateVehicleSetupTypes.REQUEST, createVehicleSetupRequest),
    takeLatest(FetchVehicleSetupTypes.REQUEST, fetchVehicleSetupRequest),
    takeLatest(ListVehicleSetupsTypes.REQUEST, listVehicleSetupsRequest),
    takeLatest(
      PaginateVehicleSetupsTypes.REQUEST,
      paginateVehicleSetupsRequest
    ),
    takeLatest(UpdateVehicleSetupTypes.REQUEST, updateVehicleSetupRequest),
    // vehicle types
    takeLatest(CreateVehicleTypeTypes.REQUEST, createVehicleTypeRequest),
    takeLatest(FetchVehicleTypeTypes.REQUEST, fetchVehicleTypeRequest),
    takeLatest(ListVehicleTypesTypes.REQUEST, listVehicleTypesRequest),
    takeLatest(PaginateVehicleTypesTypes.REQUEST, paginateVehicleTypesRequest),
    takeLatest(UpdateVehicleTypeTypes.REQUEST, updateVehicleTypeRequest),
    // warehouses
    takeLatest(CreateWarehouseTypes.REQUEST, createWarehouseRequest),
    takeLatest(FetchWarehouseTypes.REQUEST, fetchWarehouseRequest),
    takeLatest(ListWarehousesTypes.REQUEST, listWarehousesRequest),
    takeLatest(PaginateWarehousesTypes.REQUEST, paginateWarehousesRequest),
    takeLatest(UpdateWarehouseTypes.REQUEST, updateWarehouseRequest),
    // warehouse members
    takeLatest(
      CreateWarehouseMemberTypes.REQUEST,
      createWarehouseMemberRequest
    ),
    takeLatest(
      DeleteWarehouseMemberTypes.REQUEST,
      deleteWarehouseMemberRequest
    ),
    takeLatest(FetchWarehouseMemberTypes.REQUEST, fetchWarehouseMemberRequest),
    takeLatest(
      ExportWarehouseMembersTypes.REQUEST,
      exportWarehouseMembersRequest
    ),
    takeLatest(
      PaginateWarehouseMembersTypes.REQUEST,
      paginateWarehouseMembersRequest
    ),
    takeLatest(
      UpdateWarehouseMemberTypes.REQUEST,
      updateWarehouseMemberRequest
    ),
    // warehouse vehicle setups
    takeLatest(
      CreateWarehouseVehicleSetupTypes.REQUEST,
      createWarehouseVehicleSetupRequest
    ),
    takeLatest(
      DeleteWarehouseVehicleSetupTypes.REQUEST,
      deleteWarehouseVehicleSetupRequest
    ),
    takeLatest(
      FetchWarehouseVehicleSetupTypes.REQUEST,
      fetchWarehouseVehicleSetupRequest
    ),
    takeLatest(
      ListWarehouseVehicleSetupsTypes.REQUEST,
      listWarehouseVehicleSetupsRequest
    ),
    takeLatest(
      PaginateWarehouseVehicleSetupsTypes.REQUEST,
      paginateWarehouseVehicleSetupsRequest
    ),
    takeLatest(
      UpdateWarehouseVehicleSetupTypes.REQUEST,
      updateWarehouseVehicleSetupRequest
    ),
    // warehouse member access
    takeLatest(
      CreateWarehouseMemberAccessTypes.REQUEST,
      createWarehouseMemberAccessRequest
    ),
    takeLatest(
      DeleteWarehouseMemberAccessTypes.REQUEST,
      deleteWarehouseMemberAccessRequest
    ),
    takeLatest(
      FetchWarehouseMemberAccessTypes.REQUEST,
      fetchWarehouseMemberAccessRequest
    ),
    takeLatest(
      ListWarehouseMemberAccessesTypes.REQUEST,
      listWarehouseMemberAccessesRequest
    ),
    takeLatest(
      PaginateWarehouseMemberAccessesTypes.REQUEST,
      paginateWarehouseMemberAccessesRequest
    ),
    // company members access
    takeLatest(
      CreateCompanyMemberAccessTypes.REQUEST,
      createCompanyMemberAccessRequest
    ),
    takeLatest(
      DeleteCompanyMemberAccessTypes.REQUEST,
      deleteCompanyMemberAccessRequest
    ),
    takeLatest(
      FetchCompanyMemberAccessTypes.REQUEST,
      fetchCompanyMemberAccessRequest
    ),
    takeLatest(
      ListCompanyMemberAccessesTypes.REQUEST,
      listCompanyMemberAccessesRequest
    ),
    takeLatest(
      PaginateCompanyMemberAccessesTypes.REQUEST,
      paginateCompanyMemberAccessesRequest
    ),
    // company warehouse access
    takeLatest(
      CreateCompanyWarehouseAccessTypes.REQUEST,
      createCompanyWarehouseAccessRequest
    ),
    takeLatest(
      DeleteCompanyWarehouseAccessTypes.REQUEST,
      deleteCompanyWarehouseAccessRequest
    ),
    takeLatest(
      FetchCompanyWarehouseAccessTypes.REQUEST,
      fetchCompanyWarehouseAccessRequest
    ),
    takeLatest(
      ListCompanyWarehouseAccessesTypes.REQUEST,
      listCompanyWarehouseAccessesRequest
    ),
    takeLatest(
      PaginateCompanyWarehouseAccessesTypes.REQUEST,
      paginateCompanyWarehouseAccessesRequest
    ),
    //warehouse carrier rules
    takeLatest(
      DeleteWarehouseCarrierTypes.REQUEST,
      deleteWarehouseCarrierRequest
    ),
    takeLatest(
      CreateWarehouseCarrierTypes.REQUEST,
      createWarehouseCarrierRequest
    ),
    //warehouse company rules
    takeLatest(
      DeleteWarehouseCompanyTypes.REQUEST,
      deleteWarehouseCompanyRequest
    ),
    takeLatest(
      CreateWarehouseCompanyTypes.REQUEST,
      createWarehouseCompanyRequest
    ),
    //warehouse vehicle type rules
    takeLatest(
      DeleteWarehouseVehicleTypeTypes.REQUEST,
      deleteWarehouseVehicleTypeRequest
    ),
    takeLatest(
      CreateWarehouseVehicleTypeTypes.REQUEST,
      createWarehouseVehicleTypeRequest
    ),
    //warehouse operation type rules
    takeLatest(
      DeleteWarehouseOperationTypeTypes.REQUEST,
      deleteWarehouseOperationTypeRequest
    ),
    takeLatest(
      CreateWarehouseOperationTypeTypes.REQUEST,
      createWarehouseOperationTypeRequest
    ),
    //company Warehouse associations
    takeLatest(
      CreateCompanyWarehouseTypes.REQUEST,
      createCompanyWarehouseRequest
    ),
    takeLatest(
      DeleteCompanyWarehouseTypes.REQUEST,
      deleteCompanyWarehouseRequest
    ),
  ]);
}
