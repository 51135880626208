import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';

const { Types, Creators } = createActions(
  {
    request: ['id', 'onSuccess', 'onFailure'],
    success: [],
    failure: ['errorMessage'],
    softReset: [],
    reset: [],
  },
  {
    prefix: 'DELETE_DOCK_RESERVED_INTERVAL_',
  }
);

export interface DeleteDockReservedIntervalState {
  id: number | null;
  loading: boolean;
  errorMessage: string | null;
}

export interface DeleteDockReservedIntervalRequestAction {
  id: number;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface FailureAction {
  errorMessage: string;
}

const INITIAL_STATE: DeleteDockReservedIntervalState = {
  id: null,
  loading: false,
  errorMessage: null,
};

const request = (
  state: DeleteDockReservedIntervalState,
  action: DeleteDockReservedIntervalRequestAction
) =>
  update(state, {
    id: { $set: action.id },
    loading: { $set: true },
    errorMessage: { $set: null },
  });

const success = (state: DeleteDockReservedIntervalState) =>
  update(state, {
    id: { $set: null },
    loading: { $set: false },
  });

const failure = (
  state: DeleteDockReservedIntervalState,
  action: FailureAction
) =>
  update(state, {
    id: { $set: null },
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
  });

const softReset = (state: DeleteDockReservedIntervalState) =>
  update(state, {
    id: { $set: null },
    loading: { $set: false },
  });

const reset = () => INITIAL_STATE;

export const deleteDockReservedInterval = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.SOFT_RESET]: softReset,
  [Types.RESET]: reset,
});

export const DeleteDockReservedIntervalTypes = Types;
export const DeleteDockReservedIntervalActions = Creators;
