import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Scaffold } from 'layouts';
import { FormPageHeader } from 'components/Shared';
import { IntervalCreationForm } from 'components/Pages/DockReservedIntervals';
import * as S from './styles';

export const IntervalCreationPage: React.FC = () => {
  const navigate = useNavigate();

  const onCreateOrDelete = useCallback((): void => {
    navigate('/configuracoes/bloqueio-de-horarios');
  }, [navigate]);

  return (
    <Scaffold>
      <S.PageContainer>
        <S.MainPanel>
          <FormPageHeader
            title="Novo bloqueio de horário"
            icon={<S.ReservedIntervalIcon />}
            actions={
              <S.LinkButton
                size="small"
                to="/configuracoes/bloqueio-de-horarios"
              >
                <S.ArrowLeftIcon /> Voltar
              </S.LinkButton>
            }
          />
          <IntervalCreationForm onCreate={onCreateOrDelete} />
        </S.MainPanel>
      </S.PageContainer>
    </Scaffold>
  );
};
