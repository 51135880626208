import React from 'react';
import * as S from './styles';

interface Props {
  title: string | React.ReactNode;
  icon?: React.ReactNode;
  actions?: React.ReactNode;
  isLoading?: boolean;
  downloand?: any;
  removeSpacing?: boolean;
  showBorder?: boolean;
}

export const FormPageHeader: React.FC<Props> = ({
  title,
  icon,
  actions,
  isLoading = false,
  downloand,
  removeSpacing,
  showBorder = true,
}) => {
  return (
    <S.PageHeader
      className="form-page-header"
      removeSpacing={removeSpacing}
      showBorder={showBorder}
    >
      <S.TitleSlot>
        {icon} <S.Title>{title}</S.Title> {isLoading && <S.ActivityIndicator />}
      </S.TitleSlot>
      <S.ActionsSlot>
        {downloand}
        {actions}
      </S.ActionsSlot>
    </S.PageHeader>
  );
};
