import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Input, ToggleInput } from 'components/Shared';
import { FORM_BACK_ACTION } from 'constants/Common';
import { useValidation } from 'hooks';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import {
  FetchVehicleTypeActions as FetchActions,
  UpdateVehicleTypeActions as UpdateActions,
} from 'store/ducks/vehicleTypes';
import { UpdateVehicleTypeValidator } from 'validators/VehicleTypes';
import * as S from './styles';

interface Props {
  typeId: string | number;
  onUpdate?: () => void;
}

export const TypeUpdateForm: React.FC<Props> = ({ typeId, onUpdate }) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors, handleApiErrors } = useValidation();

  const { data: vehicleType } = useSelector(
    (state: RootState) => state.fetchVehicleType
  );

  const { loading: updatingVehicleType, validationErrors } = useSelector(
    (state: RootState) => state.updateVehicleType
  );

  const fetchVehicleType = useCallback(() => {
    dispatch(FetchActions.request(typeId));
  }, [dispatch, typeId]);

  const onVehicleTypeLoad = useCallback((): void => {
    if (!vehicleType) return;
    const { name, blockedAt } = vehicleType;

    formRef.current?.setData({
      name,
      blockedAt: blockedAt ? '1' : '0',
    });
  }, [vehicleType]);

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
    onUpdate && onUpdate();
  }, [onUpdate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef?.current?.setErrors({});

        const { schema } = new UpdateVehicleTypeValidator();

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(UpdateActions.request(typeId, validData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess, typeId]
  );

  useEffect(() => {
    fetchVehicleType();
  }, [fetchVehicleType]);

  useEffect(() => {
    onVehicleTypeLoad();
  }, [onVehicleTypeLoad]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(FetchActions.reset());
      dispatch(UpdateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.Container>
      <Form ref={formRef} onSubmit={onSubmit}>
        <S.FormRow>
          <Input name="name" label="Nome" />
          <ToggleInput name="blockedAt" label="Bloqueado" />
        </S.FormRow>
        <S.FormActions>
          <S.LinkButton mood="light" to="/configuracoes/tipos-veiculos">
            {FORM_BACK_ACTION}
          </S.LinkButton>
          <S.Button type="submit">
            {updatingVehicleType ? <S.ActivityIndicator /> : 'Salvar'}
          </S.Button>
        </S.FormActions>
      </Form>
    </S.Container>
  );
};
