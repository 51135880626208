import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from 'contracts/Router';
import {
  IntervalCreationPage,
  IntervalsListPage,
  IntervalUpdatePage,
} from 'pages/DockReservedIntervals';

/**
 * This one can be accessed by admins and warehouse members
 */
const dockReservedIntervalsRoutes = ({
  location,
  userBelongsToAnyOf,
}: RouterProps): RouteObject => ({
  path: 'configuracoes/bloqueio-de-horarios',
  element: userBelongsToAnyOf('admin', 'warehouseMember') ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: '',
      element: <IntervalsListPage />,
    },
    {
      path: 'criar',
      element: <IntervalCreationPage />,
    },
    {
      path: ':id/editar',
      element: <IntervalUpdatePage />,
    },
  ],
});

export default dockReservedIntervalsRoutes;
