import * as Yup from 'yup';
import { getUserShape } from 'validators/Common/baseUserValidator';

export class CreateMemberValidator {
  public get schema() {
    return Yup.object().shape({
      carrierId: Yup.number()
        .typeError('Selecione a transportadora')
        .required('Selecione a transportadora'),
      user: Yup.object().shape({
        ...getUserShape(),
      }),
    });
  }
}
