import styled from 'styled-components';
export {
  ModalHeader,
  ModalContent,
  ModalBody,
  FormRow,
  FormActions,
  Button,
  ActivityIndicator,
  TruckIcon,
  OperationIcon,
  DockIcon,
} from 'styles/components';

export const TabedContainer = styled.div``;
