import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler } from 'services';
import {
  CreateWarehouseCompanyActions as Actions,
  CreateWarehouseCompanyRequestAction as RequestAction,
} from 'store/ducks/warehouseCompanies';

export function* createWarehouseCompanyRequest(action: any) {
  const { postData, onSuccess, onFailure }: RequestAction = action;
  try {
    yield call(api.post, `warehouse-companies`, postData);
    onSuccess && onSuccess();
    notify('success', 'Regra vinculada com sucesso');
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
