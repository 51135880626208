import { SortableHeader, SortableHeadersGroup } from 'components/Shared';
import { SortingParams } from 'contracts/Common';
import type { PaginatedVehicleSetup } from 'contracts/VehicleSetups';
import React, { useCallback } from 'react';
import { Formatter } from 'utils';
import * as S from './styles';

interface Props {
  setups: PaginatedVehicleSetup[];
  onSort?: (sort: SortingParams) => void;
  currentSort: SortingParams;
}

interface ItemProps {
  setup: PaginatedVehicleSetup;
}

export const SetupsList: React.FC<Props> = ({
  setups,
  onSort,
  currentSort,
}) => {
  const Item = useCallback(({ setup }: ItemProps): JSX.Element => {
    const {
      id,
      weightCapacity,
      loadDuration,
      unloadDuration,
      vehicleType,
      cargoType,
      blockedAt,
    } = setup;

    return (
      <S.ListItem>
        <S.Column>{id}</S.Column>
        <S.Column>{vehicleType.name}</S.Column>
        <S.Column>{cargoType.name}</S.Column>
        <S.Column>{Formatter.weight(weightCapacity)}</S.Column>
        <S.Column>{Formatter.minutesToTime(loadDuration)}</S.Column>
        <S.Column>{Formatter.minutesToTime(unloadDuration)}</S.Column>
        <S.Column>{blockedAt && <S.LockIcon />}</S.Column>
        <S.ActionsColumn>
          <S.LinkActionButton
            to={`/configuracoes/setups-veiculos/${id}/editar`}
          >
            <S.EditIcon />
          </S.LinkActionButton>
        </S.ActionsColumn>
      </S.ListItem>
    );
  }, []);

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={currentSort}>
          <SortableHeader column="id" label="ID" />
          <SortableHeader column="vehicleTypeName" label="VEÍCULO" />
          <SortableHeader column="cargoTypeName" label="TIPO DE CARGA" />
          <SortableHeader column="weightCapacity" label="CAP. DE CARGA" />
          <SortableHeader column="loadDuration" label="CARREGAMENTO" />
          <SortableHeader column="unloadDuration" label="DESCARREGAMENTO" />
          <div></div>
        </SortableHeadersGroup>
      </S.ListHeader>
      {setups.map((setup) => (
        <Item key={setup.id} setup={setup} />
      ))}
    </S.List>
  );
};
