import {
  WarehouseScheduleEntry,
  ScheduleOrder,
  ScheduleReservedInterval,
} from 'contracts/Scheduling';
import { useAuth } from 'hooks';
import React, { useCallback, useMemo } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import SchedulingHelper, { TimeTrackSlot } from './schedulingHelper';
import * as S from './styles';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const PIXELS_PER_HOUR = 128; // multiples of 4 works best
const PADDING_IN_MINUTES = 30; // multiple of 15 works best
const SEGMENTS_PER_HOUR = 12; // one stot for each 5 minutes
const ON_HOVER_SCALE = 1.05; // the same as the scale for the hover
const ON_HOVER_MIN_WIDTH = 256; // the same as the min width for the hover

interface MainTrackProps extends React.PropsWithChildren<{}> {}

interface IntervalProps {
  interval: ScheduleReservedInterval;
}

interface OrderProps {
  order: ScheduleOrder;
}

interface MainProps {
  entry: WarehouseScheduleEntry;
  onSelectOrder: (orderId: number) => void;
}

const ScheduleEntry: React.FC<MainProps> = ({ entry, onSelectOrder }) => {
  const { dock, orders, dockReservedIntervals } = entry;
  const { data: recall } = useSelector((state: RootState) => state.recallSlice);
  

  const { profile } = useAuth();

  const schedulingHelper = useMemo((): SchedulingHelper => {
    return new SchedulingHelper(entry, {
      pixelsPerHour: PIXELS_PER_HOUR,
      paddingInMinutes: PADDING_IN_MINUTES,
      segmentsPerHour: SEGMENTS_PER_HOUR,
      onHoverScale: ON_HOVER_SCALE,
      onHoverMinWidth: ON_HOVER_MIN_WIDTH,
    });
  }, [entry]);

  const Header = useCallback((): JSX.Element => {
    const { paddedStartTime, paddedEndTime } = schedulingHelper.mainMetadata;
    const { startTime, endTime } = schedulingHelper.timeboundaries;
    const { startTime: dockStartTime, endTime: dockEndTime, name, id } = dock;

    return (
      <S.Header>
        <S.DockIcon /> {name}
        {process.env.NODE_ENV === 'development' && (
          <>
            <span>Id: {id}</span>
            <span>
              Real: {dockStartTime}/{dockEndTime}
            </span>
            <span>
              Boundaries: {startTime}/{endTime}
            </span>
            <span>
              Padded: {paddedStartTime}/{paddedEndTime}
            </span>
          </>
        )}
      </S.Header>
    );
  }, [dock, schedulingHelper]);

  const TimeTrack = useCallback((): JSX.Element => {
    const { trackWidth, timeTrackSlots } = schedulingHelper;
    const width = `${trackWidth}px`;

    return (
      <S.TimeTrack style={{ width }}>
        {timeTrackSlots.map((slot, i) => {
          const {
            isFullHour,
            isQuarterHour,
            isOutOfRange,
            time,
            widthInPixels,
          } = slot;

          const showLabel = isFullHour && !isOutOfRange;

          const Marker: JSX.Element = (() => {
            if (isFullHour) {
              return <S.FullHourMarker />;
            }
            if (isQuarterHour) {
              return <S.QuarterHourMarker />;
            }
            return <S.Marker />;
          })();

          const classes: string[] = [];

          const nextSlot: TimeTrackSlot | undefined = timeTrackSlots?.[i + 1];
          // is last in range if is has no next slot or the next slot is out of range
          const isLastInRange =
            !isOutOfRange && (!nextSlot || nextSlot.isOutOfRange);

          if (isLastInRange) classes.push('last-in-range');
          if (isOutOfRange) classes.push('out-of-range');
          classes.push(`t-${time}`);

          const width = `${widthInPixels}px`;

          return (
            <S.TimeSlot key={i} className={classes.join(' ')} style={{ width }}>
              {showLabel && <S.TimeLabel>{time}</S.TimeLabel>}
              {Marker}
            </S.TimeSlot>
          );
        })}
      </S.TimeTrack>
    );
  }, [schedulingHelper]);

  const MainTrack = useCallback(
    (props: MainTrackProps): JSX.Element => {
      const width = `${schedulingHelper.trackWidth}px`;
      return <S.MainTrack style={{ width }}>{props.children}</S.MainTrack>;
    },
    [schedulingHelper.trackWidth]
  );

  const ReservedInterval = useCallback(
    (props: IntervalProps): JSX.Element => {
      const { interval } = props;

      const { containerWidth, leftPosition } =
        schedulingHelper.getIntervalMetadata(interval);

      const width = `${containerWidth}px`;
      const left = `${leftPosition}px`;

      return (
        <S.ReservedInterval style={{ width, left }}>
          <S.BlockIcon />
        </S.ReservedInterval>
      );
    },
    [schedulingHelper]
  );

  const Order = useCallback(
    (props: OrderProps): JSX.Element => {
      const {
        id,
        carrier,
        company,
        vehiclePlate,
        orderType,
        operationType,
        status,
      } = props.order;

      const {
        containerWidth,
        leftPosition,
        hasCutContent,
        overflowingPixelsOnHover,
      } = schedulingHelper.getOrderMetadata(props.order);

      const isOwner = (() => {
        if (profile?.carrier) {
          return profile.carrier.id === carrier.id;
        }
        return true;
      })();

      const width = `${containerWidth}px`;
      const left = `${leftPosition}px`;

      const classes: string[] = [];

      if (!isOwner) classes.push('not-owner');
      if (hasCutContent) classes.push('has-cut-content');

      return (
        <S.Order
          style={{ width, left }}
          negativeX={overflowingPixelsOnHover}
          status={status}
          className={classes.join(' ')}
          onClick={() => {
            isOwner && onSelectOrder(id);
          }}
        >
          <S.OrderContent>
            <S.OrderHeader>
              <S.OrderHeaderIcon>
                {orderType.name === 'Carregamento' ? (
                  <S.UpArrowSquareIcon title="Carregamento" />
                ) : (
                  <S.DownArrowSquareIcon title="Descarregamento" />
                )}
              </S.OrderHeaderIcon>
              <S.OrderHeaderMain>
                <S.CarrierName title="Transportadora">
                  {carrier.tradeName}
                </S.CarrierName>
                {isOwner && (
                  <S.CompanyName title="Cliente">
                    {company.tradeName}
                  </S.CompanyName>
                )}
              </S.OrderHeaderMain>
            </S.OrderHeader>

            <S.OrderFooter>
              {process.env.NODE_ENV === 'development' && (
                <span title="Order id">{id}</span>
              )}
              <S.VehiclePlate title="Placa do veículo">
                {vehiclePlate}
              </S.VehiclePlate>
              <S.OperationName title="Tipo de operação">
                {operationType.name}
              </S.OperationName>
            </S.OrderFooter>
          </S.OrderContent>
        </S.Order>
      );
    },
    [onSelectOrder, profile?.carrier, schedulingHelper]
  );

  return (
    <S.MainContainer recall={recall}>
      <Header />
      <S.ScrollContainerWrapper>
        <ScrollContainer className="scroll-container">
          <TimeTrack />
          <MainTrack>
            {dockReservedIntervals.map((interval, i) => (
              <ReservedInterval key={i} interval={interval} />
            ))}
            {orders.map((order, i) => (
              <Order key={i} order={order} />
            ))}
          </MainTrack>
        </ScrollContainer>
      </S.ScrollContainerWrapper>
    </S.MainContainer>
  );
};

export default ScheduleEntry;
