import styled from 'styled-components';
import { BaseFiltersContainer } from 'styles/components';
export { FormRow } from 'styles/components';

export const Container = styled(BaseFiltersContainer)`
  form {
    > div:nth-child(1) {
      grid-area: warehouse;
      // fot the select to be above the acalendar content
      z-index: 2;
    }
    > div:nth-child(2) {
      grid-area: date;
    }
    > div:nth-child(3) {
      grid-area: status;
    }

    grid-template-columns: 360px 160px auto;
    grid-template-areas: 'warehouse date status';

    @media (max-width: 1800px) {
      grid-template-columns: auto 240px;
      grid-template-areas:
        'warehouse date'
        'status status';
    }
  }
`;
