import styled, { css } from 'styled-components';
import { BasePaginatorListItem } from 'styles/components';
export {
  PaginatorColumn as Column,
  PaginatorActionsColumn as ActionsColumn,
  ActionButton,
  TrashIcon,
  ActivityIndicator,
  Button,
  FormRow,
} from 'styles/components';

export const Container = styled.div`
  margin-top: 32px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const baseGridCss = css`
  grid-template-columns: auto 32px;
`;

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridCss}
`;
