import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler, applyQueryString } from 'services';
import {
  ExportWarehouseMembersActions as Actions,
  ExportWarehouseMembersRequestAction as RequestAction,
} from 'store/ducks/warehouseMembers';

export function* exportWarehouseMembersRequest(action: any) {
  const { query = {}, onSuccess, onFailure }: RequestAction = action;
  try {
    const url = applyQueryString('warehouse-members', {...query, asList: 1});
    const { data } = yield call(api.get, url);
    onSuccess && onSuccess(data);
    yield put(Actions.success(data, data.meta));
  } catch (error) {
    const { errorMessage } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
