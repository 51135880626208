import * as Yup from 'yup';

export class FindVehicleSetupsValidator {
  public get schema() {
    return Yup.object().shape({
      warehouseId: Yup.number()
        .typeError('Selecione um armazém')
        .required('Selecione um armazém'),
      excludeBlocked: Yup.boolean(),
    });
  }
  public logErrors(error: any) {
    if (error instanceof Yup.ValidationError) {
      const validationErrors: Record<string, any> = {};
      error.inner.forEach((er: any) => {
        validationErrors[er.path] = er.message;
      });
    }
  }
}
