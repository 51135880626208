import * as Yup from 'yup';

export class CreateVehicleTypeValidator {
  public get schema() {
    return Yup.object().shape({
      name: Yup.string().required('Informe o nome do tipo de veículo'),
      blockedAt: Yup.string()
        .nullable()
        .transform((value) => {
          if (value === '1') return new Date().toISOString();
          return null;
        }),
    });
  }
}
