import {
  DocksManager,
  WarehouseUpdateForm,
  WarehouseVehicleSetupsManager,
} from 'components/Pages/Warehouses';
import { Scaffold } from 'layouts';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FetchWarehouseActions as FetchActions } from 'store/ducks/warehouses';
import * as S from './styles';
import { RulesManager } from 'components/Pages/Warehouses/Rules';
import { useDispatch } from 'react-redux';

export const WarehouseUpdatePage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onUpdate = useCallback((): void => {
    navigate('/configuracoes/armazens');
  }, [navigate]);

  const fetchWarehouse = useCallback(
    () => id && dispatch(FetchActions.request(id)),
    [dispatch, id]
  );

  useEffect(() => {
    fetchWarehouse();
  }, [fetchWarehouse]);

  return (
    <Scaffold>
      <S.PageContainer>
        <WarehouseUpdateForm onUpdate={onUpdate} />
        <WarehouseVehicleSetupsManager warehouseId={id} />
        <DocksManager warehouseId={id} />
        <RulesManager warehouseId={id} onUpdate={fetchWarehouse} />
      </S.PageContainer>
    </Scaffold>
  );
};
