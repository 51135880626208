import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  CompactSelect,
  CompactInput,
  CompactCheckbox,
} from 'components/Shared';
import { CHECKBOX_OPTIONS } from 'constants/Checkboxes';
import { SelectOption } from 'contracts/Common';
import { OrderStatus } from 'contracts/Orders';
import { FindWarehouseSchedule } from 'contracts/Scheduling';
import { useAuth, useWarehouses } from 'hooks';
import { throttle } from 'lodash';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as S from './styles';

export interface FindOrders extends Partial<FindWarehouseSchedule> {}

interface Props {
  onFilter?: (query: FindOrders) => void;
  currentFilter: FindOrders;
  delay?: number;
}

const TODAY = new Date().toISOString().split('T')[0];

const Filters: React.FC<Props> = ({
  delay = 1000,
  currentFilter,
  onFilter,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { profile } = useAuth();
  const { warehouseOptions, loadingWarehouses, fetchWarehouses } =
    useWarehouses();

  const [filters, setFilters] = useState<FindOrders>({
    ...currentFilter,
    dirty: false,
  });

  const handleWarehouseChange = useCallback(
    (option: SelectOption | null): void => {
      setFilters((state) => ({
        ...state,
        dirty: true,
        warehouseId: option?.value,
      }));
    },
    []
  );

  const handleDateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setFilters((state) => ({
        ...state,
        dirty: true,
        date: value,
      }));
    },
    []
  );

  const handleStatusChange = useCallback((options: string[]): void => {
    setFilters((state) => ({
      ...state,
      dirty: true,
      status: options as OrderStatus[],
    }));
  }, []);

  const setDefaultWarehouse = useCallback(() => {
    const warehouseId =
      profile?.warehouse?.id ??
      currentFilter?.warehouseId ??
      warehouseOptions[0]?.value ??
      null;

    if (!warehouseId) return;

    const warehouse = warehouseOptions.find(
      (option) => option.value === warehouseId
    );

    if (!warehouse) return;

    formRef.current?.setFieldValue('warehouseId', warehouse);
  }, [currentFilter, profile, warehouseOptions]);

  const setDefaultDate = useCallback(() => {
    let date: unknown = null;

    date = currentFilter?.date;

    if (!date) {
      date = TODAY;
    }

    if (!date) return;

    formRef.current?.setFieldValue('date', date);
    // for some reason, setting date is not triggering onChange
    setFilters((state) => ({
      ...state,
      dirty: true,
      date: date as string,
    }));
  }, [currentFilter]);

  const setDefaultStatus = useCallback(() => {
    formRef.current?.setFieldValue('status', currentFilter.status);
  }, [currentFilter.status]);

  // throttled methods

  const onWarehouseChange = useMemo(
    () => throttle(handleWarehouseChange, 0),
    [handleWarehouseChange]
  );

  const onDateChange = useMemo(
    () => throttle(handleDateChange, delay),
    [delay, handleDateChange]
  );

  const onStatusChange = useMemo(
    () => throttle(handleStatusChange, 0),
    [handleStatusChange]
  );

  useEffect(() => {
    fetchWarehouses();
  }, [fetchWarehouses]);

  useEffect(() => {
    setDefaultWarehouse();
  }, [warehouseOptions]);

  useEffect(() => {
    setDefaultDate();
  }, [setDefaultDate]);

  useEffect(() => {
    setDefaultStatus();
  }, [setDefaultStatus]);

  const invokeFilter = useCallback((): void => {
    if (onFilter && filters.dirty) {
      onFilter(filters);
    }
  }, [filters, onFilter]);

  useEffect(() => {
    invokeFilter();
  }, [invokeFilter]);

  return (
    <S.Container>
      <Form ref={formRef} onSubmit={() => {}}>
        <CompactSelect
          name="warehouseId"
          placeholder="Armazém"
          options={warehouseOptions}
          onChange={onWarehouseChange}
          isLoading={loadingWarehouses}
        />
        <CompactInput
          name="date"
          type="date"
          placeholder="Data"
          onChange={onDateChange}
        />
        <CompactCheckbox
          name="status"
          options={CHECKBOX_OPTIONS.ORDER_STATUS}
          onChange={onStatusChange}
        />
      </Form>
    </S.Container>
  );
};

export default Filters;
