import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  DurationInput,
  NumberInput,
  Select,
  ToggleInput,
} from 'components/Shared';
import { FORM_BACK_ACTION } from 'constants/Common';
import { useCargoTypes, useValidation, useVehicleTypes } from 'hooks';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { CreateVehicleSetupActions as CreateActions } from 'store/ducks/vehicleSetups';
import { CreateVehicleSetupValidator } from 'validators/VehicleSetups';
import * as S from './styles';

interface Props {
  onCreate?: () => void;
}

export const SetupCreationForm: React.FC<Props> = ({ onCreate }) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors, handleApiErrors } = useValidation();

  const { vehicleTypeOptions, loadingVehicleTypes, fetchVehicleTypes } =
    useVehicleTypes();

  const { cargoTypeOptions, loadingCargoTypes, fetchCargoTypes } =
    useCargoTypes();

  const { loading: creatingSetup, validationErrors } = useSelector(
    (state: RootState) => state.createVehicleSetup
  );

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
    onCreate && onCreate();
  }, [onCreate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef?.current?.setErrors({});

        const { schema } = new CreateVehicleSetupValidator();

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(CreateActions.request(validData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess]
  );

  useEffect(() => {
    // One should not be able to create a setup with a blocked cargo type
    fetchCargoTypes({ excludeBlocked: false });
  }, [fetchCargoTypes]);

  useEffect(() => {
    // One should not be able to create a setup with a blocked vehicle type
    fetchVehicleTypes({});
  }, [fetchVehicleTypes]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(CreateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.Container>
      <Form ref={formRef} onSubmit={onSubmit}>
        <S.FormRow>
          <Select
            name="vehicleTypeId"
            label="Tipo de veículo"
            options={vehicleTypeOptions}
            isLoading={loadingVehicleTypes}
          />
          <Select
            name="cargoTypeId"
            label="Tipo de carga"
            options={cargoTypeOptions}
            isLoading={loadingCargoTypes}
          />
          <NumberInput
            name="weightCapacity"
            label="Capacidade de carga (Kg)"
            suffix=" Kg"
          />
        </S.FormRow>
        <S.FormRow>
          <DurationInput name="loadDuration" label="Tempo de carregamento" />
          <DurationInput
            name="unloadDuration"
            label="Tempo de descarregamento"
          />
          <ToggleInput name="blockedAt" label="Bloqueado" />
        </S.FormRow>
        <S.FormActions>
          <S.LinkButton mood="light" to="/configuracoes/setups-veiculos">
            {FORM_BACK_ACTION}
          </S.LinkButton>
          <S.Button type="submit">
            {creatingSetup ? <S.ActivityIndicator /> : 'Salvar'}
          </S.Button>
        </S.FormActions>
      </Form>
    </S.Container>
  );
};
