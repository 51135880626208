import { SelectOption } from 'contracts/Common';
import { FindSchedulingVehicleSetups } from 'contracts/Scheduling';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { FindVehicleSetupsValidator } from 'validators/Scheduling';
import { ListSchedulingVehicleSetupsActions as MainActions } from 'store/ducks/scheduling';

export const useSchedulingVehicleSetups = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    data: schedulingVehicleSetups,
    loading: loadingSchedulingVehicleSetups,
  } = useSelector((state: RootState) => state.listSchedulingVehicleSetups);

  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchSchedulingVehicleSetups = useCallback(
    async (query: Partial<FindSchedulingVehicleSetups>): Promise<void> => {
      const { schema, logErrors } = new FindVehicleSetupsValidator();
      try {
        const validQuery = await schema.validate(query, { abortEarly: false });
        dispatch(MainActions.request(validQuery));
      } catch (error) {
        dispatch(MainActions.reset());
        logErrors(error);
      }
    },
    [dispatch]
  );

  const onVehicleSetupsLoad = useCallback((): void => {
    setOptions(
      schedulingVehicleSetups.map(({ vehicleType, cargoType, blockedAt }) => ({
        value: `${vehicleType.id}-${cargoType.id}`,
        label: `${vehicleType.name} (${cargoType.name})`,
        isDisabled: blockedAt !== null,
      }))
    );
  }, [schedulingVehicleSetups]);

  useEffect(() => {
    onVehicleSetupsLoad();
  }, [onVehicleSetupsLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    schedulingVehicleSetups,
    schedulingVehicleSetupsOptions: options,
    loadingSchedulingVehicleSetups,
    fetchSchedulingVehicleSetups,
  };
};

export type SchedulingVehicleSetupsHook = ReturnType<
  typeof useSchedulingVehicleSetups
>;
