import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { CompactSearch, CompactSelect } from 'components/Shared';
import { SelectOption, FindMany } from 'contracts/Common';
import { useCargoTypes, useVehicleTypes } from 'hooks';
import { throttle } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as S from './styles';

export interface FindSetups extends FindMany {
  vehicleTypeId?: unknown;
  cargoTypeId?: unknown;
}

interface Props {
  onFilter?: (query: FindSetups) => void;
  currentFilter: FindSetups;
  delay?: number;
}

export const SetupFilters: React.FC<Props> = ({
  delay = 1000,
  currentFilter,
  onFilter,
}) => {
  const formRef = useRef<FormHandles>(null);

  const { vehicleTypeOptions, loadingVehicleTypes, fetchVehicleTypes } =
    useVehicleTypes();
  const { cargoTypeOptions, loadingCargoTypes, fetchCargoTypes } =
    useCargoTypes();

  const [filters, setFilters] = useState<FindSetups>({
    ...currentFilter,
    dirty: false,
  });

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setFilters((state) => ({
        ...state,
        dirty: true,
        search: e.target.value,
      }));
    },
    []
  );

  const handleVehicleTypeChange = useCallback(
    (option: SelectOption | null): void => {
      setFilters((state) => ({
        ...state,
        dirty: true,
        vehicleTypeId: option?.value,
      }));
    },
    []
  );

  const handleCargoTypeChange = useCallback(
    (option: SelectOption | null): void => {
      setFilters((state) => ({
        ...state,
        dirty: true,
        cargoTypeId: option?.value,
      }));
    },
    []
  );

  const setDefaultVehicleType = useCallback((): void => {
    if (!currentFilter?.vehicleTypeId) return;
    const vehicleType = vehicleTypeOptions.find(
      (option) => option.value === currentFilter.vehicleTypeId
    );
    if (!vehicleType) return;
    formRef.current?.setFieldValue('vehicleTypeId', vehicleType);
  }, [currentFilter.vehicleTypeId, vehicleTypeOptions]);

  const setDefaultCargoType = useCallback((): void => {
    if (!currentFilter?.cargoTypeId) return;
    const cargoType = cargoTypeOptions.find(
      (option) => option.value === currentFilter.cargoTypeId
    );
    if (!cargoType) return;
    formRef.current?.setFieldValue('cargoTypeId', cargoType);
  }, [currentFilter.cargoTypeId, cargoTypeOptions]);

  // throttled methods

  const onSearch = useMemo(
    () => throttle(handleSearch, delay),
    [delay, handleSearch]
  );

  const onVehicleTypeChange = useMemo(
    () => throttle(handleVehicleTypeChange, delay),
    [delay, handleVehicleTypeChange]
  );

  const onCargoTypeChange = useMemo(
    () => throttle(handleCargoTypeChange, delay),
    [delay, handleCargoTypeChange]
  );

  const invokeOnFilter = useCallback((): void => {
    if (filters.dirty && onFilter) {
      onFilter(filters);
    }
  }, [filters, onFilter]);

  useEffect(() => {
    fetchVehicleTypes({});
  }, [fetchVehicleTypes]);

  useEffect(() => {
    fetchCargoTypes({});
  }, [fetchCargoTypes]);

  useEffect(() => {
    setDefaultVehicleType();
  }, [setDefaultVehicleType]);

  useEffect(() => {
    setDefaultCargoType();
  }, [setDefaultCargoType]);

  useEffect(() => {
    invokeOnFilter();
  }, [invokeOnFilter]);

  return (
    <S.Container>
      <Form ref={formRef} onSubmit={() => {}}>
        <CompactSearch
          onChange={onSearch}
          defaultValue={filters?.search}
          placeholder="Buscar"
          name="search"
        />
        <CompactSelect
          name="vehicleTypeId"
          placeholder="Tipo de veículo"
          options={vehicleTypeOptions}
          isLoading={loadingVehicleTypes}
          onChange={onVehicleTypeChange}
        />
        <CompactSelect
          name="cargoTypeId"
          placeholder="Tipo de carga"
          options={cargoTypeOptions}
          isLoading={loadingCargoTypes}
          onChange={onCargoTypeChange}
        />
      </Form>
    </S.Container>
  );
};
