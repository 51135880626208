import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler } from 'services';
import {
  UpdateOrderEventsActions as Actions,
  UpdateOrderEventsRequestAction as RequestAction,
} from 'store/ducks/orders';

export function* updateOrderEventsRequest(action: any) {
  const { id, putData, onSuccess, onFailure }: RequestAction = action;
  try {
    yield call(api.put, `orders/${id}/events`, putData);
    onSuccess && onSuccess();
    notify('success', 'Apontamento atualizado com sucesso');
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
