import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler, applyQueryString } from 'services';
import {
  ListSchedulingVehicleSetupsActions as Actions,
  ListSchedulingVehicleSetupsRequestAction as RequestAction,
} from 'store/ducks/scheduling';

export function* listSchedulingVehicleSetupsRequest(action: any) {
  const { query, onSuccess, onFailure }: RequestAction = action;
  try {
    const url = applyQueryString('scheduling/vehicle-setups', query);
    const { data } = yield call(api.get, url);
    onSuccess && onSuccess();
    yield put(Actions.success(data));
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
