import React, { useCallback, useEffect, useRef } from 'react';
import * as S from './styles';
import * as Tabs from 'components/Shared/Tabs';
import { FormPageHeader } from 'components/Shared';
import { useCompanies, useWarehouses } from 'hooks';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

import { PaginateCompanyWarehouseAccessesActions as PaginateWarehouseAccess } from 'store/ducks/companyWarehouseAccesses';
import { PaginateCompanyMemberAccessesActions as PaginateCompanyAccess } from 'store/ducks/companyMemberAccesses';

import WarehousesManager, {
  Ref as WarehouseManagerRef,
} from './WarehousesManager';
import CompaniesManager, {
  Ref as CompaniesManagerRef,
} from './CompaniesManager';
import { useDispatch } from 'react-redux';

interface Props {
  onUpdate: () => void;
  companyMemberId: string;
}

export const RulesManager: React.FC<Props> = (props) => {
  const companiesManagerRef = useRef<CompaniesManagerRef>(null);
  const warehouseManagerRef = useRef<WarehouseManagerRef>(null);
  const tabsOutletRef = useRef<Tabs.Ref>(null);
  const dispatch = useDispatch();

  const { loading } = useSelector((state: RootState) => state.fetchWarehouse);

  const { fetchCompanies } = useCompanies();
  const { fetchWarehouses } = useWarehouses();

  const fetchWarehouseAccesses = useCallback(() => {
    dispatch(
      PaginateWarehouseAccess.request({ companyMemberId: props.companyMemberId })
    );
  }, [dispatch, props]);

  const fetchCompaniesAccess = useCallback(() => {
    dispatch(
      PaginateCompanyAccess.request({ companyMemberId: props.companyMemberId })
    );
  }, [dispatch, props]);

  // We will dispatch this action here on top level
  // to avoid dispatching multiple times down the tree

  const fetchLists = useCallback((): void => {
    fetchCompanies();
    fetchWarehouses();
    fetchWarehouseAccesses();
    fetchCompaniesAccess();
  }, [fetchCompanies, fetchWarehouses, fetchWarehouseAccesses]);

  useEffect(() => {
    fetchLists();
  }, [fetchLists]);

  return (
    <S.MainPanel>
      <FormPageHeader
        title="Permissões"
        icon={<S.BookContentIcon />}
        isLoading={loading}
        removeSpacing
        showBorder={false}
      />
      <S.TabedContainer>
        <Tabs.Controller
          onTabChange={(i) => tabsOutletRef?.current?.selectTab(i)}
        >
          <Tabs.ModalTab title="Clientes" />
          <Tabs.ModalTab title="Armazéns" />
        </Tabs.Controller>
        <Tabs.Outlet ref={tabsOutletRef}>
          <CompaniesManager ref={companiesManagerRef} {...props} />
          <WarehousesManager ref={warehouseManagerRef} {...props} />
        </Tabs.Outlet>
      </S.TabedContainer>
    </S.MainPanel>
  );
};
