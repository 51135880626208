import * as Yup from 'yup';
import { getVehicleSetupShape } from 'validators/Common/baseVehicleSetupValidator';

const { weightCapacity, loadDuration, unloadDuration } = getVehicleSetupShape();

export const getWarehouseVehicleSetupShape = () => ({
  weightCapacity,
  loadDuration,
  unloadDuration,
  warehouseId: Yup.number()
    .typeError('Informe o id do armazém')
    .required('Informe o id do armazém'),
  vehicleSetupId: Yup.number()
    .typeError('Selecione o setup de veículo')
    .required('Selecione o setup de veículo'),
});
