import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  HiddenInput,
  Input,
  MaskedInput,
  Select,
  FormPageHeader,
} from 'components/Shared';
import { FORM_BACK_ACTION, SELECT_OPTIONS } from 'constants/Common';
import type { CompanyDocumentType, SelectOption } from 'contracts/Common';
import { useAddressLookup, useCountries, useValidation } from 'hooks';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { CreateWarehouseActions as CreateActions } from 'store/ducks/warehouses';
import { CreateWarehouseValidator } from 'validators/Warehouses';
import * as S from './styles';

interface Props {
  onCreate?: () => void;
}

export const WarehouseCreationForm: React.FC<Props> = ({ onCreate }) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors, handleApiErrors } = useValidation();
  const { onZipcodeChange, fetchingAddress } = useAddressLookup(formRef);
  const { countryOptions, loadingCountries, fetchCountries } = useCountries();

  const [selectedType, setSelectedType] = useState<CompanyDocumentType>('cnpj');
  const [selectedCountryId, setSelectedCountryId] = useState<number>(1);

  const { loading: creatingWarehouse, validationErrors } = useSelector(
    (state: RootState) => state.createWarehouse
  );

  const documentLabel = useMemo(() => {
    if (selectedType === 'cnpj') return 'CNPJ';
    if (selectedType === 'cpf') return 'CPF';
    if (selectedType === 'other') return 'Documento';
  }, [selectedType]);

  const documentMask = useMemo(() => {
    if (selectedType === 'cnpj') return '99.999.999/9999-99';
    if (selectedType === 'cpf') return '999.999.999-99';
    return [];
  }, [selectedType]);

  const onTypeChange = useCallback((option: SelectOption | null): void => {
    if (!option) return;
    setSelectedType(option.value as CompanyDocumentType);
  }, []);

  const onCountryChange = useCallback((option: SelectOption | null): void => {
    if (!option) return;
    setSelectedCountryId(option.value as number);
  }, []);

  const onUfChange = useCallback((option: SelectOption | null): void => {
    if (!option) {
      formRef.current?.setFieldValue('addressState', '');
      return;
    }
    formRef.current?.setFieldValue('addressState', option.label);
  }, []);

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
    onCreate && onCreate();
  }, [onCreate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef?.current?.setErrors({});

        const { schema } = new CreateWarehouseValidator({
          selectedType,
          selectedCountryId,
        });

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(CreateActions.request(validData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess, selectedCountryId, selectedType]
  );

  const Header = useCallback((): JSX.Element => {
    return (
      <FormPageHeader
        title="Novo armazém"
        icon={<S.WarehouseIcon />}
        actions={
          <S.LinkButton size="small" to="/configuracoes/armazens">
            <S.ArrowLeftIcon /> Voltar
          </S.LinkButton>
        }
      />
    );
  }, []);

  useEffect(() => {
    fetchCountries({ excludeBlocked: true });
  }, [fetchCountries]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(CreateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.MainPanel>
      <Header />
      <Form ref={formRef} onSubmit={onSubmit}>
        <HiddenInput name="ibge" readOnly />
        <S.FormRow>
          <Select
            name="documentType"
            label="Tipo de documento"
            options={SELECT_OPTIONS.DOCUMENT_TYPES}
            defaultValue={SELECT_OPTIONS.DOCUMENT_TYPES[0]}
            onChange={onTypeChange}
          />
          <Input
            name="tradeName"
            label={selectedType === 'cnpj' ? 'Nome fantasia' : 'Nome completo'}
          />
          <>
            {(['cnpj', 'cpf'].includes(selectedType as string) && (
              <MaskedInput
                name="document"
                label={documentLabel}
                mask={documentMask}
              />
            )) || <Input name="document" label={documentLabel} />}
          </>
        </S.FormRow>

        <S.FormRow>
          <S.FormRow>
            {!!countryOptions.length && (
              <Select
                name="countryId"
                label="País"
                options={countryOptions}
                isLoading={loadingCountries}
                onChange={onCountryChange}
                defaultValue={countryOptions.find((o) => o.value === 1)}
              />
            )}
            <Input
              name="addressZipcode"
              label="Código postal"
              onChange={onZipcodeChange}
              isLoading={fetchingAddress}
            />
          </S.FormRow>
          <Input name="addressStreet" label="Logradouro" />
          <Input name="addressNumber" label="Número" />
        </S.FormRow>

        <S.FormRow>
          <Input name="addressComplement" label="Complemento" />
          <Input name="addressNeighborhood" label="Bairro" />
          <Input name="addressCity" label="Cidade" />
        </S.FormRow>

        <S.FormRow>
          {(selectedCountryId === 1 && (
            <>
              <HiddenInput name="addressState" readOnly />
              <Select
                name="addressUf"
                label="Estado"
                options={SELECT_OPTIONS.STATES}
                onChange={onUfChange}
              />
            </>
          )) || <Input name="addressState" label="Estado" />}
          <Input name="addressLatitude" label="Latitude" />
          <Input name="addressLongitude" label="Longitude" />
        </S.FormRow>

        <S.FormActions>
          <S.LinkButton mood="light" to="/configuracoes/armazens">
            {FORM_BACK_ACTION}
          </S.LinkButton>
          <S.Button type="submit">
            {creatingWarehouse ? <S.ActivityIndicator /> : 'Salvar'}
          </S.Button>
        </S.FormActions>
      </Form>
    </S.MainPanel>
  );
};
