import React, { useCallback, useRef } from 'react';
import { ConfirmationDialog, ConfirmationDialogRef } from 'components/Shared';
import type { PaginatedWarehouseMemberAccesses } from 'contracts/WarehouseMemberAccesses';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { DeleteWarehouseMemberAccessActions as DeleteActions } from 'store/ducks/warehouseMemberAccesses';
import * as S from './styles';

interface Props {
  warehouseMemberAccesses: PaginatedWarehouseMemberAccesses[];
  onDelete?: () => void;
}

interface ItemProps {
  warehouseMemberAccess: PaginatedWarehouseMemberAccesses;
}

const WarehouseMemberAccessList: React.FC<Props> = ({
  warehouseMemberAccesses,
  onDelete,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const dialogRef = useRef<ConfirmationDialogRef>(null);

  const { id: deletingId } = useSelector(
    (state: RootState) => state.deleteWarehouseMemberAccess
  );

  const handleDelete = useCallback(
    async (id: number): Promise<void> => {
      const confirmed = await dialogRef.current?.openDialog({
        title: 'Deseja remover o vínculo com este armazérm?',
        message: 'Esta ação não poderá ser desfeita após confirmada.',
        confirmButtonMood: 'outlinedDanger',
      });
      if (confirmed) {
        dispatch(DeleteActions.request(id, onDelete));
      }
    },
    [dispatch, onDelete]
  );
  const Item = useCallback(
    ({ warehouseMemberAccess }: ItemProps): JSX.Element => {
      const { id, warehouse } = warehouseMemberAccess;

      return (
        <S.ListItem>
          <ConfirmationDialog ref={dialogRef} />
          <S.Column>{id}</S.Column>
          <S.Column>{warehouse.tradeName}</S.Column>
          <S.ActionsColumn>
            <S.ActionButton mood="danger" onClick={() => handleDelete(id)}>
              {deletingId === id ? <S.ActivityIndicator /> : <S.TrashIcon />}
            </S.ActionButton>
          </S.ActionsColumn>
        </S.ListItem>
      );
    },
    [deletingId, handleDelete]
  );

  if (!warehouseMemberAccesses.length) {
    return (
      <S.EmptyListPlaceholder>
        Este usuário não está associado a nenhum outro armazém.
      </S.EmptyListPlaceholder>
    );
  }

  return (
    <S.List>
      <S.ListHeader>
        <div>ID</div>
        <div>ARMAZÉM</div>
        <div></div>
      </S.ListHeader>
      {warehouseMemberAccesses.map((warehouseMemberAccess) => (
        <Item
          key={warehouseMemberAccess.id}
          warehouseMemberAccess={warehouseMemberAccess}
        />
      ))}
    </S.List>
  );
};

export default WarehouseMemberAccessList;
