import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from 'hooks';
import { LogoutActions } from 'store/ducks/auth';
import { ConfirmationDialog, ConfirmationDialogRef } from 'components/Shared';
import * as S from './styles';

const TopPanel: React.FC = () => {
  const dialogRef = useRef<ConfirmationDialogRef>(null);
  const { profile } = useAuth();

  const dispatch = useDispatch();

  const handleLogout = useCallback(async (): Promise<void> => {
    const confirmed = await dialogRef.current?.openDialog({
      title: 'Deseja sair?',
    });
    if (confirmed) {
      dispatch(LogoutActions.request());
    }
  }, [dispatch]);

  const Domain = useCallback((): JSX.Element => {
    switch (profile?.type) {
      case 'admin':
        return (
          <S.Domain>
            <S.AdminIcon /> Administrador
          </S.Domain>
        );
      case 'companyMember':
        return (
          <S.Domain>
            <S.CompanyIcon /> {profile?.company?.tradeName}
          </S.Domain>
        );
      case 'carrierMember':
        return (
          <S.Domain>
            <S.CompanyIcon /> {profile?.carrier?.tradeName}
          </S.Domain>
        );
      case 'warehouseMember':
        return (
          <S.Domain>
            <S.WarehouseIcon /> {profile?.warehouse?.tradeName}
          </S.Domain>
        );
      default:
        return <></>;
    }
  }, [profile]);

  if (!profile) return null;

  return (
    <S.TopPanel>
      <ConfirmationDialog ref={dialogRef} />
      <Domain />
      <S.Actions>
        <S.User>{profile?.name}</S.User>
        <S.LogoutButton onClick={handleLogout}>
          <S.LogoutIcon />
        </S.LogoutButton>
      </S.Actions>
    </S.TopPanel>
  );
};

export default TopPanel;
