import * as Yup from 'yup';

export class UpdateOrderCancelationReasonValidator {
  public get schema() {
    return Yup.object().shape({
      cancelationReasonId: Yup.number()
        .typeError('Selecione uma justificativa')
        .required('Selecione uma justificativa'),
    });
  }
}
