import * as Yup from 'yup';
import { getDockShape } from 'validators/Common/baseDockValidator';

export class UpdateDockValidator {
  public get schema() {
    return Yup.object().shape({
      ...getDockShape(),
      blockedAt: Yup.string()
        .nullable()
        .transform((value) => {
          if (value === '1') return new Date().toISOString();
          return null;
        }),
    });
  }
}
