import * as Yup from 'yup';
import { getUserShape } from 'validators/Common/baseUserValidator';

export class UpdateMemberValidator {
  public get schema() {
    return Yup.object().shape({
      userId: Yup.number(),
      warehouseId: Yup.number()
        .typeError('Selecione o armazém')
        .required('Selecione o armazém'),
      user: Yup.object().shape({
        ...getUserShape(),
        password: Yup.string().optional(),
      }),
    });
  }
}
