import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BaseButtonStyle } from 'styles/components';
import { ColorScheme, Fonts } from 'styles/constants';
export {
  ActivityIndicator,
  Button,
  FormActions,
  FormRow,
} from 'styles/components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  gap: 12px;
`

export const ForgotMyPwsLink = styled(Link)`
  ${BaseButtonStyle};
  margin-left: auto;
  font-family: ${Fonts.GilroySemiBold};
  color: ${ColorScheme.LightText};
  font-size: 14px;
`;
