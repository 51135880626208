import XLSX from 'sheetjs-style-v2';
import * as FileSaver from 'file-saver';

export const exportXLSX = <T>(data: T[], fileName: string) => {

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet: charset=UTF8';
  const fileExtension = '.xlsx';
  
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const file = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(file, fileName + fileExtension);
}