import { SelectOption } from 'contracts/Common';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { ListCargoTypesActions as MainActions } from 'store/ducks/cargoTypes';

interface FindCargoTypes {
  excludeBlocked?: boolean;
}

export const useCargoTypes = () => {
  const dispatch: AppDispatch = useDispatch();

  const { data: cargoTypes, loading: loadingCargoTypes } = useSelector(
    (state: RootState) => state.listCargoTypes
  );

  const [ignoredBlockedId, enableCargpTypeIfBlocked] = useState<number | null>(
    null
  );

  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchCargoTypes = useCallback(
    (query?: FindCargoTypes) => {
      dispatch(MainActions.request({ ...query }));
    },
    [dispatch]
  );

  const onCargoTypesLoad = useCallback((): void => {
    setOptions(
      cargoTypes.map(({ id, name, blockedAt }) => ({
        value: id,
        label: name,
        isDisabled: ignoredBlockedId !== id && blockedAt !== null,
      }))
    );
  }, [cargoTypes, ignoredBlockedId]);

  useEffect(() => {
    onCargoTypesLoad();
  }, [onCargoTypesLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    cargoTypes,
    cargoTypeOptions: options,
    loadingCargoTypes,
    fetchCargoTypes,
    enableCargpTypeIfBlocked,
  };
};

export type CargoTypesHook = ReturnType<typeof useCargoTypes>;
