import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormPageHeader, Paginator } from 'components/Shared';
import type { AppDispatch, RootState } from 'store';
import { PaginateWarehouseMemberAccessesActions as PaginateActions } from 'store/ducks/warehouseMemberAccesses';
import WarehouseMemberAccessCreationModal, {
  Ref as CreationModalRef,
} from './CreationModal';
import WarehouseMemberAccessList from './WarehouseMemberAccessList';
import * as S from './styles';

interface Props {
  warehouseMemberId: number | string;
}

export const WarehouseAccessManager: React.FC<Props> = ({
  warehouseMemberId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const warehouseMemberAccessCreationModalRef = useRef<CreationModalRef>(null);

  const {
    data: warehouseMemberAccesses,
    pagination,
    loading: loadingWarehouseMemberAccesses,
  } = useSelector((state: RootState) => state.paginateWarehouseMemberAccesses);

  const [query, setQuery] = useState({
    search: '',
    page: 1,
    limit: 99,
    warehouseMemberId,
    orderBy: 'id',
    direction: 'asc',
  });

  // We will dispatch this action here on top level
  // to avoid dispatching multiple times down the tree
  const fetchLists = useCallback((): void => {}, []);

  const onPageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const onQueryChange = useCallback((): void => {
    dispatch(PaginateActions.request(query));
  }, [dispatch, query]);

  useEffect(() => {
    fetchLists();
  }, [fetchLists]);

  useEffect(() => {
    onQueryChange();
  }, [onQueryChange]);

  useEffect(() => {
    return () => {
      dispatch(PaginateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.MainPanel>
      <FormPageHeader
        title="Acesso a outros armazéns"
        icon={<S.WarehouseIcon />}
        isLoading={loadingWarehouseMemberAccesses}
        actions={
          <S.Button
            size="small"
            onClick={warehouseMemberAccessCreationModalRef?.current?.openModal}
          >
            <S.PlusIcon /> Novo armazém
          </S.Button>
        }
      />
      <WarehouseMemberAccessCreationModal
        ref={warehouseMemberAccessCreationModalRef}
        warehouseMemberId={warehouseMemberId}
        onCreate={onQueryChange}
      />
      <WarehouseMemberAccessList
        warehouseMemberAccesses={warehouseMemberAccesses}
        onDelete={onQueryChange}
      />
      <Paginator onPageChange={onPageChange} pagination={pagination} />
    </S.MainPanel>
  );
};
