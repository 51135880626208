import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  CompactInput,
  ConfirmationDialog,
  ConfirmationDialogRef,
} from 'components/Shared';
import { Order } from 'contracts/Orders';
import { parseISO } from 'date-fns';
import { useAuth, useValidation } from 'hooks';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { UpdateOrderEventsActions as MainActions } from 'store/ducks/orders';
import { Formatter } from 'utils';
import { UpdateOrderEventsValidator } from 'validators/Orders';
import * as S from './styles';

interface Props {
  order: Order;
  onUpdate?: () => void;
}

type FieldName =
  | 'vehicleArrivedAt'
  | 'startedAt'
  | 'finishedAt'
  | 'vehicleDepartedAt';

interface FormField {
  label: string;
  name: FieldName;
}

const fields: FormField[] = [
  {
    label: 'Chegada do veículo',
    name: 'vehicleArrivedAt',
  },
  {
    label: 'Ordem iniciada',
    name: 'startedAt',
  },
  {
    label: 'Ordem finalizada',
    name: 'finishedAt',
  },
  {
    label: 'Saída do veículo',
    name: 'vehicleDepartedAt',
  },
];

const EventsManager: React.FC<Props> = ({ order, onUpdate }) => {
  const formRef = useRef<FormHandles>(null);
  const dialogRef = useRef<ConfirmationDialogRef>(null);

  const { handleFormErrors, handleApiErrors } = useValidation();
  const { userBelongsToAnyOf } = useAuth();

  const dispatch: AppDispatch = useDispatch();

  const { loading: updatingOrder, validationErrors } = useSelector(
    (state: RootState) => state.updateOrderEvents
  );

  const setExistingValues = useCallback((): void => {
    const { vehicleArrivedAt, startedAt, finishedAt, vehicleDepartedAt } =
      order;

    Object.entries({
      vehicleArrivedAt,
      startedAt,
      finishedAt,
      vehicleDepartedAt,
    }).forEach(([field, value]) => {
      if (!value) return;
      formRef.current?.setFieldValue(
        field,
        Formatter.date(value, { format: 'HH:mm' })
      );
    });
  }, [order]);

  const getDatetimeLabel = useCallback(
    (event: string | null): string => {
      if (!event) {
        return Formatter.date(order.scheduledAt, {
          format: 'dd MMM yyyy, __:__',
        });
      }

      return Formatter.date(event, { format: 'dd MMM yyyy, HH:mm' });
    },
    [order.scheduledAt]
  );

  const getDatetimeValueFromTime = useCallback(
    (time: string): string => {
      const [hour, minute] = time.split(':').map(Number);
      const date = parseISO(order.scheduledAt);
      date.setHours(hour);
      date.setMinutes(minute);
      return date.toISOString();
    },
    [order.scheduledAt]
  );

  const isLockedField = useCallback(
    (field: FieldName): boolean => {
      if (order.noShowAt) return true;
      if (order.cancelationReason) return true;
      if (userBelongsToAnyOf('admin')) return false;

      switch (field) {
        case 'vehicleArrivedAt':
          return order.vehicleArrivedAt !== null;
        case 'startedAt':
          return order.startedAt !== null || order.vehicleArrivedAt === null;
        case 'finishedAt':
          return order.finishedAt !== null || order.startedAt === null;
        case 'vehicleDepartedAt':
          return order.vehicleDepartedAt !== null || order.finishedAt === null;
        default:
          return true;
      }
    },
    [order, userBelongsToAnyOf]
  );

  const onSuccess = useCallback((): void => {
    onUpdate && onUpdate();
  }, [onUpdate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef.current?.setErrors({});

        const { schema, schemaAdmin } = new UpdateOrderEventsValidator({
          order,
        });

        const validData = userBelongsToAnyOf('admin')
          ? ((await schemaAdmin.validate(data, {
              abortEarly: false,
            })) as Record<string, any>)
          : ((await schema.validate(data, {
              abortEarly: false,
            })) as Record<string, any>);

        Object.entries(validData).forEach(([field, value]) => {
          if (!value) {
            validData[field] = null;
            return;
          }
          validData[field] = getDatetimeValueFromTime(value);
        });

        const confirmed = await dialogRef.current?.openDialog({
          title: 'Confirmar apontamento?',
          message: 'Esta ação não poderá ser desfeita.',
          confirmButtonText: 'Confirmar',
        });

        if (confirmed) {
          dispatch(MainActions.request(order.id, validData, onSuccess));
        }
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, getDatetimeValueFromTime, handleFormErrors, onSuccess, order, userBelongsToAnyOf]
  );

  useEffect(() => {
    setExistingValues();
  }, [setExistingValues]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  return (
    <S.MainContainer>
      <ConfirmationDialog ref={dialogRef} />
      <Form ref={formRef} onSubmit={onSubmit}>
        {fields.map(({ label, name }) => {
          const isLocked = isLockedField(name);
          return (
            <S.FormRow key={name} onSubmit={onSubmit}>
              <S.Label>{label}</S.Label>
              <S.Label>{getDatetimeLabel(order[name])}</S.Label>
              {userBelongsToAnyOf('admin', 'warehouseMember') && (
                <S.Controls disabled={isLocked}>
                  <CompactInput type="time" name={name} readOnly={isLocked} />
                  <S.Button type={isLocked ? 'button' : 'submit'} size="small">
                    {!isLocked && updatingOrder ? (
                      <S.ActivityIndicator />
                    ) : (
                      'Marcar'
                    )}
                  </S.Button>
                </S.Controls>
              )}
            </S.FormRow>
          );
        })}
      </Form>
    </S.MainContainer>
  );
};

export default EventsManager;
