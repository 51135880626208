import styled, { css } from 'styled-components';
import { BasePaginatorHeader, BasePaginatorListItem } from 'styles/components';
export {
  PaginatorColumn as Column,
  PaginatorActionsColumn as ActionsColumn,
} from 'styles/components';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

const baseGridCss = css`
  grid-template-columns: 200px 140px auto;
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridCss}
`;

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridCss}
`;
