import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  HiddenInput,
  Input,
  MaskedInput,
  Select,
  FormPageHeader,
  LogoCropper,
} from 'components/Shared';
import { FORM_BACK_ACTION, SELECT_OPTIONS } from 'constants/Common';
import type { CompanyDocumentType, SelectOption } from 'contracts/Common';
import type { Company } from 'contracts/Companies';
import { useAddressLookup, useCountries, useValidation } from 'hooks';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import {
  FetchCompanyActions as FetchActions,
  UpdateCompanyActions as UpdateActions,
} from 'store/ducks/companies';
import { Formatter } from 'utils';
import { UpdateCompanyValidator } from 'validators/Companies';
import * as S from './styles';

interface Props {
  companyId: string | number;
  onUpdate?: () => void;
}

export const CompanyUpdateForm: React.FC<Props> = ({ companyId, onUpdate }) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors, handleApiErrors } = useValidation();
  const { onZipcodeChange, fetchingAddress } = useAddressLookup(formRef);
  const { countryOptions, loadingCountries, fetchCountries } = useCountries();

  const [selectedType, setSelectedType] = useState<CompanyDocumentType>('cnpj');
  const [selectedCountryId, setSelectedCountryId] = useState<number>(1);
  const [logoFile, setLogoFile] = useState<File | undefined>();

  const { data: company, loading: fetchingCompany } = useSelector(
    (state: RootState) => state.fetchCompany
  );

  const { loading: updatingCompany, validationErrors } = useSelector(
    (state: RootState) => state.updateCompany
  );

  const documentLabel = useMemo(() => {
    if (selectedType === 'cnpj') return 'CNPJ';
    if (selectedType === 'cpf') return 'CPF';
    if (selectedType === 'other') return 'Documento';
  }, [selectedType]);

  const documentMask = useMemo(() => {
    if (selectedType === 'cnpj') return '99.999.999/9999-99';
    if (selectedType === 'cpf') return '999.999.999-99';
    return [];
  }, [selectedType]);

  const onCompanyLoad = useCallback((): void => {
    if (!company) return;
    const { documentType, document, countryId, addressUf } = company;

    const autoSet: Array<keyof Company> = [
      'tradeName',
      'ibge',
      'addressStreet',
      'addressNumber',
      'addressComplement',
      'addressNeighborhood',
      'addressCity',
      'addressState',
      'addressZipcode',
      'addressLatitude',
      'addressLongitude',
    ];

    for (const [field, value] of Object.entries(company)) {
      if (autoSet.includes(field as keyof Company)) {
        formRef.current?.setFieldValue(field, value);
      }
    }

    // manually set fields

    const typeOption = SELECT_OPTIONS.DOCUMENT_TYPES.find(
      (o) => o.value === documentType
    );

    if (typeOption) {
      formRef.current?.setFieldValue('documentType', typeOption);
    }

    setSelectedType(documentType);
    formRef.current?.setFieldValue(
      'document',
      Formatter.document(document, documentType)
    );
    const countryOption = countryOptions.find((o) => o.value === countryId);

    setSelectedCountryId(countryId);
    if (countryOption) {
      formRef.current?.setFieldValue('countryId', countryOption);
    }

    const ufOption = SELECT_OPTIONS.STATES.find((o) => o.value === addressUf);

    if (ufOption) {
      formRef.current?.setFieldValue('addressUf', ufOption);
    }
  }, [company, countryOptions]);

  const onTypeChange = useCallback((option: SelectOption | null): void => {
    if (!option) return;
    setSelectedType(option.value as CompanyDocumentType);
  }, []);

  const onCountryChange = useCallback((option: SelectOption | null): void => {
    if (!option) return;
    setSelectedCountryId(option.value as number);
  }, []);

  const onUfChange = useCallback((option: SelectOption | null): void => {
    if (!option) {
      formRef.current?.setFieldValue('addressState', '');
      return;
    }
    formRef.current?.setFieldValue('addressState', option.label);
  }, []);

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
    onUpdate && onUpdate();
  }, [onUpdate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef?.current?.setErrors({});
        Object.assign(data, { logoFile });

        const { schema } = new UpdateCompanyValidator({
          selectedType,
          selectedCountryId,
        });

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(UpdateActions.request(companyId, validData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [
      companyId,
      dispatch,
      handleFormErrors,
      logoFile,
      onSuccess,
      selectedCountryId,
      selectedType,
    ]
  );

  const Header = useCallback((): JSX.Element => {
    return (
      <FormPageHeader
        title="Editar cliente"
        icon={<S.CompanyIcon />}
        isLoading={fetchingCompany}
        actions={
          <S.LinkButton size="small" to="/configuracoes/clientes">
            <S.ArrowLeftIcon /> Voltar
          </S.LinkButton>
        }
      />
    );
  }, [fetchingCompany]);

  useEffect(() => {
    fetchCountries({ excludeBlocked: true });
  }, [fetchCountries]);

  useEffect(() => {
    onCompanyLoad();
  }, [onCompanyLoad]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(FetchActions.reset());
      dispatch(UpdateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.MainPanel>
      <Header />
      <S.Content>
        <Form ref={formRef} onSubmit={onSubmit}>
          <HiddenInput name="ibge" readOnly />
          <S.FormRow>
            <Select
              name="documentType"
              label="Tipo de documento"
              options={SELECT_OPTIONS.DOCUMENT_TYPES}
              onChange={onTypeChange}
            />
            <Input
              name="tradeName"
              label={
                selectedType === 'cnpj' ? 'Nome fantasia' : 'Nome completo'
              }
            />
            {!!company?.documentType && (
              <>
                {(['cnpj', 'cpf'].includes(company.documentType as string) && (
                  <MaskedInput
                    name="document"
                    label={documentLabel}
                    mask={documentMask}
                  />
                )) || <Input name="document" label={documentLabel} />}
              </>
            )}
          </S.FormRow>

          <S.FormRow>
            <S.FormRow>
              <Select
                name="countryId"
                label="País"
                options={countryOptions}
                isLoading={loadingCountries}
                onChange={onCountryChange}
              />
              <Input
                name="addressZipcode"
                label="Código postal"
                onChange={onZipcodeChange}
                isLoading={fetchingAddress}
              />
            </S.FormRow>
            <Input name="addressStreet" label="Logradouro" />
            <Input name="addressNumber" label="Número" />
          </S.FormRow>

          <S.FormRow>
            <Input name="addressComplement" label="Complemento" />
            <Input name="addressNeighborhood" label="Bairro" />
            <Input name="addressCity" label="Cidade" />
          </S.FormRow>

          <S.FormRow>
            {(selectedCountryId === 1 && (
              <>
                <HiddenInput name="addressState" readOnly />
                <Select
                  name="addressUf"
                  label="Estado"
                  options={SELECT_OPTIONS.STATES}
                  onChange={onUfChange}
                />
              </>
            )) || <Input name="addressState" label="Estado" />}
            <Input name="addressLatitude" label="Latitude" />
            <Input name="addressLongitude" label="Longitude" />
          </S.FormRow>

          <S.FormActions>
            <S.LinkButton mood="light" to="/configuracoes/clientes">
              {FORM_BACK_ACTION}
            </S.LinkButton>
            <S.Button type="submit">
              {updatingCompany ? <S.ActivityIndicator /> : 'Salvar'}
            </S.Button>
          </S.FormActions>
        </Form>
        <LogoCropper
          label="Logo do cliente"
          helpText="Uma imagem de ao menos 320 x 320px"
          onChange={(file) => setLogoFile(file)}
          preview={company?.logoUrl}
        />
      </S.Content>
    </S.MainPanel>
  );
};
