import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler } from 'services';
import {
  DeleteWarehouseMemberAccessActions as Actions,
  DeleteWarehouseMemberAccessRequestAction as RequestAction,
} from 'store/ducks/warehouseMemberAccesses';

export function* deleteWarehouseMemberAccessRequest(action: any) {
  const { id, onSuccess, onFailure }: RequestAction = action;
  try {
    yield call(api.delete, `warehouse-member-accesses/${id}`);
    onSuccess && onSuccess();
    notify('success', 'Acesso ao armazém desvinculado com sucesso');
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
