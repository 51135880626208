import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  DurationInput,
  Modal,
  ModalCloseButton,
  NumberInput,
  Select,
} from 'components/Shared';
import { MODAL_DISMISS_ACTION } from 'constants/Common';
import type { SelectOption } from 'contracts/Common';
import { useValidation, useVehicleSetups } from 'hooks';
import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { CreateWarehouseVehicleSetupActions as CreateActions } from 'store/ducks/warehouseVehicleSetups';
import { Formatter } from 'utils';
import { CreateWarehouseVehicleSetupValidator as CreateSetupValidator } from 'validators/WarehouseVehicleSetups';
import * as S from './styles';

export interface Ref {
  openModal: () => void;
}

interface Props {
  warehouseId: string | number;
  onCreate?: () => void;
}

interface ISeetupCreationModal
  extends ForwardRefExoticComponent<Props & RefAttributes<Ref>> {}

const SetupCreationModal: ISeetupCreationModal = forwardRef((props, ref) => {
  const { warehouseId, onCreate } = props;
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors, handleApiErrors } = useValidation();
  const {
    vehicleSetups,
    vehicleSetupOptions,
    loadingVehicleSetups,
    fetchVehicleSetups,
  } = useVehicleSetups();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { loading: creatingSetup, validationErrors } = useSelector(
    (state: RootState) => state.createWarehouseVehicleSetup
  );

  const onModalClose = useCallback(() => {
    setIsOpen(false);
    dispatch(CreateActions.reset());
  }, [dispatch]);

  const onVehicleSetupChange = useCallback(
    (option: SelectOption | null): void => {
      if (!option) return;
      const selectedSetup = vehicleSetups.find(
        (setup) => setup.id === option.value
      );

      formRef.current?.setData({
        ...(selectedSetup && {
          weightCapacity: selectedSetup.weightCapacity,
          loadDuration: Formatter.minutesToTime(selectedSetup.loadDuration),
          unloadDuration: Formatter.minutesToTime(selectedSetup.unloadDuration),
        }),
      });
    },
    [vehicleSetups]
  );

  const onSuccess = useCallback((): void => {
    formRef.current?.reset();
    onModalClose();
    onCreate && onCreate();
  }, [onCreate, onModalClose]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef.current?.setErrors({});

        Object.assign(data, { warehouseId });

        const { schema } = new CreateSetupValidator();

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(CreateActions.request(validData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess, warehouseId]
  );

  useEffect(() => {
    fetchVehicleSetups({});
  }, [fetchVehicleSetups]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(CreateActions.reset());
    };
  }, [dispatch]);

  useImperativeHandle(
    ref,
    () => ({
      openModal: () => setIsOpen(true),
    }),
    []
  );

  return (
    <Modal isOpen={isOpen} onClickOutside={onModalClose}>
      <S.ModalContent style={{ maxWidth: '720px' }}>
        <S.ModalHeader>
          <h4>Novo setup de veículo</h4>
          <ModalCloseButton onClick={onModalClose} />
        </S.ModalHeader>
        <S.ModalBody>
          <Form ref={formRef} onSubmit={onSubmit}>
            <S.FormRow>
              <Select
                name="vehicleSetupId"
                label="Setup de veiculo"
                options={vehicleSetupOptions}
                isLoading={loadingVehicleSetups}
                onChange={onVehicleSetupChange}
                menuPortalTarget={document.body}
              />
              <NumberInput
                name="weightCapacity"
                label="Capacidade de carga (Kg)"
                suffix=" Kg"
              />
            </S.FormRow>
            <S.FormRow>
              <DurationInput
                name="loadDuration"
                label="Tempo de carregamento"
              />
              <DurationInput
                name="unloadDuration"
                label="Tempo de descarregamento"
              />
            </S.FormRow>
            <S.FormActions>
              <S.Button type="button" mood="light" onClick={onModalClose}>
                {MODAL_DISMISS_ACTION}
              </S.Button>
              <S.Button type="submit">
                {creatingSetup ? <S.ActivityIndicator /> : 'Salvar'}
              </S.Button>
            </S.FormActions>
          </Form>
        </S.ModalBody>
      </S.ModalContent>
    </Modal>
  );
});

export default SetupCreationModal;
