import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from 'contracts/Router';
import {
  CarrierMembersListPage,
  CarrierMemberCreationPage,
  CarrierMemberUpdatePage,
} from 'pages/CarrierMembers';

const carrierMemberRoutes = ({
  location,
  userBelongsToAnyOf,
}: RouterProps): RouteObject => ({
  path: 'configuracoes/transportadoras/usuarios',
  element: userBelongsToAnyOf('admin') ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: '',
      element: <CarrierMembersListPage />,
    },
    {
      path: 'criar',
      element: <CarrierMemberCreationPage />,
    },
    {
      path: ':id/editar',
      element: <CarrierMemberUpdatePage />,
    },
  ],
});

export default carrierMemberRoutes;
