import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler } from 'services';
import {
  CreateDockReservedIntervalActions as Actions,
  CreateDockReservedIntervalRequestAction as RequestAction,
} from 'store/ducks/dockReservedIntervals';

export function* createDockReservedIntervalRequest(action: any) {
  const { postData, onSuccess, onFailure }: RequestAction = action;
  try {
    yield call(api.post, `dock-reserved-intervals`, postData);
    onSuccess && onSuccess();
    notify('success', 'Bloqueio de horário criado sucesso');
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
