import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';
import type { WarehouseMemberAccess } from 'contracts/WarehouseMemberAccesses';

const { Types, Creators } = createActions(
  {
    request: ['id', 'onSuccess', 'onFailure'],
    success: ['data'],
    failure: ['errorMessage'],
    softReset: [],
    reset: [],
  },
  {
    prefix: 'FETCH_WAREHOUSE_MEMBER_ACCESS_',
  }
);

export interface FetchWarehouseMemberAccessState {
  data: WarehouseMemberAccess | null;
  loading: boolean;
  errorMessage: string | null;
}

export interface FetchWarehouseMemberAccessRequestAction {
  id: number;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface SuccessAction {
  data: WarehouseMemberAccess;
}

interface FailureAction {
  errorMessage: string;
}

const INITIAL_STATE: FetchWarehouseMemberAccessState = {
  data: null,
  loading: false,
  errorMessage: null,
};

const request = (state: FetchWarehouseMemberAccessState) =>
  update(state, {
    loading: { $set: true },
    errorMessage: { $set: null },
  });

const success = (
  state: FetchWarehouseMemberAccessState,
  action: SuccessAction
) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

const failure = (
  state: FetchWarehouseMemberAccessState,
  action: FailureAction
) =>
  update(state, {
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
  });

const softReset = (state: FetchWarehouseMemberAccessState) =>
  update(state, {
    loading: { $set: false },
  });

const reset = () => INITIAL_STATE;

export const fetchWarehouseMemberAccess = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.SOFT_RESET]: softReset,
  [Types.RESET]: reset,
});

export const FetchWarehouseMemberAccessTypes = Types;
export const FetchWarehouseMemberAccessActions = Creators;
