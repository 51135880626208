import React from 'react';
import { Scaffold } from 'layouts';
import { WarehouseSchedule } from 'components/Pages/Scheduling';

export const WarehouseSchedulePage: React.FC = () => {
  return (
    <Scaffold>
      <WarehouseSchedule />
    </Scaffold>
  );
};
