import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import {
  Alert,
  HiddenInput,
  Input,
  Modal,
  Select,
  ToggleInput,
} from 'components/Shared';
import { FORM_BACK_ACTION } from 'constants/Common';
import { useCompanies, useValidation } from 'hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { ListCompaniesActions } from 'store/ducks/companies';
import { DeleteCompanyMemberActions as DeleteActions } from 'store/ducks/companyMembers';
import {
  FetchCompanyMemberActions as FetchActions,
  UpdateCompanyMemberActions as UpdateActions,
} from 'store/ducks/companyMembers';
import { UpdateMemberValidator } from 'validators/CompanyMembers';
import * as S from './styles';

interface Props {
  memberId: string | number;
  onUpdate?: () => void;
}

export const MemberUpdateForm: React.FC<Props> = ({ memberId, onUpdate }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors, handleApiErrors } = useValidation();
  const { companyOptions, loadingCompanies, fetchCompanies } = useCompanies();

  const { data: member } = useSelector(
    (state: RootState) => state.fetchCompanyMember
  );

  const { loading: updatingMember, validationErrors } = useSelector(
    (state: RootState) => state.updateCompanyMember
  );

  const { loading: deletingMember } = useSelector(
    (state: RootState) => state.deleteCompanyMember
  );

  const onMemberLoad = useCallback((): void => {
    if (!member) return;

    const { company, user, userId, allowToday } = member;

    const companyOption = companyOptions.find((o) => o.value === company.id);

    if (companyOption) {
      formRef.current?.setFieldValue('companyId', companyOption);
    }
    formRef.current?.setFieldValue('allowToday', String(allowToday));
    formRef.current?.setFieldValue('userId', userId);
    formRef.current?.setFieldValue('user.name', user.name);
    formRef.current?.setFieldValue('user.email', user.email);
    formRef.current?.setFieldValue(
      'user.blockedAt',
      user.blockedAt !== null ? '1' : '0'
    );
  }, [member, companyOptions]);

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
    onUpdate && onUpdate();
  }, [onUpdate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef?.current?.setErrors({});

        const { schema } = new UpdateMemberValidator();

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(UpdateActions.request(memberId, validData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, memberId, onSuccess]
  );

  const onDelete = useCallback(() => {
    setModalOpen(false);
    dispatch(DeleteActions.request(memberId, onSuccess));
  }, [dispatch, handleFormErrors, memberId]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    onMemberLoad();
  }, [onMemberLoad]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(FetchActions.reset());
      dispatch(UpdateActions.reset());
      dispatch(ListCompaniesActions.reset());
    };
  }, [dispatch]);

  return (
    <S.Container>
      <Form ref={formRef} onSubmit={onSubmit}>
        <HiddenInput name="userId" />
        <Select
          name="companyId"
          label="Cliente"
          options={companyOptions}
          isLoading={loadingCompanies}
        />
        <S.FormRow>
          <Scope path="user">
            <Input name="name" label="Nome completo" />
            <Input name="email" label="Email" />
            <Input name="password" label="Senha" type="password" />
            <ToggleInput name="blockedAt" label="Bloqueado" />
          </Scope>
          <ToggleInput
            name="allowToday"
            label="Permitir D/Atual"
            title="Permissão para agendar no dia atual"
          />
        </S.FormRow>
        <Modal isOpen={modalOpen} onClickOutside={() => setModalOpen(false)}>
          <Alert
            title={'Excluir Usuário'}
            text={'Deseja realmente excluir este usuário?'}
            close={() => setModalOpen(false)}
            action={onDelete}
            labelAction={'Sim'}
          />
        </Modal>
        <S.FormActions>
          <S.LinkButton mood="light" to="/configuracoes/clientes/usuarios">
            {FORM_BACK_ACTION}
          </S.LinkButton>
          <S.Button type="submit">
            {updatingMember ? <S.ActivityIndicator /> : 'Salvar'}
          </S.Button>
          <S.Button
            type="button"
            mood="danger"
            onClick={() => setModalOpen(true)}
            disabled={deletingMember}
          >
            {deletingMember ? <S.ActivityIndicator /> : 'Excluir'}
          </S.Button>
        </S.FormActions>
      </Form>
    </S.Container>
  );
};
