import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { PaginateCarriersActions as PaginateActions } from 'store/ducks/carriers';
import { usePaginationCache } from 'hooks';
import { Scaffold } from 'layouts';
import {
  CarriersList,
  CarrierFilters,
  FindCarriers,
} from 'components/Pages/Carriers';
import { Paginator, ListingPageHeader, SettingsMenu } from 'components/Shared';
import * as S from './styles';

export const CarriersListPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const { paginationCache, updatePaginationCache, handleFilter, handleSort } =
    usePaginationCache('carriers');

  const {
    data: carriers,
    pagination,
    loading,
  } = useSelector((state: RootState) => state.paginateCarriers);

  const [query, setQuery] = useState<FindCarriers>({
    search: '',
    page: 1,
    limit: 10,
    ...paginationCache,
  });

  const onPagechange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const onQueryChange = useCallback((): void => {
    dispatch(PaginateActions.request(query));
  }, [dispatch, query]);

  useEffect(() => {
    onQueryChange();
  }, [onQueryChange]);

  useEffect(() => {
    return () => {
      dispatch(PaginateActions.reset());
      updatePaginationCache(query);
    };
  }, [dispatch, query, updatePaginationCache]);

  return (
    <Scaffold>
      <SettingsMenu />
      <S.MainPanel>
        <ListingPageHeader
          icon={<S.TruckIcon />}
          title="Transportadoras"
          isLoading={loading}
          actions={
            <S.LinkButton
              to={'/configuracoes/transportadoras/criar'}
              size="small"
            >
              <S.PlusIcon /> Nova transportadora
            </S.LinkButton>
          }
        />
        <CarrierFilters
          currentFilter={query}
          onFilter={(filter) => handleFilter(query, filter, setQuery)}
        />
        <CarriersList
          carriers={carriers}
          currentSort={query}
          onSort={(sort) => handleSort(query, sort, setQuery)}
        />
        <Paginator onPageChange={onPagechange} pagination={pagination} />
      </S.MainPanel>
    </Scaffold>
  );
};
