import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from 'contracts/Router';
import {
  VehicleSetupsListPage,
  VehicleSetupCreationPage,
  VehicleSetupUpdatePage,
} from 'pages/VehicleSetups';

const vehicleSetupsRoutes = ({
  location,
  userBelongsToAnyOf,
}: RouterProps): RouteObject => ({
  path: 'configuracoes/setups-veiculos',
  element: userBelongsToAnyOf('admin') ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: '',
      element: <VehicleSetupsListPage />,
    },
    {
      path: 'criar',
      element: <VehicleSetupCreationPage />,
    },
    {
      path: ':id/editar',
      element: <VehicleSetupUpdatePage />,
    },
  ],
});

export default vehicleSetupsRoutes;
