import styled from 'styled-components'
import { ColorScheme } from 'styles/constants';
export { TrashIcon } from "styles/components";

export const Container = styled.ul`
  display: flex;
  flex-direction: column;
`

export const File = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  border-radius: 6px;
  color: ${ColorScheme.LightText};

  &:hover {
    background-color: ${ColorScheme.Text}11;
  }
`

export const ButtonIcon = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  height: 32px;
  color: ${ColorScheme.Danger};

  svg {
    height: 24px;
    width: 24px;
  }
`;