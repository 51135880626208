import { Formatter } from 'utils';
import * as Yup from 'yup';

interface Props {
  weightCapacity: number;
  totalWeight: number;
}

export class CreateItemValidator {
  constructor(protected readonly props: Props) {}

  public get schema() {
    const { weightCapacity, totalWeight } = this.props;

    return Yup.object().shape({
      document: Yup.string().required('Informe o número do documento'),
      weight: Yup.number()
        .typeError('Informe o peso do item')
        .required('Informe o peso do item')
        .test({
          name: 'testWeight',
          test: function (value) {
            try {
              if (typeof value !== 'number' || value <= 0) {
                throw new Error('Peso inválido');
              }

              if (totalWeight + value > weightCapacity) {
                throw new Error(
                  `Peso excederia o limite de ${Formatter.weight(
                    weightCapacity
                  )}`
                );
              }

              return true;
            } catch (error: any) {
              return this.createError({
                message: error?.message,
              });
            }
          },
        }),
      value: Yup.number()
        .typeError('Informe o valor do item')
        .required('Informe o valor do item')
        .test({
          name: 'testValue',
          test: function (value) {
            try {
              if (typeof value !== 'number' || value <= 0) {
                throw new Error('Valor inválido');
              }

              if (value > 100_000_000) {
                throw new Error(
                  'O valor não pode ser maior ou igual a 100 milhões de reais'
                );
              }

              return true;
            } catch (error: any) {
              return this.createError({
                message: error?.message,
              });
            }
          },
        }),
    });
  }
}
